import axios from 'axios'

export const updateProcedure = async ({ procedureId, procedure }) => {
  const { data } = await axios({
    method: 'put',
    url: `/procedures/${procedureId}`,
    data: { procedure },
  })

  return data
}
