import cx from 'classnames'
import Downshift from 'downshift'
import * as React from 'react'

import { Chip } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import { AutocompleteInput } from './AutocompleteInput'
import { Suggestion } from './Suggestion'
import { styles } from './autocomplete-styles'
import { getSuggestions } from './suggestions-parser'

interface Props {
  label: string
  placeholder: string
  suggestions: any[]
  fullWidth: boolean
  handleSelectionChange: any
  selectedItems: string[]
  classes: any
  disabled: boolean
  subLabel?: string
}

const AutocompleteMultipleComponent = ({
  label,
  placeholder,
  suggestions,
  fullWidth,
  handleSelectionChange,
  selectedItems = [],
  classes,
  disabled,
  subLabel,
}: Props) => {
  const [inputValue, setInputValue] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState(selectedItems)

  const handleKeyDown = (event) => {
    if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1))
    }
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleChange = (item) => {
    let newSelectedItem = [...selectedItem]
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item]
    }
    setInputValue('')
    setSelectedItem(newSelectedItem)

    handleSelectionChange(newSelectedItem)
  }

  const handleDelete = (item) => () => {
    const newSelectedItem = [...selectedItem]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    setSelectedItem(newSelectedItem)

    handleSelectionChange(newSelectedItem)
  }

  return (
    <Downshift id="downshift-multiple" inputValue={inputValue} onChange={handleChange} selectedItem={selectedItem}>
      {({ getInputProps, getItemProps, getLabelProps, isOpen, inputValue, selectedItem, highlightedIndex }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder,
          disabled,
        })

        return (
          <div className={cx('input', classes.container)}>
            <AutocompleteInput
              classes={classes}
              fullWidth={fullWidth}
              placeholder={placeholder}
              InputLabelProps={getLabelProps()}
              isMultiple
              label={label}
              helperText={subLabel}
              InputProps={{
                startAdornment: selectedItem.map((item) => (
                  <Chip className={classes.chip} key={item} tabIndex={-1} label={item} onDelete={handleDelete(item)} />
                )),
                onBlur,
                onChange: (event) => {
                  handleInputChange(event)
                  onChange(event)
                },
                onFocus,
                multiline: true,
              }}
              {...inputProps}
            />
            {isOpen ? (
              <Paper className={classes.paper} square>
                {getSuggestions({ value: inputValue, suggestions }).map((suggestion, index) => (
                  <Suggestion
                    key={index}
                    suggestion={suggestion}
                    index={index}
                    itemProps={getItemProps({ item: suggestion.name })}
                    highlightedIndex={highlightedIndex}
                    selectedItem={selectedItem}
                  />
                ))}
              </Paper>
            ) : null}
          </div>
        )
      }}
    </Downshift>
  )
}

const AutocompleteMultiple = withStyles(styles)(AutocompleteMultipleComponent)

export { AutocompleteMultiple }
