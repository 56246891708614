import * as Yup from 'yup'

import { disableWeekendDays } from '../../shared/forms/DatePicker'
import { phoneRegExp, zipCodeRegExp } from './regex-validators'

const requiredMessage = 'Can’t be blank.'

export const petProfessionalSchema = Yup.object().shape({
  practiceName: Yup.string().required(requiredMessage),
  practicePhoneNumber: Yup.string().matches(phoneRegExp, 'Must be a valid US phone number.'),
  address: Yup.string().required(requiredMessage),
  city: Yup.string().required(requiredMessage),
  state: Yup.string().required(requiredMessage),
  zip: Yup.string()
    .required(requiredMessage)
    .matches(zipCodeRegExp, 'Invalid zip code.'),
  procedureDate: Yup.date()
    .nullable()
    .test(
      'procedureDate',
      'The procedure day needs to be between Monday and Thursday, not including holidays or a day before the holiday.',
      (value) => {
        if (!value) return true

        return !disableWeekendDays(value)
      },
    ),
})
