import '../checkout.scss'

import { Formik } from 'formik'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button, Checkbox, FormControlLabel } from '@material-ui/core'

import { identify, trackEvent, SegmentEvents } from '../../../api/segment'
import { stripeProducts } from '../../../api/segment/products'
import { changeStepAction } from '../../../redux/checkout/change-step'
import { checkEmailAction } from '../../../redux/checkout/check-email'
import { CheckoutStep } from '../../../redux/checkout/initial-state'
import { initialState as checkoutInitialState } from '../../../redux/checkout/initial-state'
import { includeStepAction, skipStepAction } from '../../../redux/checkout/skip-step'
import { updateOwnerAction } from '../../../redux/checkout/update-owner'
import { Store } from '../../../redux/configure-store'
import { calculateCheckout } from '../../../redux/selectors/checkout-calculator-selector'
import { Input } from '../../shared/forms/Input'
import { ownerInfoSchema } from '../schemas/owner-schema'
import { getSafe } from './utils'

const mapStateToProps = (state: Store) => {
  return {
    checkout: state.checkout,
    checkoutCalculation: calculateCheckout(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateOwner: bindActionCreators(updateOwnerAction, dispatch),
    changeStep: bindActionCreators(changeStepAction, dispatch),
    skipStep: bindActionCreators(skipStepAction, dispatch),
    includeStep: bindActionCreators(includeStepAction, dispatch),
    checkEmail: bindActionCreators(checkEmailAction, dispatch),
  }
}

type Dispatchers = ReturnType<typeof mapDispatchToProps>

type Props = {
  checkout: typeof checkoutInitialState
  checkoutCalculation: any
} & Dispatchers

export interface OwnerInfoValues {
  petName: string
  firstName: string
  email: string
}

export class OwnerFormComponent extends React.Component<Props, any> {
  state = {
    isGift: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    const { currentStep } = this.props.checkout

    if (currentStep === CheckoutStep.OwnerInfo) {
      trackEvent(SegmentEvents.CheckoutStepViewed, {
        step: CheckoutStep.OwnerInfo,
      })
    }

    this.dispatchCreateProductEvent()
  }

  dispatchCreateProductEvent() {
    const { checkout, checkoutCalculation } = this.props
    const chosenProduct = stripeProducts[checkout.product]

    trackEvent(SegmentEvents.ProductAdded, chosenProduct)

    trackEvent(SegmentEvents.CheckoutStarted, {
      currency: 'USD',
      revenue: chosenProduct && chosenProduct.price,
      products: [
        {
          ...chosenProduct,
          price: checkoutCalculation.subtotal,
        },
      ],
    })
  }

  handleBasicInfoSubmit = (values: OwnerInfoValues) => {
    const payload = {
      pet: {
        name: values.petName,
      },
      firstName: values.firstName,
      email: values.email,
      isGift: this.state.isGift,
    }

    this.props.updateOwner({ info: payload })
    this.props.changeStep(CheckoutStep.Billing)

    trackEvent(SegmentEvents.CheckoutStepCompleted, {
      step: CheckoutStep.OwnerInfo,
      email: values.email,
    })

    identify(null, {
      firstName: values.firstName,
      email: values.email,
      pets: [
        {
          name: values.petName,
        },
      ],
      address: {
        country: 'USA',
      },
    })
  }

  // tslint:disable-next-line:cyclomatic-complexity
  getInitialValues = () => {
    const {
      checkout: { owner },
    } = this.props

    return {
      petName: getSafe(() => owner.pet.name, ''),
      firstName: getSafe(() => owner.firstName, ''),
      email: getSafe(() => owner.email, ''),
    }
  }

  render() {
    const initialValues = this.getInitialValues()
    const { isGift } = this.state
    const timestamp = Date.now()

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={ownerInfoSchema}
        onSubmit={async (values) => this.handleBasicInfoSubmit(values)}
      >
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit} noValidate>
              <section className="form-fields">
                <div className="same-line">
                  <Input
                    key={`first-name-${timestamp}`}
                    className="right-margin"
                    form={formProps}
                    name="firstName"
                    type="text"
                    label={isGift ? 'Your First Name (Gift Giver)' : 'First Name'}
                    autoComplete="given-name"
                    fullWidth
                  />
                  <Input
                    key={`pet-name-${timestamp}`}
                    form={formProps}
                    placeholder="Rocky"
                    name="petName"
                    type="text"
                    label={isGift ? 'Pet\'s Name (N/A if you don\'t know)' : 'Pet\'s Name'}
                    autoComplete="off"
                    fullWidth
                  />
                </div>
                <Input
                  key={`email-${timestamp}`}
                  form={formProps}
                  onBlur={(e) => this.props.checkEmail(e.target.value)}
                  name="email"
                  type="email"
                  label={isGift ? 'Your Email (Gift Giver)' : 'Email'}
                  autoComplete="email"
                  fullWidth
                />
                <div className="same-line">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => this.setState({ isGift: !isGift })}
                        name="is-gift"
                        value={isGift}
                        color="primary"
                        checked={isGift}
                      />
                    }
                    label="I'm offering Gallant as a gift."
                  />
                </div>
              </section>
              <Button type="submit">
                Billing
                <span className="arrow-right" />
              </Button>
            </form>
          )
        }}
      </Formik>
    )
  }
}

const OwnerForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OwnerFormComponent)

export { OwnerForm }
