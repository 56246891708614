import '../checkout/checkout.scss'

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

type Props = {
  history: any
}

const Procedure: React.FunctionComponent<Props> = ({ history }) => (
  <section className="container">
    <section className="header">
      <button className="no-style-btn" onClick={() => history.goBack()}>
        <FontAwesomeIcon className="back-arrow" icon={faArrowLeft} />
      </button>
      <div className="image-logo centralized" />
    </section>
    <p className="procedure-content">
      To change your procedure information, please contact help@gallant.com or call 855-4-GALLANT.{' '}
    </p>
  </section>
)

export { Procedure }
