import { createActionThunk } from 'redux-thunk-actions'

import { getCoupon } from '../../api/checkout/get-coupon'
import { actions } from './actions'
import { Reducer } from './initial-state'

export const getCouponAction = createActionThunk(actions.getCoupon, (couponCode: string) =>
  getCoupon(couponCode),
)

export const getCouponReducer = {
  [getCouponAction.STARTED]: (state) => ({
    ...state,
    loadingCoupon: true,
    error: undefined,
    coupon: undefined,
  }),
  [getCouponAction.SUCCEEDED]: (state, { payload }) => ({
    ...state,
    coupon: payload,
    error: undefined,
  }),
  [getCouponAction.FAILED]: (state, { payload }) => ({
    ...state,
    loadingCoupon: false,
    error: payload.error,
    coupon: undefined,
  }),
  [getCouponAction.ENDED]: (state) => ({
    ...state,
    loadingCoupon: false,
  }),
} as Reducer
