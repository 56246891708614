import './stripe-payment-provider.scss'

import * as React from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'

import { config } from '../../../config'
import { PaymentForm } from './PaymentForm/PaymentForm'

export const StripePaymentProvider = () => (
  <StripeProvider apiKey={config.stripeApiKey}>
    <Elements>
      <PaymentForm />
    </Elements>
  </StripeProvider>
)
