import './input.scss'

import cx from 'classnames'
import * as React from 'react'

import { withStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

import { styles } from './input-styles'

interface Props {
  name: string
  type: string
  label: string
  subLabel?: string
  placeholder?: string
  autoComplete?: string
  form?: any
  helpText?: string
  maxLength?: number
  rows?: number
  autoFocus?: boolean
  children?: any
  fullWidth?: boolean
  className?: string
  InputLabelProps?: object
  InputProps?: object
  classes: any
  onBlur?: (event: any) => void
  isOptional?: boolean
  handleChange?: (event: any) => void
}

const InputComponent = (props: Props) => {
  const {
    name,
    type,
    placeholder,
    autoComplete,
    autoFocus,
    children,
    fullWidth,
    className,
    InputLabelProps,
    InputProps,
    label,
    subLabel,
    classes,
    handleChange,
    maxLength,
    onBlur,
  } = props
  const { values, errors, submitCount, handleChange: formikHandleChange, handleBlur } = props.form

  return (
    <div className={cx('input', className, classes.container)}>
      {subLabel && <p className="sub-label">{subLabel}</p>}
      <TextField
        className={classes.textField}
        autoFocus={autoFocus}
        type={type as any}
        id={name}
        name={name}
        label={label}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={(e) => {
          formikHandleChange(e)
          handleChange && handleChange(e)
        }}
        onBlur={(e) => {
          onBlur && onBlur(e)
          handleBlur && handleBlur(e)
        }}
        value={values[name]}
        children={children}
        fullWidth={fullWidth}
        variant="outlined"
        InputLabelProps={InputLabelProps}
        InputProps={{
          classes: classes && {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ maxLength }}
      />
      <p className="error">{!!submitCount && errors[name]}</p>
    </div>
  )
}

const Input = withStyles(styles)(InputComponent)

export { Input }
