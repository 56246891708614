import { Button, FormControlLabel, Radio } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { Pet } from '../../../redux/user/initial-state'

interface PicturePermissionFormProps {
  pet: Pet
  handleSubmit: (pet: Pet) => void
  cancelSubmit: () => void
}

export default function PicturePermissionForm({
  pet,
  handleSubmit,
  cancelSubmit,
}: PicturePermissionFormProps) {
  const [permission, setPermission] = useState<boolean>(
    pet.picturePermission === null ? true : pet.picturePermission,
  )

  return (
    <form style={{ marginBottom: '30px' }}>
      <section className="form-fields">
        <div className="radio-input">
          <p>Can Gallant use your pup's picture for social media or website posts?</p>
          <div className="radio-group">
            <FormControlLabel
              value={true}
              control={
                <Radio
                  color="primary"
                  checked={permission === true}
                  onChange={() => setPermission(true)}
                />
              }
              label="Yes"
            />
            <FormControlLabel
              value={false}
              control={
                <Radio
                  color="primary"
                  checked={permission === false}
                  onChange={() => setPermission(false)}
                />
              }
              label="No"
            />
          </div>
          <div className="same-line actions">
            <Button type="button" className="cancel" onClick={() => cancelSubmit()}>
              Cancel
            </Button>
            <Button
              type="button"
              className="save"
              onClick={() => handleSubmit({ id: pet.id, picturePermission: permission })}
            >
              <span className="icon save white" />
              Save
            </Button>
          </div>
        </div>
      </section>
    </form>
  )
}
