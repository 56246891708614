import '../checkout.scss'

import { Formik } from 'formik'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button, FormControlLabel, Radio } from '@material-ui/core'

import { identify, trackEvent, SegmentEvents } from '../../../api/segment'
import { SpeciesRef } from '../../../model/SpeciesRef'
import { changeStepAction } from '../../../redux/checkout/change-step'
import { getBreedsAction } from '../../../redux/checkout/get-breeds'
import {
  initialState as checkoutInitialState,
  CheckoutStep,
  SetupStep,
  ValidProducts,
} from '../../../redux/checkout/initial-state'
import { Pet } from '../../../redux/checkout/initial-state'
import { updateOwnerAction } from '../../../redux/checkout/update-owner'
import { updatePetAction } from '../../../redux/checkout/update-pet'
import { Store } from '../../../redux/configure-store'
import { Input } from '../../shared/forms/Input'
import { AutocompleteMultiple } from '../../shared/forms/autocomplete/AutocompleteMultiple'
import { AutocompleteSingle } from '../../shared/forms/autocomplete/AutocompleteSingle'
import { petInfoSchema } from '../schemas/pet-schema'

const mapStateToProps = (state: Store) => {
  return {
    checkout: state.checkout,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateOwner: bindActionCreators(updateOwnerAction, dispatch),
    changeStep: bindActionCreators(changeStepAction, dispatch),
    updatePet: bindActionCreators(updatePetAction, dispatch),
    getBreeds: bindActionCreators(getBreedsAction, dispatch),
  }
}

type Dispatchers = ReturnType<typeof mapDispatchToProps>
type Props = {
  checkout: typeof checkoutInitialState
  hideReceipt: boolean
  nextStep: CheckoutStep | SetupStep
  isSetup?: boolean
} & Dispatchers

export interface PetInfoValues {
  gender: string
  years: number
  months: number
  primaryBreed: string
  secondaryBreeds: string[]
}

const createPetDate = (days, months, years) => {
  const date = new Date()

  date.setDate(date.getDate() + days)
  date.setMonth(date.getMonth() + months)
  date.setFullYear(date.getFullYear() + years)

  return date
}

export class PetFormComponent extends React.Component<Props, any> {
  static defaultProps = {
    hideReceipt: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    this.props.getBreeds()
  }

  handlePetInfoSubmit = (values: PetInfoValues) => {
    const { checkout, nextStep } = this.props
    const primaryBreed = values.primaryBreed || checkout.owner.pet.primaryBreed
    const secondaryBreeds = values.secondaryBreeds || checkout.owner.pet.secondaryBreeds

    const pet = {
      ...checkout.owner.pet,
      gender: values.gender,
      years: values.years || 0,
      months: values.months || 0,
      primaryBreed,
      secondaryBreeds,
    } as Partial<Pet>

    this.props.updatePet({
      pet: {
        ...pet,
        primaryBreed: this.getBreedId(primaryBreed),
        secondaryBreeds: this.getSecondaryBreedIds(secondaryBreeds),
      },
      petId: checkout.owner.ids.petId,
    })

    this.props.updateOwner({ info: { pet } })
    this.props.changeStep(nextStep)

    this.trackEvents(pet)
  }

  trackEvents = (pet) => {
    const { checkout } = this.props

    trackEvent(SegmentEvents.PetInfoSaved)

    identify(checkout.owner.ids.userId, {
      pets: [
        {
          name: pet.name,
          sex: pet.gender,
          dob: createPetDate(0, -pet.months, -pet.years),
          breed: pet.primaryBreed,
        },
      ],
    })
  }

  getBreedId = (breedName: string) => {
    const breed = this.props.checkout.breeds.find(
      (breed: { name: string; id: string }) => breed.name.toLowerCase() === breedName.toLowerCase(),
    )

    return breed.id
  }

  getSecondaryBreedIds = (secondaryBreeds: string[]) => {
    return (
      secondaryBreeds &&
      secondaryBreeds
        .map((secondaryBreedName: string) => this.getBreedId(secondaryBreedName))
        .join(', ')
    )
  }

  getInitialValues = (): PetInfoValues => {
    const { checkout } = this.props
    const { owner } = checkout
    const initialValues = {
      gender: undefined,
      years: undefined,
      months: undefined,
      species: undefined,
      primaryBreed: undefined,
      secondaryBreeds: undefined,
    }

    const equineCheckout =
      checkout.product === ValidProducts.equineAnnual ||
      checkout.product === ValidProducts.equineLifetime

    if (owner.pet) {
      initialValues.gender = owner.pet.gender
      initialValues.years = owner.pet.years && owner.pet.years >= 0 ? owner.pet.years : undefined
      initialValues.months =
        owner.pet.months && owner.pet.months >= 0 ? owner.pet.months : undefined

      if (equineCheckout) {
        initialValues.species = 'horse'
      } else if (owner.pet.speciesId) {
        initialValues.species = SpeciesRef.toArray().find(
          (ref) => ref.id === owner.pet.speciesId,
        ).optionValue
      } else {
        initialValues.species = 'dog'
      }
      initialValues.primaryBreed = owner.pet.primaryBreed
      initialValues.secondaryBreeds = owner.pet.secondaryBreeds
    }

    return initialValues
  }

  // tslint:disable-next-line:cyclomatic-complexity
  renderForm = ({ formProps }) => {
    const { checkout, isSetup } = this.props
    const { values, errors, touched, setFieldValue, handleSubmit } = formProps
    const primaryBreed = values.primaryBreed || checkout.owner.pet.primaryBreed
    const secondaryBreeds = values.secondaryBreeds || checkout.owner.pet.secondaryBreeds || []
    const equineCheckout =
      checkout.product === ValidProducts.equineAnnual ||
      checkout.product === ValidProducts.equineLifetime

    let breedSugestions

    if (values.species === 'horse') {
      breedSugestions = ((checkout && checkout.breeds) || []).filter(
        (breed) => breed.speciesId === SpeciesRef.EQUINE.id,
      )
    } else {
      breedSugestions = ((checkout && checkout.breeds) || []).filter(
        (breed) => breed.speciesId === SpeciesRef.CANINE.id,
      )
    }

    return (
      <form onSubmit={handleSubmit} noValidate>
        <section className="form-fields">
          <div className="radio-input">
            <p>gender</p>
            <div className="radio-group">
              <FormControlLabel
                value="female"
                control={
                  <Radio
                    color="primary"
                    checked={values.gender === 'female'}
                    onChange={(e) => setFieldValue('gender', e.target.value)}
                  />
                }
                label="Female"
              />
              <FormControlLabel
                value="male"
                control={
                  <Radio
                    color="primary"
                    checked={values.gender === 'male'}
                    onChange={(e) => setFieldValue('gender', e.target.value)}
                  />
                }
                label="Male"
              />
            </div>
          </div>
          {errors.gender && touched.gender && <p className="error">A selection must be made.</p>}
          <div className="same-line">
            <Input
              className="right-margin"
              form={formProps}
              name="years"
              type="number"
              label="Age (years)"
              fullWidth
              autoComplete="off"
            />
            <Input
              form={formProps}
              name="months"
              type="number"
              label="Age (months)"
              fullWidth
              autoComplete="off"
            />
          </div>
          {!equineCheckout && (
            <div className="radio-input">
              <p>Species</p>
              <div className="radio-group">
                <FormControlLabel
                  value="dog"
                  control={
                    <Radio
                      color="primary"
                      checked={values.species === 'dog'}
                      onChange={(e) => {
                        setFieldValue('primaryBreed', null)
                        setFieldValue('species', e.target.value)
                      }}
                    />
                  }
                  label="Dog"
                />
                <FormControlLabel
                  value="cat"
                  control={
                    <Radio
                      color="primary"
                      checked={values.species === 'cat'}
                      onChange={(e) => {
                        setFieldValue('primaryBreed', 'Cat')
                        setFieldValue('secondaryBreeds', undefined)
                        setFieldValue('species', 'cat')
                      }}
                    />
                  }
                  label="Cat"
                />
                {isSetup && (
                  <FormControlLabel
                    value="horse"
                    control={
                      <Radio
                        color="primary"
                        checked={values.species === 'horse'}
                        onChange={(e) => {
                          setFieldValue('primaryBreed', null)
                          setFieldValue('species', e.target.value)
                        }}
                      />
                    }
                    label="Horse"
                  />
                )}
              </div>
            </div>
          )}
          {errors.species && touched.species && <p className="error">A selection must be made.</p>}
          {values.species !== 'cat' && (
            <>
              <AutocompleteSingle
                label="Main Breed"
                subLabel="Choose a single, main breed. Can also be “Mixed”"
                placeholder="Type to find main breed..."
                fullWidth
                suggestions={breedSugestions}
                selectedItem={primaryBreed}
                disabled={checkout.loadingBreeds}
                handleSelectionChange={(value) => setFieldValue('primaryBreed', value)}
              />
              {errors.primaryBreed && touched.primaryBreed && (
                <p className="error">Can’t be blank.</p>
              )}
              <AutocompleteMultiple
                label="Secondary breed(s)"
                subLabel="Optional. Add as many that apply."
                placeholder="Optional Secondary Breed(s)"
                fullWidth
                suggestions={breedSugestions}
                disabled={checkout.loadingBreeds}
                handleSelectionChange={(value) => setFieldValue('secondaryBreeds', value)}
                selectedItems={secondaryBreeds}
              />
            </>
          )}
        </section>
        <Button disabled={formProps.isSubmitting} type="submit">
          NEXT
          <span className="arrow-right" />
        </Button>
      </form>
    )
  }

  render() {
    const initialValues = this.getInitialValues()

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={petInfoSchema}
        onSubmit={(values) => this.handlePetInfoSubmit(values)}
      >
        {(formProps) => this.renderForm({ formProps })}
      </Formik>
    )
  }
}

const PetForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PetFormComponent)

export { PetForm }
