import { Button } from '@material-ui/core'
import { Formik } from 'formik'
import * as React from 'react'

import { Input } from '../../shared/forms/Input'
import { InputPlaces } from '../../shared/forms/InputPlaces'
import { PhoneInput } from '../../shared/forms/PhoneInput'
import { procedureSchema } from './schemas/practice'

interface PracticeFormProps {
  practice: {
    name: string
    phoneNumber: string
    address: {
      address: string
      secondAddress: string
      city: string
      state: string
      zip: string
    }
  }
  handleSubmit: (values: object) => void
  onCancel?: () => void
}

const PracticeForm: React.SFC<PracticeFormProps> = ({ practice, onCancel, handleSubmit }) => {
  // tslint:disable-next-line: cyclomatic-complexity
  const getInititalValue = () => ({
    practiceName: practice.name || '',
    phoneNumber: practice.phoneNumber || '',
    address: (practice.address && practice.address.address) || '',
    secondAddress: (practice.address && practice.address.secondAddress) || '',
    city: (practice.address && practice.address.city) || '',
    state: (practice.address && practice.address.state) || '',
    zip: (practice.address && practice.address.zip) || '',
  })

  const handleProcedureSubmit = (values) =>
    handleSubmit({
      name: values.practiceName,
      phoneNumber: values.phoneNumber,
      address: {
        address: values.address,
        secondAddress: values.secondAddress,
        city: values.city,
        state: values.state,
        zip: values.zip,
      },
    })

  return (
    <Formik
      enableReinitialize
      initialValues={getInititalValue()}
      validationSchema={procedureSchema}
      onSubmit={handleProcedureSubmit}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit} noValidate>
          <section className="form-fields">
            <InputPlaces
              label="Name of your vet’s practice"
              form={formProps}
              name="practiceName"
              onSelect={({ name: practiceName, address, city, state, zipcode: zip }) =>
                formProps.setValues({ practiceName, address, city, state, zip })
              }
            />
            <Input form={formProps} name="address" type="text" label="Address" fullWidth autoComplete="off" />
            <Input
              form={formProps}
              name="secondAddress"
              type="text"
              label="Address Line 2"
              placeholder="Unit or Suite"
              isOptional
              fullWidth
              autoComplete="off"
            />
            <Input form={formProps} name="city" type="text" label="City" fullWidth autoComplete="off" />
            <div className="same-line">
              <Input
                className="right-margin"
                form={formProps}
                name="state"
                type="text"
                label="State"
                fullWidth
                autoComplete="off"
                maxLength={2}
              />
              <Input
                form={formProps}
                name="zip"
                type="text"
                label="Zip Code"
                maxLength={9}
                fullWidth
                autoComplete="off"
              />
            </div>
            <PhoneInput form={formProps} label="Phone Number" name="phoneNumber" />
            <div className="same-line actions">
              <Button type="button" className="cancel" onClick={() => onCancel && onCancel()}>
                Cancel
              </Button>
              <Button type="submit" className="save">
                <span className="icon save white" />
                Save
              </Button>
            </div>
          </section>
        </form>
      )}
    </Formik>
  )
}

export { PracticeForm }
