import moment from 'moment'
import hollidaysData from './hollidays.json'

export const hollidays = hollidaysData

// Helpers

const formatDate = (date) => {
  const parsedDate = new Date(date)

  return `${parsedDate.getMonth() + 1}-${parsedDate.getDate()}-${parsedDate.getFullYear()}`
}

const incrementDate = (date, days) => new Date(date).setDate(new Date(date).getDate() + days)

// Filters

const isFriday = (date) => new Date(date).getDay() === 5

const isWeekendDay = (date) => {
  const day = new Date(date).getDay()

  return day === 6 || day === 0
}

const isHolliday = (date) => !!hollidays.find((hollidayDate) => hollidayDate === formatDate(date))

const incrementSkippedDays = (date, totalBusinessDays = 0, skippedDaysList = []) => {
  const totalSkippedDays = 0

  if (totalBusinessDays === totalSkippedDays) {
    return skippedDaysList
  }

  const nextDate = incrementDate(date, 1)

  skippedDaysList = [...skippedDaysList, formatDate(nextDate)]

  if (!isFriday(nextDate) && !isWeekendDay(nextDate) && !isHolliday(nextDate)) {
    totalBusinessDays = totalBusinessDays + 1
  }

  return incrementSkippedDays(nextDate, totalBusinessDays, skippedDaysList)
}

const isOneDayBeforeHolliday = (date: Date) => {
  const nextDate = incrementDate(date, 1)

  if (isHolliday(nextDate)) {
    return true
  }
}

const isRestricted = (date, orderDate) => {
  // const formatedOrderDate = formatDate(new Date(orderDate))
  let skippedDaysList = []

  skippedDaysList = [...skippedDaysList, ...incrementSkippedDays(orderDate, 0, skippedDaysList)]

  return skippedDaysList.includes(formatDate(date))
}

const isCustomRestrictedDays = (date) => {
  const march15 = moment('03/15/2021')
  const march16 = moment('03/16/2021')

  return march15.isSame(date) || march16.isSame(date)
}

export const isDateDisabled = (date: Date, orderDate) => {
  return (
    // isFriday(date) ||
    isWeekendDay(date) ||
    isOneDayBeforeHolliday(date) ||
    isHolliday(date) ||
    isRestricted(date, orderDate) ||
    isCustomRestrictedDays(date)
  )
}
