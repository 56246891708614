import { createActionThunk } from 'redux-thunk-actions'

import { createProcedure } from '../../api/procedures/create-procedure'
import { actions } from './actions'
import { Procedure, Reducer } from './initial-state'

export const createProcedureAction = createActionThunk(actions.createProcedure, (procedure: Procedure) =>
  createProcedure({ procedure }),
)

export const createProcedureReducer = {
  [createProcedureAction.STARTED]: (state) => ({
    ...state,
    updatingPet: true,
  }),
  [createProcedureAction.SUCCEEDED]: (state, { payload }) => ({
    ...state,
    owner: {
      ...state.owner,
      ids: {
        ...state.owner.ids,
        procedureId: payload.procedureId,
      },
    },
  }),
  [createProcedureAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [createProcedureAction.ENDED]: (state) => ({
    ...state,
    updatingPet: false,
  }),
} as Reducer
