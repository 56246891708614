import { CircularProgress } from '@material-ui/core'
import * as React from 'react'

interface LoadingProps {
  visible: boolean
}

const Loading: React.FunctionComponent<LoadingProps> = ({ visible }) =>
  visible && (
    <div className="loading">
      <CircularProgress size={60} />
      <p>Just a moment while we pawcess your transaction...</p>
    </div>
  )

export { Loading }
