import '../checkout.scss'

import { Button, Grid } from '@material-ui/core'
import { Formik } from 'formik'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { trackEvent, SegmentEvents } from '../../../api/segment'
import { changeStepAction } from '../../../redux/checkout/change-step'
import { CheckoutStep, SetupStep } from '../../../redux/checkout/initial-state'
import { initialState as checkoutInitialState } from '../../../redux/checkout/initial-state'
import { updateShippingAction } from '../../../redux/checkout/update-shipping'
import { Store } from '../../../redux/configure-store'
import { Input } from '../../shared/forms/Input'
import { PhoneInput } from '../../shared/forms/PhoneInput'
import { billingSchema } from '../schemas/billing-schema'
import { getSafe } from './utils'

const mapStateToProps = (state: Store) => {
  return {
    checkout: state.checkout,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateShipping: bindActionCreators(updateShippingAction, dispatch),
    changeStep: bindActionCreators(changeStepAction, dispatch),
  }
}

type Dispatchers = ReturnType<typeof mapDispatchToProps>

type Props = {
  checkout: typeof checkoutInitialState
  nextStep?: CheckoutStep | SetupStep
} & Dispatchers

export interface ShippingValues {
  firstName: string
  lastName: string
  phoneNumber: string
  streetAddress: string
  secondAddress: string
  city: string
  state: string
  zipCode: string
}

export class ShippingFormComponent extends React.Component<Props, any> {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleBasicInfoSubmit = (values: ShippingValues) => {
    const { nextStep, checkout } = this.props
    const { owner } = checkout
    const { userId } = owner.ids

    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      address: {
        address: values.streetAddress,
        secondAddress: values.secondAddress,
        city: values.city,
        state: values.state,
        zip: values.zipCode,
      },
    }

    this.props.updateShipping(userId, payload)
    this.props.changeStep(nextStep)

    trackEvent(SegmentEvents.CheckoutStepCompleted, {
      step: CheckoutStep.Shipping,
      email: owner.email,
    })
  }

  getInitialValues = () => {
    const {
      checkout: { owner },
    } = this.props

    return {
      firstName: getSafe(() => owner.firstName, ''),
      lastName: getSafe(() => owner.lastName, ''),
      phoneNumber: getSafe(() => owner.phoneNumber, ''),
      streetAddress: getSafe(() => owner.address.address, ''),
      secondAddress: getSafe(() => owner.address.secondAddress, ''),
      city: getSafe(() => owner.address.city, ''),
      state: getSafe(() => owner.address.state, ''),
      zipCode: getSafe(() => owner.address.zip, ''),
    }
  }

  render() {
    const initialValues = this.getInitialValues()

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={billingSchema}
        onSubmit={async (values) => this.handleBasicInfoSubmit(values)}
      >
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit} noValidate>
              <section className="form-fields">
                <Grid container spacing={2}>
                  <Grid item xs={6} lg={6}>
                    <Input
                      form={formProps}
                      name="firstName"
                      type="text"
                      label="First Name"
                      autoComplete="given-name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Input
                      form={formProps}
                      name="lastName"
                      type="text"
                      label="Last Name"
                      autoComplete="family-name"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <PhoneInput form={formProps} label="Phone Number" name="phoneNumber" />
                <Input
                  form={formProps}
                  name="streetAddress"
                  type="text"
                  label="Street Address"
                  autoComplete="address-line1"
                  fullWidth
                />
                <Input
                  form={formProps}
                  name="secondAddress"
                  placeholder="Apt #, Unit, or Suite"
                  type="text"
                  label="Address Line 2"
                  isOptional
                  autoComplete="address-line2"
                  fullWidth
                />
                <Grid container spacing={2} style={{ padding: 0 }}>
                  <Grid item xs={12} lg={4}>
                    <Input form={formProps} name="city" type="text" label="City" fullWidth />
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <Input form={formProps} name="state" type="text" maxLength={2} label="State" fullWidth />
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <Input
                      form={formProps}
                      name="zipCode"
                      type="text"
                      label="Zip Code"
                      maxLength={9}
                      autoComplete="postal-code"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </section>
              <Button type="submit">
                Next
                <span className="arrow-right" />
              </Button>
            </form>
          )
        }}
      </Formik>
    )
  }
}

const ShippingForm = connect(mapStateToProps, mapDispatchToProps)(ShippingFormComponent)

export { ShippingForm }
