import { createAction } from 'redux-actions'

import { trackEvent, SegmentEvents } from '../../api/segment'
import { actions } from './actions'
import { CheckoutStep, Reducer } from './initial-state'

export const changeStepAction = createAction(actions.changeStep)
export const changeLastVisitedStepAction = createAction(actions.changeLastVisitedStep)

const dispatchCheckoutStepViewedEvent = (email, nextStep) => {
  switch (nextStep) {
    case CheckoutStep.OwnerInfo:
      trackEvent(SegmentEvents.CheckoutStepViewed, {
        step: CheckoutStep.OwnerInfo,
      })
      break
    case CheckoutStep.Billing:
      trackEvent(SegmentEvents.CheckoutStepViewed, {
        step: CheckoutStep.Billing,
        email,
      })
      break
    case CheckoutStep.Checkout:
      trackEvent(SegmentEvents.CheckoutStepViewed, {
        step: CheckoutStep.Checkout,
        email,
      })
      break
    default:
      break
  }
}

export const changeStepReducer = {
  [actions.changeStep]: (state, { payload: nextStep }) => {
    let { validatedSteps, currentStep } = state
    const previousStep = nextStep - 1

    if (!validatedSteps.includes(state.currentStep)) {
      validatedSteps = [...validatedSteps, state.currentStep]
    }

    if (nextStep > currentStep) {
      dispatchCheckoutStepViewedEvent(state.owner.email, nextStep)
    }

    return {
      ...state,
      currentStep: nextStep,
      lastVisitedStep: previousStep <= 0 ? CheckoutStep.OwnerInfo : previousStep,
      validatedSteps,
    }
  },
  [actions.changeLastVisitedStep]: (state, { payload: lastVisitedStep }) => ({
    ...state,
    lastVisitedStep,
  }),
} as Reducer
