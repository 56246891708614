import './summary.scss'

import lodash from 'lodash'
import { DateTime } from 'luxon'
import * as React from 'react'

import {
  initialState as checkoutInitialState,
  ValidProducts,
} from '../../redux/checkout/initial-state'

type Props = {
  checkout: typeof checkoutInitialState
}

class Receipt extends React.Component<Props> {
  getFormattedDate = () =>
    DateTime.fromString(new Date().toISOString().split('T')[0], 'yyyy-MM-dd').toLocaleString(
      DateTime.DATE_MED,
    )

  formatMoney = (value) => {
    if (isNaN(value)) return 0

    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  capitalizeFirstLetter = (value: string) => value.charAt(0).toUpperCase() + value.slice(1)

  getPaymentMethodInfo(paymentDetails) {
    if (!(paymentDetails && paymentDetails.stripeSource)) {
      return <strong>Affirm Payments</strong>
    }

    const cardBrand = paymentDetails.stripeSource && paymentDetails.stripeSource.brand
    const last4Digits = paymentDetails.stripeSource && paymentDetails.stripeSource.last4

    return (
      <>
        <strong>{this.capitalizeFirstLetter(cardBrand)}</strong> - {last4Digits}
      </>
    )
  }

  render() {
    const { checkout } = this.props
    const paymentDetails = checkout.paymentResult

    return (
      <aside className="summary">
        <h2 className="summary-title">Receipt</h2>
        <ul>
          <li>
            <p>Plan</p>
            <p>{lodash.startCase(checkout.product) || ValidProducts.lifetime}</p>
          </li>
          <li>
            <p>Amount</p>
            <p>${this.formatMoney(checkout.amount)}</p>
          </li>
          <li>
            <p>Date</p>
            <p>{this.getFormattedDate()}</p>
          </li>
          <li>
            <p>Method</p>
            <p>{this.getPaymentMethodInfo(paymentDetails)}</p>
          </li>
        </ul>
      </aside>
    )
  }
}

export { Receipt }
