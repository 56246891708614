import { createActionThunk } from 'redux-thunk-actions'

import { checkEmail } from '../../api/checkout/check-email'
import { actions } from './actions'
import { Reducer } from './initial-state'

export const checkEmailAction = createActionThunk(actions.checkEmail, (email: string) => checkEmail(email))

export const checkEmailReducer = {
  [checkEmailAction.STARTED]: (state) => ({
    ...state,
  }),
  [checkEmailAction.SUCCEEDED]: (state, { payload }) => {
    if (!payload.user.email) return state

    return {
      ...state,
      owner: {
        ...state.owner,
        email: payload.user.email,
        firstName: payload.user.firstName,
        lastName: payload.user.lastName,
        phoneNumber: payload.user.phoneNumber,
        address: {
          address: payload.user.address,
          secondAddress: payload.user.secondAddress,
          city: payload.user.city,
          state: payload.user.state,
          zip: payload.user.zip,
        },
      },
    }
  },
  [checkEmailAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error || 'Something went wrong, try again later.',
  }),
  [checkEmailAction.ENDED]: (state) => ({
    ...state,
  }),
} as Reducer
