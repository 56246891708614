import * as React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AUTH_TOKEN_KEY } from '../redux/user/get-profile'
import { routePaths } from './route-paths'

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  let authToken = rest.computedMatch.params.authToken

  if (!authToken) {
    authToken = window.localStorage.getItem(AUTH_TOKEN_KEY)
  }

  const isLoggedIn = !!authToken
  const redirectComponent = <Redirect to={{ pathname: routePaths.user.login }} />

  const renderComponent = (props) => (
    <main>
      <Component {...props} />
    </main>
  )

  return (
    <Route {...rest} render={(props) => (isLoggedIn ? renderComponent({ ...props, authToken }) : redirectComponent)} />
  )
}
