export class SpeciesRef {

  private constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly description: string,
    public readonly optionValue: string,
  ) {}
  static readonly CANINE = new SpeciesRef(1, 'Canine', 'Dog', 'dog')
  static readonly FELINE = new SpeciesRef(2, 'Feline', 'Cat', 'cat')
  static readonly EQUINE = new SpeciesRef(3, 'Equine', 'Horse', 'horse')

  static toArray() {
    return [SpeciesRef.CANINE, SpeciesRef.FELINE, SpeciesRef.EQUINE]
  }

  toString() {
    return this.name
  }
}
