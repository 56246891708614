import * as Yup from 'yup'

import { phoneRegExp } from '../../../checkout/schemas/regex-validators'

const requiredMessage = 'Can’t be blank.'

export const ownerInfoSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredMessage),
  lastName: Yup.string().required(requiredMessage),
  email: Yup.string()
    .email('Must be email format.')
    .required(requiredMessage),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Must be a valid US phone number.'),
  streetAddress: Yup.string().required(requiredMessage),
  city: Yup.string().required(requiredMessage),
  state: Yup.string().required(requiredMessage),
  zip: Yup.string()
    .required(requiredMessage)
    .matches(/^\d{5}(?:\d{4})?$/, 'Invalid zip code.'),
})
