import { createActionThunk } from 'redux-thunk-actions'

import { createAuthToken } from '../../api/auth-tokens/create-auth-token'
import { actions } from './actions'
import { Reducer } from './initial-state'

export const createAuthTokenAction = createActionThunk(actions.submitLogin, (setupData) => createAuthToken(setupData))

export const createAuthTokenReducer = {
  [createAuthTokenAction.STARTED]: (state) => ({
    ...state,
    creatingAuthToken: true,
  }),
  [createAuthTokenAction.SUCCEEDED]: (state) => ({
    ...state,
    authTokenCreated: true,
  }),
  [createAuthTokenAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error || 'Something went wrong, try again later.',
  }),
  [createAuthTokenAction.ENDED]: (state) => ({
    ...state,
    creatingAuthToken: false,
  }),
} as Reducer
