import * as Yup from 'yup'

import { disableWeekendDays } from '../../../shared/forms/DatePicker'

export const procedureSchema = Yup.object().shape({
  procedureDate: Yup.date()
    .nullable()
    .test(
      'procedureDate',
      'The procedure day needs to be between Monday and Thursday, not including holidays or a day before the holiday.',
      (value) => !disableWeekendDays(value),
    ),
})
