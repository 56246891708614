import { withStyles, MenuItem } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import { debounce } from 'debounce'
import React, { useState } from 'react'
import { geocodeByAddress } from 'react-places-autocomplete'
import { styles } from './autocomplete/autocomplete-styles'

interface Props {
  classes: any
  form: any
  InputLabelProps?: object
  InputProps?: object
  label: string
  name: string
  onChange?: any
  onSelect?: any
}

const fetchSuggestions = debounce(async (e, setSuggestions, setLoading) => {
  setLoading(true)

  try {
    const { data } = await axios({
      method: 'get',
      url: `/clinics?name=${e.target.value}`,
    })
    setSuggestions(data.clinics)
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error)
    setSuggestions([])
  }

  setLoading(false)
}, 500)

interface Place {
  name: string
  address: string
  city: string
  state: string
  zipcode: string
}

const formatPlace = (place): Place => {
  const [address, city, additional] = place.formatted_address.split(', ')
  const [state, zipcode] = additional.split(' ')

  return {
    name: place.name,
    address,
    city,
    state,
    zipcode,
  }
}

const InputPlacesComponent = (props: Props) => {
  const { form, classes, InputProps, InputLabelProps, onSelect, label, name } = props
  const { values, errors, submitCount } = form

  const [localValue, setLocalValue] = useState('')

  const onChoosePlace = (place) => {
    geocodeByAddress(place)
      .then((results) => {
        const name = place.split(', ')[0]

        onSelect(formatPlace({ name, ...results[0] }))
        setLocalValue(name)
      })
      // tslint:disable-next-line: no-console
      .catch((error) => console.error(error))
  }

  const [suggestions, setSuggestions] = useState([])
  const [loading, setLoading] = useState(false)

  const inputPropsAsAny: any = {
    //disableUnderline: true,
    classes: {
      root: classes.inputRoot,
      input: classes.inputInput,
    },
    ...InputProps,
  }

  return (
    <>
      <TextField
        className={`${classes.textField} location-search-input`}
        variant="outlined"
        InputLabelProps={InputLabelProps}
        fullWidth={true}
        autoComplete="off"
        label={label}
        placeholder="Type to find your vet’s practice…"
        InputProps={inputPropsAsAny}
        value={localValue}
        onChange={(e) => {
          e.persist()
          setLocalValue(e.target.value)
          fetchSuggestions(e, setSuggestions, setLoading)
        }}
        onBlur={(e) => {
          if (!e.relatedTarget) return

          if (!e.relatedTarget.className.split(' ').some((a) => a === 'MuiButtonBase-root')) {
            onSelect({ ...values, zipcode: values.zip, name: localValue })
            setSuggestions([])
          }
        }}
      />
      <Paper className={classes.paper} square>
        {loading && (
          <MenuItem key={0} component="div">
            Loading...
          </MenuItem>
        )}
        {!loading &&
          suggestions.map((suggestion) => {
            return (
              <MenuItem
                key={suggestion}
                component="div"
                onClick={() => {
                  onChoosePlace(suggestion)
                  setSuggestions([])
                }}
              >
                {suggestion}
              </MenuItem>
            )
          })}
      </Paper>
      <p className="error">{!!submitCount && errors[name]}</p>
    </>
  )
}

const InputPlaces = withStyles(styles)(InputPlacesComponent)

export { InputPlaces }
