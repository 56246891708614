import { createActionThunk } from 'redux-thunk-actions'

import { checkout } from '../../api/checkout/checkout'
import { actions } from './actions'
import { initialState, Checkout, CheckoutStep, Reducer } from './initial-state'

export const checkoutAction = createActionThunk(actions.submitCheckout, (checkoutData: Checkout) =>
  checkout(checkoutData),
)

export const checkoutReducer = {
  [checkoutAction.STARTED]: (state, { payload }) => ({
    ...state,
    checkingOut: true,
    product: payload.product,
    amount: payload.amount,
    error: initialState.error,
  }),
  [checkoutAction.SUCCEEDED]: (state, { payload }) => ({
    ...state,
    checkedOut: true,
    currentStep: CheckoutStep.Shipping,
    paymentResult: payload.paymentResult,
    owner: {
      ...state.owner,
      ids: {
        ...state.owner.ids,
        orderId: payload.orderId,
      },
    },
  }),
  [checkoutAction.FAILED]: (state, { payload }) => ({
    ...state,
    checkedOut: false,
    error: payload,
  }),
  [checkoutAction.ENDED]: (state) => ({
    ...state,
    checkingOut: false,
  }),
} as Reducer
