import axios from 'axios'

export const getPlans = async () => {
  const { data } = await axios({
    method: 'get',
    url: '/checkout/plans',
  })

  return data
}
