import { identify as rawIdentify } from './identify'
import { page as rawPage } from './page'
import { trackEvent as rawTrackEvent } from './track-event'

export enum SegmentEvents {
  Checkout = 'Checkout',
  Setup = 'Setup',
  CheckoutStarted = 'Checkout Started',
  CheckoutStepViewed = 'Checkout Step Viewed',
  CheckoutStepCompleted = 'Checkout Step Completed',
  CouponApplied = 'Coupon Applied',
  CouponDenied = 'Coupon Denied',
  CouponEntered = 'Coupon Entered',
  ProductAdded = 'Product Added',
  ProcedureScheduled = 'Procedure Scheduled',
  PetInfoSaved = 'Pet Info Saved',
  OrderUpdated = 'Order Updated',
  OrderCompleted = 'Order Completed',
  VetInfoSaved = 'Vet Info Saved',
}

const isSegmentDisabled = () => {
  const urlParams = new URLSearchParams(window.location.search)

  return urlParams.get('disable-segment')
}

const fnWrapper = (fn) => async (
  event: string | number,
  payload: object = {},
  options: object = {},
) => {
  const analytics = (window as any).analytics

  if (!analytics || !analytics.user) {
    // Analytics not loaded yet from public/index.html JS snippet
    return setTimeout(async () => {
      const wrappedFn = fnWrapper(fn)
      await wrappedFn(event, payload)
    }, 500)
  }

  if (isSegmentDisabled()) {
    // tslint:disable-next-line: no-console
    console.warn('Segment is disabled for tests.')

    options = {
      integrations: {
        All: false,
      },
    }
  }

  return await fn(analytics, event, payload, options)
}

const identify = fnWrapper(rawIdentify)
const trackEvent = fnWrapper(rawTrackEvent)
const page = fnWrapper(rawPage)

export { identify, trackEvent, page }
