import { createSelector } from 'reselect'

import { ValidProducts, ValidProductsInterval } from '../checkout/initial-state'
import { Store } from '../configure-store'

const checkout = ({ checkout }: Store) =>
  ({
    plans: checkout.availablePlans,
    tax: checkout.calculatedTax,
    coupon: checkout.coupon,
    product: checkout.product,
    subscriptionDetails: checkout.subscriptionCheckoutDetails,
  } as Checkout)

interface Plan {
  id: string
  amount: number
  interval: string
}

interface SubscriptionDetails {
  sku: { price: number }
}

export interface Coupon {
  id: string
  object: string
  amountOff?: number
  appliesTo?: AppliesTo
  created: number
  currency?: any
  duration: string
  durationInMonths?: any
  livemode: boolean
  maxRedemptions?: any
  metadata?: Metadata
  name: string
  percentOff?: number
  redeemBy?: number
  timesRedeemed: number
  valid: boolean
  couponCode: string
}

interface Metadata {
  [key: string]: string
}

interface AppliesTo {
  products: string[]
}

interface Checkout {
  tax: number
  plans: Plan[]
  coupon: Coupon
  subscriptionDetails: SubscriptionDetails
  product: string
}

export const calculateCheckout = createSelector(
  [checkout],
  (checkout: Checkout) => {
    const product = checkout.product

    let price = getPrice(checkout.subscriptionDetails) || 0
    const storagePrice = getStoragePrice(product, checkout.plans)

    // "monthly" does not have a SKU, adding it hardcoded
    if (product === 'monthly') {
      price = 45
    }

    // The storage price isn't charged on checkout anymore.
    const priceWithStorage = price
    const tax = checkout.tax * priceWithStorage
    const discount = getDiscount(checkout.coupon, priceWithStorage, product)
    const storagePriceWithDiscount = storagePrice - discount
    const totalPerInterval = calculateTotalPerInterval(
      storagePriceWithDiscount,
      checkout.tax,
      product,
    )
    const total = priceWithStorage - discount
    const totalWithTaxes = calculateTotalWithTaxes(checkout.tax, total)

    return {
      discount,
      extraProductPrice: price,
      storagePrice,
      storageWithExtraProducts: priceWithStorage,
      subscriptionType: product,
      subtotal: priceWithStorage,
      tax,
      totalPerInterval,
      totalWithTaxes,
    }
  },
)

const getStoragePrice = (product: string, plans: Plan[]) => {
  if (
    product === ValidProducts.lifetime ||
    product === ValidProducts.monthly ||
    product === ValidProducts.adiposeLifetime ||
    product === ValidProducts.equineLifetime ||
    plans.length === 0
  ) {
    return 0
  }

  const productInterval = ValidProductsInterval[product || 'lifetime']

  const findPlanByInterval = (availablePlan: { interval: string }) =>
    availablePlan.interval === productInterval

  const currentPlan = plans.find(findPlanByInterval)

  return currentPlan.amount
}

const getPrice = (subscriptionDetails: SubscriptionDetails) => {
  const price = subscriptionDetails.sku && subscriptionDetails.sku.price

  return price / 100
}

export const isCouponEligible = (coupon: Coupon, product: string) => {
  if (
    coupon &&
    coupon.valid &&
    coupon.appliesTo &&
    coupon.appliesTo.products &&
    coupon.appliesTo.products.includes(product)
  ) {
    return true
  }

  return false
}

const getDiscount = (coupon: Coupon, amount: number, product: string) => {
  if (!coupon || !isCouponEligible(coupon, product)) {
    return 0
  }

  return coupon.percentOff ? amount * coupon.percentOff : coupon.amountOff
}

const calculateTotalPerInterval = (amount: number, tax: number, product: string) => {
  if (product === ValidProducts.lifetime) {
    return 0
  }

  return amount + amount * +tax
}

const calculateTotalWithTaxes = (tax: number, amount: number) => {
  let total = amount

  if (tax) {
    const taxAmount = tax * amount
    total = total + taxAmount
  }

  return total > 0 ? total : 0
}
