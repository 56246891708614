import { createActionThunk } from 'redux-thunk-actions'

import { getSubscriptionCheckoutDetails } from '../../api/checkout/get-subscription-checkout-details'
import { actions } from './actions'
import { initialState, Reducer } from './initial-state'

export const getSubscriptionCheckoutDetailsAction = createActionThunk(
  actions.getSubscriptionDetails,
  (product: string) => getSubscriptionCheckoutDetails(product),
)

export const getSubscriptionCheckoutDetailsReducer = {
  [getSubscriptionCheckoutDetailsAction.STARTED]: (state) => ({
    ...state,
    loadingCheckoutDetails: true,
    subscriptionCheckoutDetails: initialState.subscriptionCheckoutDetails,
  }),
  [getSubscriptionCheckoutDetailsAction.SUCCEEDED]: (state, { payload }) => ({
    ...state,
    subscriptionCheckoutDetails: payload.subscriptionCheckoutDetails,
    loadingCheckoutDetails: false,
    error: undefined,
  }),
  [getSubscriptionCheckoutDetailsAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
    loadingCheckoutDetails: false,
    subscriptionCheckoutDetails: initialState.subscriptionCheckoutDetails,
  }),
  [getSubscriptionCheckoutDetailsAction.ENDED]: (state) => ({
    ...state,
    loadingCheckoutDetails: false,
  }),
} as Reducer
