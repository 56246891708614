import * as React from 'react'
import { initialState as checkoutInitialState } from '../../../../../../redux/checkout/initial-state'

interface boolFunc {
  (): boolean
}

interface CouponStatusProps {
  checkout: typeof checkoutInitialState
  invalidCoupon: boolean
  isCouponEligible: boolFunc
}

const CouponStatus: React.FunctionComponent<CouponStatusProps> = ({ checkout, invalidCoupon, isCouponEligible }) => (
  <>
    <div>
      {(invalidCoupon || (checkout.coupon && !isCouponEligible())) && (
        <p className="error alert">This coupon code is invalid.</p>
      )}
      {isCouponEligible() && !invalidCoupon && <p className="alert success">Promo Code: {checkout.coupon.name}</p>}
    </div>
  </>
)

export { CouponStatus }
