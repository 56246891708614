import '../checkout.scss'

import cx from 'classnames'
import { Formik } from 'formik'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button, Checkbox, FormControlLabel } from '@material-ui/core'

import { identify, trackEvent, SegmentEvents } from '../../../api/segment'
import { changeStepAction } from '../../../redux/checkout/change-step'
import { createPetProfessionalAction } from '../../../redux/checkout/create-pet-professional'
import { CheckoutStep, SetupStep } from '../../../redux/checkout/initial-state'
import {
  initialState as checkoutInitialState,
  PetProfessional,
} from '../../../redux/checkout/initial-state'
import { updateOwnerAction } from '../../../redux/checkout/update-owner'
import { updatePetProfessionalAction } from '../../../redux/checkout/update-pet-professional'
import { Store } from '../../../redux/configure-store'
import { DatePicker } from '../../shared/forms/DatePicker'
import { Input } from '../../shared/forms/Input'
import { InputPlaces } from '../../shared/forms/InputPlaces'
import { PhoneInput } from '../../shared/forms/PhoneInput'
import { petProfessionalSchema } from '../schemas/pet-professional-schema'
import { formatDate, getSafe } from './utils'

const mapStateToProps = (state: Store) => {
  return {
    checkout: state.checkout,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateOwner: bindActionCreators(updateOwnerAction, dispatch),
    changeStep: bindActionCreators(changeStepAction, dispatch),
    createPetProfessional: bindActionCreators(createPetProfessionalAction, dispatch),
    updatePetProfessional: bindActionCreators(updatePetProfessionalAction, dispatch),
  }
}

type Dispatchers = ReturnType<typeof mapDispatchToProps>
type Props = {
  checkout: typeof checkoutInitialState
  nextStep: CheckoutStep | SetupStep
  hideProcedureDate: boolean
  submitText: string
  hideHasVet: boolean
} & Dispatchers

export interface PetProfessionalValues {
  practiceName: string
  practicePhoneNumber: string
  address: string
  secondAddress: string
  city: string
  state: string
  zip: string
  procedureDate: Date
  hasVet: boolean
}

export class PetProfessionalFormComponent extends React.Component<Props, any> {
  static defaultProps = {
    hideProcedureDate: false,
    hideHasVet: false,
    submitText: 'Finish',
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleVetInfoSubmit = (values: PetProfessionalValues) => {
    const { checkout, nextStep } = this.props

    const petProfessional = {
      practice: {
        name: values.practiceName,
        phoneNumber: values.practicePhoneNumber,
      },
      address: {
        address: values.address,
        secondAddress: values.secondAddress,
        city: values.city,
        state: values.state,
        zip: values.zip,
      },
    } as Partial<PetProfessional>

    const procedure = values.procedureDate
      ? {
          procedureDate: values.procedureDate && formatDate(values.procedureDate),
        }
      : checkout.owner.procedure

    this.props.updateOwner({ info: { petProfessional, procedure } })
    this.props.changeStep(nextStep)

    this.trackPetProfessionalEvents(petProfessional)
    this.trackProcedureEvents(procedure)

    if (checkout.owner.ids.petProfessionalId) {
      return this.props.updatePetProfessional({
        petProfessionalId: checkout.owner.ids.petProfessionalId,
        petProfessional,
      })
    }

    this.props.createPetProfessional({
      petProfessional,
      orderId: checkout.owner.ids.orderId,
      procedure,
      petId: checkout.owner.ids.petId,
    })
  }

  trackPetProfessionalEvents = (petProfessional) => {
    const { checkout } = this.props

    trackEvent(SegmentEvents.VetInfoSaved)

    identify(checkout.owner.ids.userId, {
      vet: {
        practice_name: petProfessional.practice.name,
        phone: petProfessional.practice.phoneNumber,
      },
    })
  }

  trackProcedureEvents = (procedure) => {
    const { checkout } = this.props

    if (!procedure.procedureDate) {
      return
    }

    trackEvent(SegmentEvents.ProcedureScheduled, {
      procedure_date: procedure.procedureDate,
    })

    identify(checkout.owner.ids.userId, {
      procedure_date: procedure.procedureDate,
    })
  }

  getInitialValues = (): PetProfessionalValues => {
    const {
      checkout: { owner },
    } = this.props

    return {
      practiceName: getSafe(() => owner.petProfessional.practice.name, ''),
      practicePhoneNumber: getSafe(() => owner.petProfessional.practice.phoneNumber, ''),
      address: getSafe(() => owner.petProfessional.address.address, ''),
      secondAddress: getSafe(() => owner.petProfessional.address.secondAddress, ''),
      city: getSafe(() => owner.petProfessional.address.city, ''),
      state: getSafe(() => owner.petProfessional.address.state, ''),
      zip: getSafe(() => owner.petProfessional.address.zip, ''),
      procedureDate: getSafe(() => owner.procedure.procedureDate, null),
      hasVet: true,
    }
  }

  renderForm(formProps) {
    const { hideHasVet, submitText, nextStep } = this.props
    const { values, errors, touched } = formProps

    const togglehasVet = () => formProps.setFieldValue('hasVet', !values.hasVet)
    const onClickSubmit = () => !values.hasVet && this.props.changeStep(nextStep)

    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        <section className="form-fields">
          {!hideHasVet && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={!values.hasVet}
                  onChange={togglehasVet}
                  name="hasVet"
                  value="checked"
                  color="primary"
                />
              }
              label="I don't have a vet yet"
            />
          )}
          {values.hasVet && (
            <>
              <InputPlaces
                label="Name of your vet’s practice"
                form={formProps}
                name="practiceName"
                onSelect={({ name: practiceName, address, city, state, zipcode: zip }) =>
                  formProps.setValues({ ...values, practiceName, address, city, state, zip })
                }
              />
              <Input
                form={formProps}
                name="address"
                type="text"
                label="Address"
                fullWidth
                autoComplete="off"
              />
              <Input
                form={formProps}
                name="secondAddress"
                type="text"
                label="Address Line 2"
                placeholder="Unit or Suite"
                isOptional
                fullWidth
                autoComplete="off"
              />
              <div className="same-line">
                <Input
                  className="right-margin"
                  form={formProps}
                  name="city"
                  type="text"
                  label="City"
                  fullWidth
                  autoComplete="off"
                />
                <Input
                  className="right-margin"
                  form={formProps}
                  name="state"
                  type="text"
                  label="State"
                  fullWidth
                  autoComplete="off"
                  maxLength={2}
                />
                <Input
                  form={formProps}
                  name="zip"
                  type="text"
                  label="Zip Code"
                  maxLength={9}
                  fullWidth
                  autoComplete="off"
                />
              </div>
              <div className="same-line">
                <PhoneInput
                  form={formProps}
                  label="Phone Number"
                  name="practicePhoneNumber"
                  className={cx({ 'right-margin': !this.props.hideProcedureDate })}
                />
                {!this.props.hideProcedureDate && (
                  <DatePicker
                    name="procedureDate"
                    form={formProps}
                    onChange={(date) => formProps.setFieldValue('procedureDate', date)}
                    subLabel="If you haven’t scheduled yet, you can leave this blank."
                  />
                )}
              </div>
              <div className="same-line" style={{ marginTop: 20 }}>
                {errors.procedureDate && touched.procedureDate && (
                  <p className="error">* {errors.procedureDate}</p>
                )}
              </div>
            </>
          )}
        </section>
        <Button type="submit" onClick={onClickSubmit} disabled={formProps.isSubmitting}>
          {submitText}
          <span className="arrow-right" />
        </Button>
      </form>
    )
  }

  render() {
    const initialValues = this.getInitialValues()

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={petProfessionalSchema}
        onSubmit={(values) => this.handleVetInfoSubmit(values)}
      >
        {(formProps) => this.renderForm(formProps)}
      </Formik>
    )
  }
}

const PetProfessionalForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PetProfessionalFormComponent)

export { PetProfessionalForm }
