import './checkout.scss'

import cx from 'classnames'
import lodash from 'lodash'
import * as queryString from 'query-string'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Segment from '../../api/segment/index'
import { config } from '../../config'
import { changeProductAction } from '../../redux/checkout/change-product'
import { changeStepAction } from '../../redux/checkout/change-step'
import { checkEmailAction } from '../../redux/checkout/check-email'
import { getPlansAction } from '../../redux/checkout/get-available-plans'
import { getCouponAction } from '../../redux/checkout/get-coupon'
import { getSubscriptionCheckoutDetailsAction } from '../../redux/checkout/get-subscription-details'
import {
  initialState as checkoutInitialState,
  CheckoutStep,
  ValidProducts,
} from '../../redux/checkout/initial-state'
import { resetFormAction } from '../../redux/checkout/reset-form'
import { Store } from '../../redux/configure-store'
import { routePaths } from '../route-paths'
import { Receipt } from './Receipt'
import { Success } from './Success'
import { Summary } from './Summary'
import { BillingForm } from './forms/BillingForm'
import { OwnerForm } from './forms/OwnerForm'
import { PetForm } from './forms/PetForm'
import { PetProfessionalForm } from './forms/PetProfessionalForm'
import { ShippingForm } from './forms/ShippingForm'
import { StripePaymentProvider } from './forms/StripePaymentProvider'

const mapStateToProps = (state: Store) => {
  return {
    checkout: state.checkout,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeStep: bindActionCreators(changeStepAction, dispatch),
    getPlans: bindActionCreators(getPlansAction, dispatch),
    resetForm: bindActionCreators(resetFormAction, dispatch),
    changeProduct: bindActionCreators(changeProductAction, dispatch),
    checkEmail: bindActionCreators(checkEmailAction, dispatch),
    getCoupon: bindActionCreators(getCouponAction, dispatch),
    getSubscriptionCheckoutDetails: bindActionCreators(
      getSubscriptionCheckoutDetailsAction,
      dispatch,
    ),
  }
}

type Dispatchers = ReturnType<typeof mapDispatchToProps>
type Props = {
  history: any
  location: any
  checkout: typeof checkoutInitialState
  match: { params: { product: string } }
} & Dispatchers

export class CheckoutComponent extends React.Component<Props, any> {
  async componentDidMount() {
    Segment.page(Segment.SegmentEvents.Checkout)

    const { history, location } = this.props

    this.props.checkout.currentStep === CheckoutStep.Success && this.props.resetForm()

    const productFromPath = lodash.camelCase(this.props.match.params.product)

    const product =
      ValidProducts[productFromPath || this.props.checkout.product] || ValidProducts.lifetime

    history.push(
      `${routePaths.plans.replace(':product', lodash.kebabCase(product))}/${location.search}`,
    )

    await this.props.getSubscriptionCheckoutDetails(product)
    await this.props.changeProduct(product)
    await this.props.getPlans()

    this.setUrlFieldValues()
  }

  setUrlFieldValues = () => {
    const { location } = this.props
    const values = queryString.parse(location.search)

    if (values.email) {
      this.props.checkEmail(values.email)
    }

    if (values.coupon) {
      this.props.getCoupon(values.coupon)
    }
  }

  renderHeader = () => {
    const { checkout } = this.props
    const showBackArrow =
      !checkout.checkingOut &&
      [
        CheckoutStep.Billing,
        CheckoutStep.Checkout,
        CheckoutStep.PetInfo,
        CheckoutStep.PetProfessionalInfo,
      ].includes(checkout.currentStep as CheckoutStep)

    return (
      <section className="header">
        {showBackArrow ? (
          <button
            className=" no-style-btn"
            disabled={checkout.checkingOut}
            onClick={() => this.props.changeStep(checkout.lastVisitedStep)}
          >
            <FontAwesomeIcon className="back-arrow" icon={faArrowLeft} />
          </button>
        ) : null}
        <div className={cx('image-logo', { centralized: showBackArrow })} />
      </section>
    )
  }

  goToStep = (targetStep) => {
    const { currentStep } = this.props.checkout

    // You can't back to checkout steps when in Post Checkout.
    if (
      [CheckoutStep.OwnerInfo, CheckoutStep.Billing, CheckoutStep.Checkout].includes(targetStep) &&
      currentStep > CheckoutStep.Checkout
    )
      return null

    // Only can go back to previously and completed steps.
    return currentStep >= targetStep && this.props.changeStep(targetStep)
  }

  renderCheckoutWizard = () => {
    const { checkout } = this.props
    const { pet } = checkout.owner

    const steps = [
      {
        key: CheckoutStep.OwnerInfo,
        className: 'owner',
        component: <OwnerForm />,
        heading: 'Tell us a little about yourself and your pet',
      },
      {
        key: CheckoutStep.Billing,
        className: 'billing',
        component: <BillingForm />,
        heading: 'Billing',
      },
      {
        key: CheckoutStep.Checkout,
        className: 'checkout',
        component: <StripePaymentProvider />,
        heading: 'Payment',
      },
      {
        key: CheckoutStep.Shipping,
        className: 'shipping',
        component: <ShippingForm nextStep={CheckoutStep.PetInfo} />,
        heading: `Shipping (We have a welcome gift we'd like to send you)`,
      },
      {
        key: CheckoutStep.PetInfo,
        className: 'pet',
        component: <PetForm nextStep={CheckoutStep.PetProfessionalInfo} />,
        heading: `Tell us a little more about ${pet.name || 'your pet'}`,
      },
      {
        key: CheckoutStep.PetProfessionalInfo,
        className: 'vet',
        component: <PetProfessionalForm nextStep={CheckoutStep.Success} />,
        heading: `Tell us about ${pet.name || 'pet'}'s vet`,
      },
    ]

    const isPrevCheckout = checkout.currentStep <= CheckoutStep.Checkout
    const isAfterCheckout = checkout.currentStep > CheckoutStep.Checkout
    const isGift = checkout.owner.isGift

    const SuccessCheckoutItem = () => (
      <li className="wizard-item completed">
        <div className="wizard-item-heading">
          Congratulations! If they could talk, {pet.name} would say thank you.
        </div>
      </li>
    )

    const GiftMessage = () => (
      <li className="wizard-item completed">
        <div className="wizard-item-heading">
          You're all done! We’ll reach out via email to setup the recipient of your Gift.{' '}
          <a href="https://gallant.com">Back to homepage</a>
        </div>
      </li>
    )

    if (isAfterCheckout && isGift) {
      return (
        <ol className="wizard">
          <GiftMessage />
        </ol>
      )
    }

    return (
      <ol
        className={cx('wizard', {
          'prev-checkout': isPrevCheckout,
          'post-checkout': isAfterCheckout,
        })}
      >
        {isAfterCheckout && <SuccessCheckoutItem />}
        {steps.map((step) => (
          <li
            key={step.key}
            className={cx('wizard-item', step.className, {
              active: step.key === checkout.currentStep,
              completed: step.key < checkout.currentStep,
            })}
          >
            <div className="wizard-item-heading" onClick={() => this.goToStep(step.key)}>
              {step.heading}
            </div>
            <div className="wizard-item-content">{step.component}</div>
          </li>
        ))}
      </ol>
    )
  }

  render() {
    const { checkout } = this.props
    const isPrevCheckout = checkout.currentStep <= CheckoutStep.Checkout

    return (
      <div className="container">
        <Helmet
          title="Checkout | Gallant"
          link={[{ rel: 'canonical', href: window.location.pathname }]}
          meta={[
            {
              name: 'description',
              content:
                'Stem cell storage and regenerative therapy for healthier, happier pets. Select between lifetime, annual, and monthly plans.',
            },
          ]}
        />
        {this.renderHeader()}
        {checkout.currentStep === CheckoutStep.Success ? (
          <section className="content">
            <h2>Awesome, {checkout.owner.firstName}!</h2>
            <p className="copy">
              You can review the information below. To add/edit details, view status, or add
              additional pets, visit <a href={config.loginUrl}>gallant.com/login</a>
            </p>
            <Success />
          </section>
        ) : (
          <>
            <section className="hero">
              <img className="icon" src="/assets/images/gallant.svg" />
              <h2>Secure Checkout</h2>
            </section>
            <div className="checkout-wrap has-summary">
              <section className="content">{this.renderCheckoutWizard()}</section>
              <div className="summary-container">
                {isPrevCheckout ? <Summary /> : <Receipt checkout={checkout} />}
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

const Checkout = connect(mapStateToProps, mapDispatchToProps)(CheckoutComponent)

export { Checkout }
