import * as React from 'react'

import { FormControlLabel, Radio } from '@material-ui/core'
import { ValidProducts } from '../../../../redux/checkout/initial-state'
import { PaymentProvider } from './PaymentForm'

interface PaymentOptionProps {
  checked: boolean
  disabled: boolean
  src: string
  alt: string
  onChange: any
  name?: string
}

const PaymentOption: React.FunctionComponent<PaymentOptionProps> = ({
  checked,
  disabled,
  name,
  src,
  alt,
  onChange,
}) => (
  <FormControlLabel
    label={<img height={38} src={src} alt={alt} />}
    value={checked}
    disabled={disabled}
    control={
      <Radio
        color="primary"
        checked={checked}
        onChange={onChange}
        inputProps={{ name }}
        disabled={disabled}
      />
    }
    style={{ opacity: disabled ? 0.3 : 1, transition: 'all 0.5s ease' }}
  />
)

interface LifetimePaymentChooserProps {
  selectedPaymentProvider: PaymentProvider
  availableMobilePaymentProvider: PaymentProvider
  onPaymentProviderChange: any
  canMakePayment: boolean
  product: string
}

const LifetimePaymentChooser: React.FunctionComponent<LifetimePaymentChooserProps> = ({
  selectedPaymentProvider,
  availableMobilePaymentProvider,
  onPaymentProviderChange,
  canMakePayment,
  product,
}) => {
  const lifetime =
    product === ValidProducts.lifetime ||
    product === ValidProducts.equineLifetime ||
    product === ValidProducts.adiposeLifetime

  return (
    <section className="payments form-fields">
      <div className="radio-input">
        <PaymentOption
          name="stripe"
          alt="Credit Card"
          checked={selectedPaymentProvider === PaymentProvider.Stripe}
          disabled={false}
          src="/assets/images/stripe-button.svg"
          onChange={() => onPaymentProviderChange(PaymentProvider.Stripe)}
        />
        {/*TODO: Unhide apple pay option*/}
        {false && canMakePayment && availableMobilePaymentProvider === PaymentProvider.ApplePay && (
          <PaymentOption
            name="apple-pay"
            alt="Apple Pay"
            checked={selectedPaymentProvider === PaymentProvider.ApplePay}
            disabled={!canMakePayment}
            src="/assets/images/apple-pay-button.svg"
            onChange={() => onPaymentProviderChange(PaymentProvider.ApplePay)}
          />
        )}
        {canMakePayment && availableMobilePaymentProvider === PaymentProvider.GooglePay && (
          <PaymentOption
            name="google-pay"
            alt="Google Pay"
            disabled={!canMakePayment}
            checked={selectedPaymentProvider === PaymentProvider.GooglePay}
            src="/assets/images/google-pay-button.svg"
            onChange={() => onPaymentProviderChange(PaymentProvider.GooglePay)}
          />
        )}
        <PaymentOption
          name="affirm"
          alt="Affirm"
          checked={selectedPaymentProvider === PaymentProvider.Affirm}
          disabled={!lifetime}
          src="/assets/images/affirm-button.svg"
          onChange={() => onPaymentProviderChange(PaymentProvider.Affirm)}
        />
        <span
          className="lifetime-only"
          style={{
            opacity: !lifetime ? 0.3 : 0,
            transition: 'all 0.5s ease',
          }}
        >
          Available for Lifetime plan, only
        </span>
      </div>
    </section>
  )
}

export { LifetimePaymentChooser }
