import { createStyles } from '@material-ui/core/styles'

export const styles = () =>
  createStyles({
    container: {
      marginTop: '16px',
    },
    inputInput: {
      padding: '16px',
      fontFamily: 'Akkurat',
    },
  })
