import './setup-steps.scss'

import cx from 'classnames'
import * as React from 'react'

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SetupStep } from '../../redux/checkout/initial-state'

const renderStep = ({
  step,
  currentStep,
  onStepClick,
  validatedSteps,
  skippingSteps,
}: {
  step: number
  currentStep: number
  onStepClick: (step: number) => void
  validatedSteps: number[]
  skippingSteps: number[]
}) => {
  const isValidated = validatedSteps.includes(step)
  const skippingStep = skippingSteps.includes(step)
  const isCurrentStep = step === currentStep
  const previousStep = step - 1
  const isDisabled =
    (step !== 1 && (!validatedSteps.includes(previousStep) && !skippingSteps.includes(previousStep))) ||
    isCurrentStep ||
    skippingStep

  let buttonLabel: any = step

  if (isValidated && !isCurrentStep) {
    buttonLabel = <FontAwesomeIcon icon={faCheck} />
  }

  if (skippingStep) {
    buttonLabel = <FontAwesomeIcon icon={faTimes} />
  }

  return (
    <div className="step-container">
      <button
        onClick={() => onStepClick(step)}
        disabled={isDisabled}
        className={cx('step', {
          current: isCurrentStep,
          validated: isValidated && !isCurrentStep && !skippingStep,
        })}
      >
        {buttonLabel}
      </button>
      <p className={cx({ 'active-label': isCurrentStep })}>{getStepLabel(step)}</p>
    </div>
  )
}

const getStepLabel = (step: number) => {
  const labels = {
    [SetupStep.Shipping]: 'Shipping',
    [SetupStep.PetInfo]: 'Pet',
    [SetupStep.PetProfessionalInfo]: 'Vet',
    [SetupStep.Procedure]: 'Date',
  }

  return labels[step]
}

export const SetupSteps = ({ currentStep, onStepClick, validatedSteps, skippingSteps } = this.props) => {
  return (
    <div className="setup-steps">
      <span className="divider" />
      {renderStep({ step: SetupStep.Shipping, currentStep, onStepClick, validatedSteps, skippingSteps })}
      {renderStep({ step: SetupStep.PetInfo, currentStep, onStepClick, validatedSteps, skippingSteps })}
      {renderStep({ step: SetupStep.PetProfessionalInfo, currentStep, onStepClick, validatedSteps, skippingSteps })}
      {renderStep({ step: SetupStep.Procedure, currentStep, onStepClick, validatedSteps, skippingSteps })}
    </div>
  )
}
