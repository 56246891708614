import './styling/index.scss'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import { setupAxios } from './api/setup-axios'
import { App } from './containers/app/App'
import { store } from './redux/configure-store'
import * as serviceWorker from './serviceWorker'

setupAxios()

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1f81ff',
    },
  },
})

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <Route path="/" component={App} />
      </Router>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root') as HTMLElement,
)

serviceWorker.unregister()
