import cx from 'classnames'
import Downshift from 'downshift'
import * as React from 'react'

import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { styles } from './autocomplete-styles'

import { AutocompleteInput } from './AutocompleteInput'
import { Suggestion } from './Suggestion'
import { getSuggestions } from './suggestions-parser'

interface Props {
  label: string
  placeholder: string
  suggestions: any[]
  fullWidth: boolean
  handleSelectionChange: any
  selectedItem?: any
  classes: any
  disabled: boolean
  subLabel?: string
}

export const AutocompleteSingleComponent = ({
  label,
  placeholder,
  suggestions,
  fullWidth,
  handleSelectionChange,
  selectedItem,
  classes,
  disabled,
  subLabel,
}: Props) => {
  return (
    <Downshift id="downshift-simple" onChange={handleSelectionChange} selectedItem={selectedItem}>
      {({ getInputProps, getItemProps, getMenuProps, highlightedIndex, inputValue, isOpen, selectedItem }) => {
        const { onBlur, onFocus, ...inputProps } = getInputProps({
          placeholder,
          disabled,
        })

        return (
          <div className={cx('input', classes.container)}>
            <AutocompleteInput
              classes={classes}
              fullWidth={fullWidth}
              InputProps={{ onBlur, onFocus }}
              label={label}
              helperText={subLabel}
              {...inputProps}
            />
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper}>
                  {getSuggestions({ value: inputValue, suggestions }).map((suggestion, index) => (
                    <Suggestion
                      key={index}
                      suggestion={suggestion}
                      index={index}
                      itemProps={getItemProps({ item: suggestion.name })}
                      highlightedIndex={highlightedIndex}
                      selectedItem={selectedItem}
                    />
                  ))}
                </Paper>
              ) : null}
            </div>
          </div>
        )
      }}
    </Downshift>
  )
}

const AutocompleteSingle = withStyles(styles)(AutocompleteSingleComponent)

export { AutocompleteSingle }
