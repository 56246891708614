import * as Yup from 'yup'

const requiredMessage = 'Can’t be blank.'

export const ownerInfoSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredMessage),
  petName: Yup.string().required(requiredMessage),
  email: Yup.string()
    .email('Must be email format.')
    .required(requiredMessage),
})
