export const actions = {
  submitLogin: 'PROFILE/SUBMIT_LOGIN',
  getProfile: 'PROFILE/GET_PROFILE',
  updateProfile: 'PROFILE/UPDATE_PROFILE',
  updatePet: 'PROFILE/UPDATE_PET',
  updatePetPicture: 'PROFILE/UPDATE_PET_PICTURE',
  createPetProfessional: 'PROFILE/CREATE_PET_PROFESSIONAL',
  updatePetProfessional: 'PROFILE/UPDATE_PET_PROFESSIONAL',
  createProcedure: 'PROFILE/CREATE_PROCEDURE',
  updateProcedure: 'PROFILE/UPDATE_PROCEDURE',
}
