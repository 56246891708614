import axios from 'axios'

interface TrackingLog {
  orderId: number
  email: string
  event: string
}

export const trackingLog = async (logData: TrackingLog) => {
  const { data } = await axios({
    method: 'post',
    url: '/log/tracking',
    data: logData,
  })

  return data
}
