import { createActionThunk } from 'redux-thunk-actions'

import { updateOwner } from '../../api/owners/update-owner'
import { actions } from './actions'
import { Reducer } from './initial-state'

export const updateShippingAction = createActionThunk(actions.updateShipping, updateOwner)

export const updateShippingReducer = {
  [updateShippingAction.STARTED]: (state) => ({
    ...state,
    updatingShipping: true,
  }),
  [updateShippingAction.SUCCEEDED]: (state, { payload }) => ({
    ...state,
    owner: {
      ...state.owner,
      address: {
        ...state.owner.address,
        ...payload.address,
      },
    },
  }),
  [updateShippingAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [updateShippingAction.ENDED]: (state) => ({
    ...state,
    updatingShipping: false,
  }),
} as Reducer
