import axios from 'axios'

export const updatePet = async ({ petId, pet }) => {
  const { data } = await axios({
    method: 'put',
    url: `/pets/${petId}`,
    data: { pet },
  })

  return data
}
