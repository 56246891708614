import axios from 'axios'

export const createPetProfessional = async ({ petProfessional, orderId, petId, procedure }) => {
  const { data } = await axios({
    method: 'post',
    url: `/pet-professionals`,
    data: { petProfessional, orderId, petId, procedure },
  })

  return data
}
