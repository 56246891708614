import { createActionThunk } from 'redux-thunk-actions'

import { updatePetProfessional } from '../../api/pet-professionals/update-pet-professional'
import { actions } from './actions'
import { Practice, Reducer } from './initial-state'

interface UpdatePetProfessional {
  petProfessionalId: number
  practice: Practice
}

export const updatePetProfessionalAction = createActionThunk(
  actions.updatePetProfessional,
  ({ petProfessionalId, practice }: UpdatePetProfessional) =>
    updatePetProfessional({
      petProfessionalId,
      petProfessional: { practiceId: practice.id, practice, address: practice.address },
    }),
)

export const updatePetProfessionalReducer = {
  [updatePetProfessionalAction.STARTED]: (state) => ({
    ...state,
    creatingProcedure: true,
  }),
  [updatePetProfessionalAction.SUCCEEDED]: (state, { payload }) => {
    const pets = state.pets.map((pet) => {
      if (+pet.petProfessionalId === +payload.petProfessionalId) {
        return { ...pet, practice: payload.practice }
      }

      return pet
    })

    return { ...state, pets }
  },
  [updatePetProfessionalAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [updatePetProfessionalAction.ENDED]: (state) => ({
    ...state,
    creatingProcedure: false,
  }),
} as Reducer
