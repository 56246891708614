import '../checkout/checkout.scss'

import { CircularProgress } from '@material-ui/core'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getAuthTokenAction } from '../../redux/checkout/get-auth-token'
import { Store } from '../../redux/configure-store'
import { initialState } from '../../redux/user/initial-state'
import { Success } from '../checkout/Success'
import { LoginForm } from './forms/Login'

const mapStateToProps = (state: Store) => ({
  user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
  getAuthToken: bindActionCreators(getAuthTokenAction, dispatch),
})

type Dispatchers = ReturnType<typeof mapDispatchToProps>
type Props = {
  history: any
  user: typeof initialState
  match: { params: { authToken: string } }
} & Dispatchers

export class LoginComponent extends React.Component<Props, any> {
  renderContent = () => {
    const { user } = this.props

    if (user.creatingAuthToken) {
      return (
        <div className="setup-loading">
          <CircularProgress size={60} />
        </div>
      )
    }

    if (user.authTokenCreated) {
      return <Success message="We've sent you an e-mail, please check your inbox to proceed." />
    }

    return (
      <>
        <p className="copy">Please enter your email and we will send you a link to login.</p>
        <LoginForm />
      </>
    )
  }

  render() {
    return (
      <div className="container">
        <header className="header">
          <div className="image-logo" />
        </header>
        <section className="content">{this.renderContent()}</section>
      </div>
    )
  }
}

const Login = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginComponent)

export { Login }
