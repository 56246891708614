import axios from 'axios'

export const calculateTax = async (zipCode: string) => {
  const { data } = await axios({
    method: 'get',
    url: '/checkout/taxes',
    params: { zipCode },
  })

  return data
}
