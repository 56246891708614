import { createActionThunk } from 'redux-thunk-actions'

import { getAuthToken } from '../../api/auth-tokens/get-auth-token'
import { actions } from './actions'
import { Reducer, SetupStep } from './initial-state'

export const getAuthTokenAction = createActionThunk(actions.getAuthToken, (token: string) => getAuthToken(token))

interface CurrentStepResult {
  currentStep: SetupStep
  validatedSteps: Array<SetupStep>
}

const processCurrentStep = (pet, petProfessional, procedure): CurrentStepResult => {
  let currentStep = SetupStep.Shipping
  let validatedSteps = [SetupStep.Shipping]

  if (pet.primaryBreed) {
    currentStep = SetupStep.PetProfessionalInfo
    validatedSteps = [...validatedSteps, SetupStep.PetInfo]
  }

  if (petProfessional) {
    currentStep = SetupStep.Procedure
    validatedSteps = [...validatedSteps, SetupStep.PetProfessionalInfo]
  }

  if (procedure) {
    currentStep = SetupStep.Success
    validatedSteps = [...validatedSteps, SetupStep.Procedure]
  }

  return { currentStep, validatedSteps }
}

export const AUTH_TOKEN_KEY = 'gallant-pet-user-auth-token'

export const getAuthTokenReducer = {
  [getAuthTokenAction.STARTED]: (state) => ({
    ...state,
    loadingSetup: true,
    error: undefined,
  }),
  [getAuthTokenAction.SUCCEEDED]: (state, { payload }) => {
    const { authToken, user, address, order, pet, procedure, petProfessional, practice } = payload

    window.localStorage.setItem(AUTH_TOKEN_KEY, authToken && authToken.token)

    return {
      ...state,
      authToken: authToken.token,
      error: undefined,
      ...processCurrentStep(pet, petProfessional, procedure),
      owner: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        address,
        ids: {
          userId: user.id,
          petId: pet.id,
          orderId: order && order.id,
          petProfessionalId: petProfessional && petProfessional.id,
          procedureId: procedure && procedure.id,
          practiceId: practice && practice.id,
        },
        pet,
        petProfessional: {
          ...petProfessional,
          practice: practice || {},
          address: (petProfessional && petProfessional.address) || {},
        },
        procedure: procedure || {},
      },
    }
  },
  [getAuthTokenAction.FAILED]: (state, { payload }) => {
    window.localStorage.setItem(AUTH_TOKEN_KEY, '')

    return {
      ...state,
      error: payload.error,
    }
  },
  [getAuthTokenAction.ENDED]: (state) => ({
    ...state,
    loadingSetup: false,
  }),
} as Reducer
