import * as Yup from 'yup'

import { phoneRegExp, zipCodeRegExp } from '../../../checkout/schemas/regex-validators'
const requiredMessage = 'Can’t be blank.'

export const procedureSchema = Yup.object().shape({
  practiceName: Yup.string().required(requiredMessage),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Must be a valid US phone number.'),
  address: Yup.string().required(requiredMessage),
  city: Yup.string().required(requiredMessage),
  state: Yup.string().required(requiredMessage),
  zip: Yup.string()
    .required(requiredMessage)
    .matches(zipCodeRegExp, 'Invalid zip code.'),
})
