import axios from 'axios'

export const createProcedure = async ({ procedure }) => {
  const { data } = await axios({
    method: 'post',
    url: `/procedures`,
    data: { procedure },
  })

  return data
}
