import axios from 'axios'
import { Setup } from '../../redux/checkout/initial-state'

export const createAuthToken = async (setupData: Setup) => {
  const { data } = await axios({
    method: 'post',
    url: '/auth-tokens',
    data: setupData,
  })

  return data
}
