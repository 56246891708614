import axios from 'axios'

export const trackEvent = async (
  analytics: any,
  name: string,
  payload: object,
  options: object,
) => {
  await analytics.track(name, payload, options)

  const anonymousId = analytics.user().anonymousId()

  return await axios({
    method: 'post',
    url: '/segment/track-event',
    data: {
      trackingMessage: {
        event: name,
        anonymousId,
        properties: payload,
      },
    },
  })
}
