import { createAction } from 'redux-actions'

import { actions } from './actions'
import { initialState, Reducer } from './initial-state'

export const resetFormAction = createAction(actions.resetForm)

export const resetFormReducer = {
  [actions.resetForm]: (state) => {
    return {
      ...state,
      ...initialState,
    }
  },
} as Reducer
