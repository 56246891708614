import axios from 'axios'

export const identify = async (analytics: any, name: number, data: object, options: object) => {
  await analytics.identify(name, data, options)

  const anonymousId = analytics.user().anonymousId()

  await axios({
    method: 'post',
    url: '/segment/identify',
    data: {
      identifier: name,
      anonymousId,
      data,
    },
  })
}
