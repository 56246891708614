import { createActionThunk } from 'redux-thunk-actions'

import { updatePet } from '../../api/pets/update-pet'
import { updatePetPicture } from '../../api/pets/update-pet-picture'
import { actions } from './actions'
import { Reducer } from './initial-state'

export const updatePetAction = createActionThunk(actions.updatePet, (pet) =>
  updatePet({ petId: pet.id, pet }),
)

export const updatePetPictureAction = createActionThunk(actions.updatePetPicture, (petId, file) =>
  updatePetPicture({ petId, file }),
)

export const updatePetReducer = {
  [updatePetAction.STARTED]: (state) => ({
    ...state,
    updatingPet: true,
  }),
  [updatePetPictureAction.STARTED]: (state) => ({
    ...state,
    updatingPet: true,
  }),
  [updatePetAction.SUCCEEDED]: (state, { payload }) => {
    const pets = state.pets.map((pet) => {
      if (pet.id === payload.pet.id) {
        return { ...pet, ...payload.pet }
      }

      return pet
    })

    return { ...state, pets }
  },
  [updatePetPictureAction.SUCCEEDED]: (state, { payload }) => {
    const pets = state.pets.map((pet) => {
      if (pet.id === payload.pet.id) {
        return { ...pet, ...payload.pet }
      }

      return pet
    })

    return { ...state, pets }
  },
  [updatePetAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [updatePetPictureAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [updatePetAction.ENDED]: (state) => ({
    ...state,
    updatingPet: false,
  }),
  [updatePetPictureAction.ENDED]: (state) => ({
    ...state,
    updatingPet: false,
  }),
} as Reducer
