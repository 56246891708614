import uuid from 'uuid/v4'

declare const affirm

const AffirmPaymentService = {
  checkout(checkout: any, total: number): void {
    const {
      owner,
      subscriptionCheckoutDetails: { sku: order },
    } = checkout

    const totalPrice = total * 100

    const ownerInfo = {
      name: {
        first: owner.firstName,
        last: owner.lastName,
      },
      address: {
        line1: owner.address.address,
        line2: owner.address.address,
        city: owner.address.city,
        state: owner.address.state,
        zipcode: owner.address.zip,
        country: 'USA',
      },
      phone_number: owner.phoneNumber,
      email: owner.email,
    }

    const orderInfo = {
      merchant: {
        user_confirmation_url: window.location.href,
        user_cancel_url: window.location.href,
        user_confirmation_url_action: 'GET',
        name: 'Gallant',
      },
      shipping: ownerInfo,
      billing: ownerInfo,
      items: [
        {
          display_name: order.attributes.name,
          sku: order.id,
          unit_price: totalPrice,
          qty: 1,
        },
      ],
      metadata: {
        product: order.product,
      },
      order_id: uuid(),
      shipping_amount: 0,
      tax_amount: 0,
      total: totalPrice,
    }

    affirm.checkout(orderInfo)
    affirm.checkout.open()
  },
}

export { AffirmPaymentService }
