export interface Order {
  id: string
  plan: string
}

export interface Address {
  id: string
  address: string
  secondAddress: string
  city: string
  state: string
  zip: string
}

export interface Practice {
  id: string
  name: string
  phoneNumber: string
  address: Address
}

export interface Procedure {
  id: string
  procedureDate?: string
  practice?: Practice
}

export interface Pet {
  id: string
  name?: string
  years?: number
  months?: number
  weight?: number
  primaryBreed?: string
  secondaryBreeds?: string[]
  gender?: string
  procedure?: Procedure
  practice?: Practice
  order?: Order
  petProfessionalId?: number
  picture?: string
  picturePermission?: boolean
}

export const initialState = {
  creatingAuthToken: false,
  authTokenCreated: false,
  loadingProfile: false,
  error: undefined,
  profile: undefined,
  pets: [],
  breeds: [],
}

export interface Reducer {
  [key: string]: (state: typeof initialState, action: any) => typeof initialState
}
