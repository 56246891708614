import { createAction } from 'redux-actions'

import { actions } from './actions'
import { Reducer } from './initial-state'

export const changeProductAction = createAction(actions.changeProduct)

export const changeProductReducer = {
  [actions.changeProduct]: (state, { payload: product }) => {
    return {
      ...state,
      product,
    }
  },
} as Reducer
