import { Button, FormControlLabel, Radio } from '@material-ui/core'
import { Formik } from 'formik'
import * as React from 'react'
import { SpeciesRef } from '../../../model/SpeciesRef'
import { petInfoSchema } from '../../checkout/schemas/pet-schema'
import { Input } from '../../shared/forms/Input'
import { AutocompleteMultiple } from '../../shared/forms/autocomplete/AutocompleteMultiple'
import { AutocompleteSingle } from '../../shared/forms/autocomplete/AutocompleteSingle'

interface Breed {
  id: string
  name: string
  speciesId: number
}

interface PetFormProps {
  pet: {
    years: number
    months: number
    species: string
    gender: string
    primaryBreed: string
    secondaryBreeds: string[]
    speciesId?: number
  }
  breeds: Breed[]
  handleSubmit: (values: object) => void
  checkEmail?: (email: string) => void
  onCancel?: () => void
}

const getBreedId = (breeds: Breed[], breedName: string) => {
  const breed = breeds.find(
    (breed: Breed) => breed.name.toLowerCase() === breedName.toLowerCase(),
  ) as Breed

  return breed.id
}

const getSecondaryBreedIds = (breeds, secondaryBreeds: string[]) => {
  return (
    secondaryBreeds &&
    secondaryBreeds
      .map((secondaryBreedName: string) => getBreedId(breeds, secondaryBreedName))
      .join(', ')
  )
}

const PetForm: React.SFC<PetFormProps> = ({ pet, breeds, onCancel, handleSubmit }) => {
  const handlePetSubmit = (pet) =>
    handleSubmit({
      ...pet,
      primaryBreed: getBreedId(breeds, pet.primaryBreed),
      secondaryBreeds: getSecondaryBreedIds(breeds, pet.secondaryBreeds),
      years: pet.years || 0,
      months: pet.months || 0,
    })

  const initialValues = {
    ...pet,
    years: pet.years != null && pet.years >= 0 ? pet.years : undefined,
    months: pet.months != null && pet.months >= 0 ? pet.months : undefined,
    species: SpeciesRef.toArray().find((ref) => ref.id === pet.speciesId).optionValue,
    gender: pet.gender,
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={petInfoSchema}
      onSubmit={handlePetSubmit}
    >
      {(formProps) => {
        const { errors, values, touched, setFieldValue, handleSubmit } = formProps
        const primaryBreed = values.primaryBreed
        const secondaryBreeds = values.secondaryBreeds || pet.secondaryBreeds || []
        let breedSugestions

        if (values.species === 'horse') {
          breedSugestions = breeds.filter((b) => b.speciesId === SpeciesRef.EQUINE.id)
        } else {
          breedSugestions = breeds.filter((b) => b.speciesId === SpeciesRef.CANINE.id)
        }

        return (
          <form className="pet-form" onSubmit={handleSubmit} noValidate>
            <section className="form-fields">
              <div className="radio-input">
                <p>gender</p>
                <div className="radio-group">
                  <FormControlLabel
                    value="female"
                    control={
                      <Radio
                        color="primary"
                        checked={values.gender === 'female'}
                        onChange={(e) => setFieldValue('gender', e.target.value)}
                      />
                    }
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={
                      <Radio
                        color="primary"
                        checked={values.gender === 'male'}
                        onChange={(e) => setFieldValue('gender', e.target.value)}
                      />
                    }
                    label="Male"
                  />
                </div>
              </div>
              {errors.gender && touched.gender && (
                <p className="error">A selection must be made.</p>
              )}
              <div className="same-line">
                <div className="same-column right-margin">
                  <Input
                    form={formProps}
                    name="years"
                    type="number"
                    label="Age (years)"
                    fullWidth
                    autoComplete="off"
                  />
                </div>
                <div className="same-column">
                  <Input
                    form={formProps}
                    name="months"
                    type="number"
                    label="Age (months)"
                    fullWidth
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="radio-input">
                <p>Species</p>
                <div className="radio-group">
                  <FormControlLabel
                    value="dog"
                    control={
                      <Radio
                        color="primary"
                        checked={values.species === 'dog'}
                        onChange={(e) => {
                          setFieldValue('primaryBreed', null)
                          setFieldValue('species', e.target.value)
                        }}
                      />
                    }
                    label="Dog"
                  />
                  <FormControlLabel
                    value="cat"
                    control={
                      <Radio
                        color="primary"
                        checked={values.species === 'cat'}
                        onChange={(e) => {
                          setFieldValue('primaryBreed', 'Cat')
                          setFieldValue('secondaryBreeds', undefined)
                          setFieldValue('species', 'cat')
                        }}
                      />
                    }
                    label="Cat"
                  />
                  <FormControlLabel
                    value="horse"
                    control={
                      <Radio
                        color="primary"
                        checked={values.species === 'horse'}
                        onChange={(e) => {
                          setFieldValue('primaryBreed', null)
                          setFieldValue('species', e.target.value)
                        }}
                      />
                    }
                    label="Horse"
                  />
                </div>
              </div>
              {errors.species && touched.species && (
                <p className="error">A selection must be made.</p>
              )}
              {values.species !== 'cat' && (
                <>
                  <AutocompleteSingle
                    label="Main Breed"
                    subLabel="Choose a single, main breed. Can also be “Mixed”"
                    placeholder="Type to find main breed..."
                    fullWidth
                    suggestions={breedSugestions}
                    disabled={false}
                    selectedItem={primaryBreed}
                    handleSelectionChange={(value) => setFieldValue('primaryBreed', value)}
                  />
                  {errors.primaryBreed && touched.primaryBreed && (
                    <p className="error">Can’t be blank.</p>
                  )}
                  <AutocompleteMultiple
                    label="Secondary breed(s)"
                    subLabel="Optional. Add as many that apply."
                    placeholder="Optional Secondary Breed(s)"
                    fullWidth
                    suggestions={breeds}
                    disabled={false}
                    handleSelectionChange={(value) => setFieldValue('secondaryBreeds', value)}
                    selectedItems={secondaryBreeds}
                  />
                </>
              )}
            </section>
            <div className="same-line actions">
              <Button type="button" className="cancel" onClick={() => onCancel && onCancel()}>
                Cancel
              </Button>
              <Button type="submit" className="save">
                <span className="icon save white" />
                Save
              </Button>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

export { PetForm }
