import { handleActions } from 'redux-actions'

import { getBreedsReducer } from '../checkout/get-breeds'
import { createAuthTokenReducer } from './create-auth-token'
import { createPetProfessionalReducer } from './create-pet-professional'
import { createProcedureReducer } from './create-procedure'
import { getProfileReducer } from './get-profile'
import { initialState } from './initial-state'
import { updatePetReducer } from './update-pet'
import { updatePetProfessionalReducer } from './update-pet-professional'
import { updateProfileReducer } from './update-profile'

export const combinedReducers = {
  ...createAuthTokenReducer,
  ...getProfileReducer,
  ...updateProfileReducer,
  ...getBreedsReducer,
  ...updatePetReducer,
  ...createPetProfessionalReducer,
  ...updatePetProfessionalReducer,
  ...createProcedureReducer,
}

export const user = handleActions(combinedReducers, initialState)
