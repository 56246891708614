import { Button } from '@material-ui/core'
import * as React from 'react'
import { initialState as checkoutInitialState } from '../../../../../redux/checkout/initial-state'
import { CouponStatus } from './CouponStatus'

interface eventFunc {
  (event: any): void
}

interface voidFunc {
  (): void
}

interface boolFunc {
  (): boolean
}

interface CouponProps {
  checkout: typeof checkoutInitialState
  couponValue: string
  couponDisabled: boolean
  invalidCoupon: boolean
  isCouponEligible: boolFunc
  handleCouponChange: eventFunc
  applyCoupon: voidFunc
}

const Coupon: React.FunctionComponent<CouponProps> = ({
  checkout,
  couponValue,
  couponDisabled,
  applyCoupon,
  invalidCoupon,
  isCouponEligible,
  handleCouponChange,
}) => (
  <>
    <div className="coupon-container">
      <div className="credit-card-field">
        <label>Promo Code</label>
        <input
          className="StripeElement"
          type="text"
          name="couponCode"
          onChange={handleCouponChange}
          value={couponValue}
        />
      </div>

      {!couponDisabled && <Button onClick={applyCoupon}>Apply</Button>}
    </div>
    <CouponStatus checkout={checkout} invalidCoupon={invalidCoupon} isCouponEligible={isCouponEligible} />
  </>
)

export { Coupon }
