import '../checkout.scss'

import { Formik } from 'formik'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button } from '@material-ui/core'

import { identify, trackEvent, SegmentEvents } from '../../../api/segment'
import { changeStepAction } from '../../../redux/checkout/change-step'
import { createProcedureAction } from '../../../redux/checkout/create-procedure'
import { SetupStep } from '../../../redux/checkout/initial-state'
import { initialState as checkoutInitialState } from '../../../redux/checkout/initial-state'
import { updateOwnerAction } from '../../../redux/checkout/update-owner'
import { updateProcedureAction } from '../../../redux/checkout/update-procedure'
import { Store } from '../../../redux/configure-store'
import { DatePicker } from '../../shared/forms/DatePicker'
import { procedureSchema } from '../schemas/procedure-schema'
import { formatDate } from './utils'

const mapStateToProps = (state: Store) => ({
  checkout: state.checkout,
})

const mapDispatchToProps = (dispatch) => ({
  updateOwner: bindActionCreators(updateOwnerAction, dispatch),
  changeStep: bindActionCreators(changeStepAction, dispatch),
  createProcedure: bindActionCreators(createProcedureAction, dispatch),
  updateProcedure: bindActionCreators(updateProcedureAction, dispatch),
})

type Dispatchers = ReturnType<typeof mapDispatchToProps>
type Props = {
  checkout: typeof checkoutInitialState
  nextStep: SetupStep
} & Dispatchers

export interface ProcedureValues {
  procedureDate: Date
}

export class ProcedureFormComponent extends React.Component<Props, any> {
  state = {
    selectedDate: undefined,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleVetInfoSubmit = (values: ProcedureValues) => {
    const { checkout, nextStep } = this.props

    const procedure = {
      procedureDate: values.procedureDate && formatDate(values.procedureDate),
    }

    this.props.updateOwner({ info: { procedure } })
    this.props.changeStep(nextStep)

    if (!procedure.procedureDate) {
      return
    }

    const { petId, petProfessionalId, procedureId, practiceId, userId } = checkout.owner.ids

    this.trackProcedureScheduledEvent(userId, procedure)

    if (procedureId) {
      return this.props.updateProcedure({ procedureId, procedure })
    }

    this.props.createProcedure({ petId, petProfessionalId, practiceId, ...procedure })
  }

  trackProcedureScheduledEvent = (userId, procedure) => {
    const payload = { procedure_date: new Date(procedure.procedureDate).toISOString() }

    trackEvent(SegmentEvents.ProcedureScheduled, payload)

    identify(userId.toString(), payload)
  }

  getInitialValues = (): ProcedureValues => {
    const {
      checkout: { owner },
    } = this.props

    return {
      procedureDate: owner.procedure.procedureDate,
    }
  }

  renderForm = ({ formProps, validateForm }) => {
    const { errors, touched } = formProps

    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        <section className="form-fields">
          <DatePicker
            name="procedureDate"
            form={formProps}
            onChange={(date) => formProps.setFieldValue('procedureDate', date)}
            label="Procedure Date"
            subLabel="If you haven’t scheduled yet, you can leave this blank."
          />
          {errors.procedureDate && touched.procedureDate && (
            <p className="error">{errors.procedureDate}</p>
          )}
        </section>
        <Button onClick={validateForm} type="submit">
          FINISH
        </Button>
      </form>
    )
  }

  render() {
    const initialValues = this.getInitialValues()

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={procedureSchema}
        onSubmit={(values, formActions) => {
          this.handleVetInfoSubmit(values)
          this.setState({ formActions })
        }}
      >
        {(formProps, validateForm) => this.renderForm({ formProps, validateForm })}
      </Formik>
    )
  }
}

const ProcedureForm = connect(mapStateToProps, mapDispatchToProps)(ProcedureFormComponent)

export { ProcedureForm }
