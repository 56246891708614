import * as React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectStripe } from 'react-stripe-elements'
import { bindActionCreators } from 'redux'
import { createAuthToken } from '../../../../api/auth-tokens/create-auth-token'
import { trackingLog } from '../../../../api/log/tracking-log'
import { identify, trackEvent, SegmentEvents } from '../../../../api/segment'
import { stripeProducts } from '../../../../api/segment/products'
import { checkoutAction } from '../../../../redux/checkout/checkout'
import { createOwnerAction } from '../../../../redux/checkout/create-owner'
import { getCouponAction } from '../../../../redux/checkout/get-coupon'
import {
  initialState as checkoutInitialState,
  CheckoutStep,
} from '../../../../redux/checkout/initial-state'
import { calculateTaxAction } from '../../../../redux/checkout/tax-calculator'
import { Store } from '../../../../redux/configure-store'
import {
  calculateCheckout,
  isCouponEligible,
} from '../../../../redux/selectors/checkout-calculator-selector'
import { showToastMessage, MessageType } from '../../../shared/toast/toast'
import { Plan } from '../../Plan'
import { Terms } from '../../Terms'
import '../../checkout.scss'
import { AffirmPaymentService } from '../../services/affirm-payment'
import { CheckoutButton } from './CheckoutButton'
import { FormFields } from './FormFields'
import { Loading } from './Loading'
import { PaymentProvider } from './PaymentProvider'

const mapStateToProps = (state: Store) => {
  return {
    checkout: state.checkout,
    checkoutCalculation: calculateCheckout(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitCheckout: bindActionCreators(checkoutAction, dispatch),
    createOwner: bindActionCreators(createOwnerAction, dispatch),
    calculateTaxes: bindActionCreators(calculateTaxAction, dispatch),
    getCoupon: bindActionCreators(getCouponAction, dispatch),
  }
}

interface PaymentProps {
  stripe?: any
  checkout: typeof checkoutInitialState
  checkoutCalculation: any
  history: any
  location: any
  match: any
}

type Dispatchers = ReturnType<typeof mapDispatchToProps>

type Props = PaymentProps & Dispatchers

class PaymentFormComponent extends React.Component<Props, any> {
  captchaRef = React.createRef<ReCAPTCHA>()

  state = {
    disableActions: false,
    selectedPaymentProvider: PaymentProvider.Stripe,
    availableMobilePaymentProvider: undefined,
    couponCode: '',
    dirtyCoupon: false,
    invalidCoupon: false,
    canMakePayment: false,
    paymentRequest: undefined,
    validCaptcha: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.hasPaymentConfirmation()
  }

  componentDidUpdate(prevProps: Props) {
    const { selectedPaymentProvider } = this.state
    const { checkout, checkoutCalculation } = this.props

    if (
      prevProps.checkout.product !== checkout.product &&
      selectedPaymentProvider === PaymentProvider.Affirm
    ) {
      this.setState({ selectedPaymentProvider: PaymentProvider.Stripe })
    }

    if (prevProps.checkoutCalculation.totalWithTaxes !== checkoutCalculation.totalWithTaxes) {
      this.upsertStripePaymentButtonRequest()
    }

    if (checkout.error && this.state.disableActions) {
      this.setState({ disableActions: false })
      this.showErrorMessage({ message: checkout.error.message })
    }

    if (prevProps.checkout.error || !checkout.error || checkout.error !== 'invalid-coupon-code') {
      return
    }

    this.setState({ invalidCoupon: true })

    trackEvent(SegmentEvents.CouponDenied, {
      coupon_name: this.state.couponCode,
      reason: checkout.error,
      email: checkout.owner.email,
    })
  }

  upsertStripePaymentButtonRequest() {
    const { paymentRequest } = this.state
    const { stripe, checkout, checkoutCalculation } = this.props

    const total = {
      label: `Gallant ${checkout.product} plan`,
      amount: checkoutCalculation.totalWithTaxes * 100,
    }

    if (paymentRequest) {
      return paymentRequest.update({ total })
    }

    const newPaymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total,
    })

    newPaymentRequest.on('token', ({ complete, token: paymentInfo }) => {
      this.payWithStripe(paymentInfo)
      complete('success')
    })

    newPaymentRequest
      .canMakePayment()
      .then((result) => {
        if (!result) return

        /* Only for e2e tests. */
        if (!!new URLSearchParams(document.location.search).get('disable-google-pay')) return

        this.setState({
          paymentRequest: newPaymentRequest,
          canMakePayment: true,
          availableMobilePaymentProvider: result.applePay
            ? PaymentProvider.ApplePay
            : PaymentProvider.GooglePay,
        })
      })
      .catch((e) => {
        // tslint:disable-next-line:no-console
        console.error('newPaymentRequest error', e)
      })
  }

  async hasPaymentConfirmation() {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('checkout_token')

    if (!token) return

    const paymentInfo = { token }
    const { checkoutCalculation } = this.props
    const amount = checkoutCalculation.totalWithTaxes
    const discount = checkoutCalculation.discount
    const subtotal = checkoutCalculation.subtotal

    this.purchase(paymentInfo, { amount, discount, subtotal, provider: PaymentProvider.Affirm })
  }

  handleSubmit = async (event) => {
    this.setState({ disableActions: true })

    event.preventDefault()

    await this.props.createOwner({ ownerData: this.props.checkout.owner })

    this.state.selectedPaymentProvider === PaymentProvider.Stripe
      ? this.payWithStripe()
      : this.payWithAffirm()
  }

  payWithStripe = async (paymentInfo?) => {
    const { stripe, checkout, checkoutCalculation } = this.props
    const amount = checkoutCalculation.totalWithTaxes
    const discount = checkoutCalculation.discount
    const subtotal = checkoutCalculation.subtotal

    if (!paymentInfo) {
      const { token: createdToken, error: stripeFormError } = await stripe.createToken({
        email: checkout.owner.email,
      })

      if (stripeFormError) {
        return this.showErrorMessage(stripeFormError)
      }

      paymentInfo = createdToken
    }

    this.purchase(paymentInfo, { amount, subtotal, discount, provider: PaymentProvider.Stripe })
  }

  payWithAffirm = () => {
    const { checkout, checkoutCalculation } = this.props
    const total = checkoutCalculation.totalWithTaxes

    AffirmPaymentService.checkout(checkout, total)
  }

  getBreedId = (breedName: string) => {
    const breed = this.props.checkout.breeds.find(
      (breed: { name: string; id: string }) => breed.name.toLowerCase() === breedName.toLowerCase(),
    )

    return breed.id
  }

  getSecondaryBreedIds = () => {
    const secondaryBreeds = this.props.checkout.owner.pet.secondaryBreeds

    return (
      secondaryBreeds &&
      secondaryBreeds
        .map((secondaryBreedName: string) => this.getBreedId(secondaryBreedName))
        .join(', ')
    )
  }

  getCurrentCouponCode = () => {
    const { couponCode } = this.state
    const { checkout } = this.props

    return couponCode || (checkout.coupon && checkout.coupon.couponCode)
  }

  purchase = async (paymentInfo, { amount, discount, subtotal, provider }) => {
    const { submitCheckout, checkout } = this.props
    const couponCode = this.isCouponEligible() ? checkout.coupon.couponCode : undefined

    const checkoutData = {
      paymentInfo,
      petOwner: checkout.owner,
      product: checkout.product,
      couponCode,
      amount,
      discount,
      subtotal,
      provider,
      captcha: window.localStorage.getItem('last-captcha'),
    }

    window.scrollTo(0, 0)

    await submitCheckout(checkoutData)

    await this.trackOrderCompletedEvent()
  }

  async trackOrderCompletedEvent() {
    const { checkout, checkoutCalculation } = this.props

    if (checkout.error) return

    const chosenProduct = stripeProducts[checkout.product]
    const { credentials, order, method } = checkout.paymentResult
    const { ids, email } = checkout.owner
    const methodCustomerId = `${method}_customer_id`

    const { authToken } = await createAuthToken({
      email,
      target: 'create-only',
    })

    const event = {
      order_id: order.id,
      tax: checkoutCalculation.tax,
      discount: checkoutCalculation.discount,
      coupon: checkout.coupon && checkout.coupon.couponCode,
      total: checkout.amount,
      currency: 'USD',
      products: [
        {
          ...chosenProduct,
          price: checkoutCalculation.subtotal,
        },
      ],
      payment_method: method,
      email,
      token: authToken.token,
    }

    //local copy of the event sent to segment
    await trackingLog({
      orderId: ids.orderId,
      email,
      event: JSON.stringify(event),
    })

    identify(ids.userId, {
      email,
      [methodCustomerId]: credentials.customerId,
    })

    trackEvent(SegmentEvents.CheckoutStepCompleted, {
      step: CheckoutStep.Checkout,
      email,
    })

    trackEvent(SegmentEvents.OrderCompleted, event)
  }

  showErrorMessage = (error: { message: string }) => {
    showToastMessage(error.message, MessageType.ERROR)
    this.setState({ disableActions: false })
  }

  getCurrentPlan = (interval: string) =>
    this.props.checkout.availablePlans.find((availablePlan) => availablePlan.interval === interval)

  handleCouponChange = (event) => {
    const { value } = event.target
    this.setState({ couponCode: value, invalidCoupon: false, dirtyCoupon: true })
  }

  isCouponEligible = () => {
    const { checkout } = this.props

    return isCouponEligible(checkout.coupon, checkout.product)
  }

  applyCoupon = async () => {
    const { checkout } = this.props
    const { couponCode } = this.state
    const currentCouponCode = this.getCurrentCouponCode()

    trackEvent(SegmentEvents.CouponEntered, {
      coupon_code: couponCode,
      email: checkout.owner.email,
    })

    await this.props.getCoupon(currentCouponCode)

    if (!this.isCouponEligible()) {
      return
    }

    const { checkoutCalculation } = this.props

    await trackEvent(SegmentEvents.CouponApplied, {
      coupon_name: currentCouponCode,
      discount: checkoutCalculation.discount,
      email: checkout.owner.email,
    })

    const chosenProduct = stripeProducts[checkout.product]

    trackEvent(SegmentEvents.OrderUpdated, {
      email: checkout.owner.email,
      total: checkoutCalculation.totalWithTaxes,
      tax: checkoutCalculation.tax,
      coupon_name: currentCouponCode,
      discount: checkoutCalculation.discount,
      currency: 'USD',
      products: [
        {
          ...chosenProduct,
          price: checkoutCalculation.subtotal,
        },
      ],
    })
  }

  handlePaymentProviderChange = (selectedPaymentProvider: PaymentProvider) => {
    this.setState({ selectedPaymentProvider })
  }

  onCaptcha = (captcha: string) => {
    captcha &&
      setTimeout(() => {
        window.localStorage.setItem('last-captcha', captcha)
        this.setState({ validCaptcha: true })
      }, 1000)
  }

  // tslint:disable-next-line: cyclomatic-complexity
  render() {
    const { checkout, checkoutCalculation } = this.props
    const {
      invalidCoupon,
      canMakePayment,
      availableMobilePaymentProvider,
      selectedPaymentProvider,
      dirtyCoupon,
      couponCode,
      paymentRequest,
      disableActions,
      validCaptcha,
    } = this.state

    return validCaptcha ? (
      <form className="purchase-form">
        {checkout.checkingOut ? (
          <Loading visible />
        ) : (
          <>
            <FormFields
              selectedPaymentProvider={selectedPaymentProvider}
              availableMobilePaymentProvider={availableMobilePaymentProvider}
              canMakePayment={canMakePayment}
              invalidCoupon={invalidCoupon}
              isCouponEligible={this.isCouponEligible}
              checkout={checkout}
              couponValue={
                !dirtyCoupon ? checkout.coupon && checkout.coupon.couponCode : couponCode
              }
              couponDisabled={
                checkout.loadingCoupon ||
                (!couponCode.length && !checkout.coupon) ||
                (checkout.coupon && !checkout.coupon.couponCode) ||
                (dirtyCoupon && !couponCode.length)
              }
              handleCouponChange={this.handleCouponChange}
              handlePaymentProviderChange={this.handlePaymentProviderChange}
              applyCoupon={this.applyCoupon}
            />
            <Plan
              petName={checkout.owner.pet.name}
              plan={checkout.product}
              total={checkoutCalculation.totalWithTaxes}
            />
            <Terms product={checkout.product} className="terms-desktop" />
            <CheckoutButton
              paymentRequest={paymentRequest}
              selectedPaymentProvider={selectedPaymentProvider}
              checkout={checkout}
              handleCheckout={this.handleSubmit}
              isCheckoutDisabled={
                disableActions || checkout.calculatingTaxes || checkout.availablePlans.length === 0
              }
            />
          </>
        )}
      </form>
    ) : (
      <div
        style={{
          width: '100%',
          display: 'inline-flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <h4>Complete the Captcha to proceed to checkout.</h4>
        <ReCAPTCHA
          onChange={this.onCaptcha}
          sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
          ref={this.captchaRef}
        />
      </div>
    )
  }
}

const PaymentForm = injectStripe(
  connect(mapStateToProps, mapDispatchToProps)(withRouter<Props>(PaymentFormComponent)),
)

export { PaymentForm, PaymentProvider }
