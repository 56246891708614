import axios from 'axios'

export const checkEmail = async (email: string) => {
  const { data } = await axios({
    method: 'get',
    url: '/checkout/check-existing-email',
    params: { email },
  })

  return data
}
