import { createStyles } from '@material-ui/core/styles'

export const styles = () =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 250,
    },
    container: {
      marginTop: '16px',
      flexGrow: 1,
      position: 'relative',
    },
    paper: {
      zIndex: 1,
      marginTop: 10,
      left: 0,
      right: 0,
      textTransform: 'capitalize',
    },
    chip: {
      marginBottom: '6px',
      marginRight: '6px',
      borderRadius: '2px',
      textTransform: 'capitalize',
    },
    inputRoot: {
      flexWrap: 'wrap',
      borderRadius: '0%',
    },
    inputInput: {
      padding: '16px',
      textTransform: 'capitalize',
    },
    inputMultiline: {
      padding: '16px',
    },
  })
