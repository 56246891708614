import './summary.scss'

import cx from 'classnames'
import lodash from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { MenuItem, Select } from '@material-ui/core'

import { trackEvent, SegmentEvents } from '../../api/segment'
import { stripeProducts } from '../../api/segment/products'
import { changeProductAction } from '../../redux/checkout/change-product'
import { getCouponAction } from '../../redux/checkout/get-coupon'
import { getSubscriptionCheckoutDetailsAction } from '../../redux/checkout/get-subscription-details'
import {
  initialState as checkoutInitialState,
  ValidProducts,
} from '../../redux/checkout/initial-state'
import { Store } from '../../redux/configure-store'
import { calculateCheckout } from '../../redux/selectors/checkout-calculator-selector'
import { formatMoney } from '../../utils/formatter'
import { routePaths } from '../route-paths'
import { Terms } from './Terms'

const mapStateToProps = (state: Store) => {
  return {
    checkout: state.checkout,
    checkoutCalculation: calculateCheckout(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCoupon: bindActionCreators(getCouponAction, dispatch),
    changeProduct: bindActionCreators(changeProductAction, dispatch),
    getSubscriptionCheckoutDetails: bindActionCreators(
      getSubscriptionCheckoutDetailsAction,
      dispatch,
    ),
  }
}

type Dispatchers = ReturnType<typeof mapDispatchToProps>
type Props = {
  checkout: typeof checkoutInitialState
  checkoutCalculation: any
  history: any
  location: any
  match: any
  className?: string
} & Dispatchers

export class SummaryComponent extends React.Component<Props> {
  state = {
    hasSummaryChanged: false,
  }

  componentDidUpdate(prevProps: Props) {
    const { hasSummaryChanged } = this.state
    const { checkout } = this.props
    const hasPlanChanged = hasSummaryChanged && prevProps.checkout.product !== checkout.product

    if (hasPlanChanged) {
      this.setState({ hasSummaryChanged: false })
    }
  }

  trackOrderUpdated = () => {
    const { checkout, checkoutCalculation } = this.props
    const chosenProduct = stripeProducts[checkout.product]

    trackEvent(SegmentEvents.OrderUpdated, {
      email: checkout.owner.email,
      total: checkoutCalculation.totalWithTaxes,
      tax: checkoutCalculation.tax,
      coupon_name: checkout.coupon && checkout.coupon.couponCode,
      discount: checkoutCalculation.discount,
      currency: 'USD',
      products: [
        {
          ...chosenProduct,
          price: checkoutCalculation.subtotal,
        },
      ],
    })
  }

  handleChangePlan = async (event) => {
    const product = event.target.value
    this.props.history.push(routePaths.plans.replace(':product', lodash.kebabCase(product)))

    this.setState({ hasSummaryChanged: true })

    await this.props.getSubscriptionCheckoutDetails(product)
    await this.props.changeProduct(product)

    this.trackOrderUpdated()
  }

  isLoadingSummary = () => {
    const { checkout } = this.props

    return (
      checkout.loadingCheckoutDetails ||
      checkout.calculatingTaxes ||
      checkout.loadingPlans ||
      checkout.availablePlans.length === 0
    )
  }


  renderSummary = () => {
    const { checkout, checkoutCalculation, className } = this.props
    const { subscriptionType, totalWithTaxes, discount } = checkoutCalculation
    const loading = this.isLoadingSummary()

    if (
      subscriptionType === ValidProducts.adiposeAnnual ||
      subscriptionType === ValidProducts.adiposeLifetime
    ) {
      return (
        <aside className={cx('summary', { [className]: className })}>
          <h2 className="summary-title">Order Summary</h2>
          <ul>
            <li>
              <p>Plan</p>
              <Select
                disabled={checkout.checkingOut}
                disableUnderline
                value={subscriptionType}
                onChange={this.handleChangePlan}
                displayEmpty
                name="subscription-type"
                className="subscription-selector"
              >
                <MenuItem value={ValidProducts.adiposeLifetime}>Lifetime</MenuItem>
                <MenuItem value={ValidProducts.adiposeAnnual}>Annual</MenuItem>
              </Select>
            </li>
            <li>
              <p>Courier & Shipping</p>
              <p>$100.00</p>
            </li>
            <li>
              <p>Tissue Processing & Banked doses</p>
              <p>$1785.00</p>
            </li>
            {checkout.product === ValidProducts.adiposeLifetime && (
              <li>
                <p>Lifetime Banking Fee</p>
                <p>$595.00</p>
              </li>
            )}
            {!!discount && (
              <li>
                <p>Discount</p>
                <p id="discount-value">- ${formatMoney(discount)}</p>
              </li>
            )}
            <li className="highlight">
              <p>Total</p>
              <p data-test-id="summary-total">
                {loading ? <b>Calculating...</b> : <b>${formatMoney(totalWithTaxes)}</b>}
              </p>
            </li>
          </ul>
          <Terms product={checkout.product} className="terms" />
        </aside>
      )
    }

    if (
      subscriptionType === ValidProducts.equineAnnual ||
      subscriptionType === ValidProducts.equineLifetime
    ) {
      return (
        <aside className={cx('summary', { [className]: className })}>
          <h2 className="summary-title">Order Summary</h2>
          <ul>
            <li>
              <p>Plan</p>
              <Select
                disabled={checkout.checkingOut}
                disableUnderline
                value={subscriptionType}
                onChange={this.handleChangePlan}
                displayEmpty
                name="subscription-type"
                className="subscription-selector"
              >
                <MenuItem value={ValidProducts.equineLifetime}>Lifetime</MenuItem>
                <MenuItem value={ValidProducts.equineAnnual}>Annual</MenuItem>
              </Select>
            </li>
            <li>
              <p>Courier & Shipping</p>
              <p>$100.00</p>
            </li>
            <li>
              <p>Tissue Processing & Banked doses</p>
              <p>$2050.00</p>
            </li>
            {checkout.product === ValidProducts.equineLifetime && (
              <li>
                <p>Lifetime Banking Fee</p>
                <p>$595.00</p>
              </li>
            )}
            {!!discount && (
              <li>
                <p>Discount</p>
                <p id="discount-value">- ${formatMoney(discount)}</p>
              </li>
            )}
            <li className="highlight">
              <p>Total</p>
              <p data-test-id="summary-total">
                {loading ? <b>Calculating...</b> : <b>${formatMoney(totalWithTaxes)}</b>}
              </p>
            </li>
          </ul>
          <Terms product={checkout.product} className="terms" />
        </aside>
      )
    }

    return (
      <aside className={cx('summary', { [className]: className })}>
        <h2 className="summary-title">Order Summary</h2>
        <ul>
          <li>
            <p>Plan</p>
            <Select
              disabled={checkout.checkingOut}
              disableUnderline
              value={subscriptionType}
              onChange={this.handleChangePlan}
              displayEmpty
              name="subscription-type"
              className="subscription-selector"
            >
              <MenuItem value={ValidProducts.lifetime}>Lifetime</MenuItem>
              <MenuItem value={ValidProducts.annual}>Annual</MenuItem>
            </Select>
          </li>
          {checkout.product === ValidProducts.monthly && (
            <li>
              <p>
                Courier & Shipping <span>(1 of 12)</span>
              </p>
              <p>$10.00</p>
            </li>
          )}
          {checkout.product !== ValidProducts.monthly && (
            <li>
              <p>Courier & Shipping</p>
              <p>$100.00</p>
            </li>
          )}
          {checkout.product === ValidProducts.monthly && (
            <li>
              <p>
                Tissue Processing <span>(1 of 12)</span>
              </p>
              <p>$25.00</p>
            </li>
          )}
          {checkout.product !== ValidProducts.monthly && (
            <li>
              <p>Tissue Processing</p>
              <p>$295.00</p>
            </li>
          )}
          {checkout.product === ValidProducts.lifetime && (
            <li>
              <p>Lifetime Banking Fee</p>
              <p>$595.00</p>
            </li>
          )}
          {checkout.product === ValidProducts.monthly && (
            <li>
              <p>Monthly Banking Fee</p>
              <p>$10.00</p>
            </li>
          )}
          {!!discount && (
            <li>
              <p>Discount</p>
              <p id="discount-value">- ${formatMoney(discount)}</p>
            </li>
          )}
          <li className="highlight">
            <p>Total</p>
            <p data-test-id="summary-total">
              {loading ? <b>Calculating...</b> : <b>${formatMoney(totalWithTaxes)}</b>}
            </p>
          </li>
        </ul>
        <Terms product={checkout.product} className="terms" />
      </aside>
    )
  }

  render() {
    return this.renderSummary()
  }
}

const Summary = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter<Props>(SummaryComponent))

export { Summary }
