import { createActionThunk } from 'redux-thunk-actions'

import { createProcedure } from '../../api/procedures/create-procedure'
import { actions } from './actions'
import { Procedure, Reducer } from './initial-state'

export const createProcedureAction = createActionThunk(actions.createProcedure, (procedure: Procedure) =>
  createProcedure({ procedure }),
)

export const createProcedureReducer = {
  [createProcedureAction.STARTED]: (state) => ({
    ...state,
    creatingProcedure: true,
  }),
  [createProcedureAction.SUCCEEDED]: (state, { payload }) => {
    const pets = state.pets.map((pet) => {
      if (pet.id === +payload.procedure.petId) {
        return {
          ...pet,
          procedure: payload.procedure,
        }
      }

      return pet
    })

    return { ...state, pets }
  },
  [createProcedureAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [createProcedureAction.ENDED]: (state) => ({
    ...state,
    creatingProcedure: false,
  }),
} as Reducer
