import axios from 'axios'

export const getCoupon = async (couponCode: string) => {
  const { data } = await axios({
    method: 'get',
    url: '/checkout/coupon',
    params: { couponCode },
  })

  return data
}
