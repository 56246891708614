export const stripeProducts = {
  lifetime: {
    product_id: 'BANKING_LIFETIME',
    name: 'Lifetime',
  },
  annual: {
    product_id: 'BANKING_ANNUAL',
    name: 'Annual',
  },
}
