import axios from 'axios'

export const updateOwner = async (ownerId, ownerData) => {
  const { data } = await axios({
    method: 'put',
    url: `/owners/${ownerId}`,
    data: { owner: ownerData },
  })

  return data
}
