import { createAction } from 'redux-actions'

import { actions } from './actions'
import { Reducer } from './initial-state'

export const skipStepAction = createAction(actions.skipStep)
export const includeStepAction = createAction(actions.includeStep)

export const skipStepReducer = {
  [actions.skipStep]: (state, { payload }) => {
    const skippingSteps = state.skippingSteps

    if (!skippingSteps.includes(payload)) {
      skippingSteps.push(payload)
    }

    return {
      ...state,
      skippingSteps,
    }
  },
  [actions.includeStep]: (state, { payload }) => {
    const skippingSteps = state.skippingSteps
    const stepIndex = skippingSteps.indexOf(payload)

    skippingSteps.splice(stepIndex, 1)

    return {
      ...state,
      skippingSteps,
    }
  },
} as Reducer
