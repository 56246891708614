interface Address {
  address: string
  secondAddress: string
  city: string
  state: string
  zip: string
}

export interface Pet {
  name?: string
  years?: number
  months?: number
  weight?: number
  primaryBreed?: string
  secondaryBreeds?: string[]
  gender?: string
  speciesId?: number
}

export interface PetProfessional {
  role: string
  practice: {
    name: string
    phoneNumber: string
  }
  address: Address
}

export interface Procedure {
  procedureDate: Date
  perId?: number
  petProfessionalId?: number
}

export interface Owner {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address: Address
  pet: Pet
  petProfessional: PetProfessional
  procedure: Procedure
  isGift?: boolean
  ids?: {
    userId?: number
    petId?: number
    orderId?: number
    practiceId?: number
    petProfessionalId?: number
    procedureId?: number
  }
}

export interface Checkout {
  petOwner: Owner
  paymentInfo: object
  product: string
  couponCode: string
}

export const ValidProducts = {
  lifetime: 'lifetime',
  annual: 'annual',
  monthly: 'monthly',
  adiposeLifetime: 'adiposeLifetime',
  adiposeAnnual: 'adiposeAnnual',
  equineLifetime: 'equineLifetime',
  equineAnnual: 'equineAnnual',
}

export const ValidProductsInterval = {
  [ValidProducts.lifetime]: 'lifetime',
  [ValidProducts.adiposeLifetime]: 'adiposeLifetime',
  [ValidProducts.equineLifetime]: 'equineLifetime',
  [ValidProducts.annual]: 'year',
  [ValidProducts.adiposeAnnual]: 'year',
  [ValidProducts.equineAnnual]: 'year',
  [ValidProducts.monthly]: 'month',
}

export enum CheckoutStep {
  OwnerInfo = 1,
  Billing = 2,
  Checkout = 3,
  Shipping = 4,
  PetInfo = 5,
  PetProfessionalInfo = 6,
  Success = 7,
}

export enum SetupStep {
  Shipping = 1,
  PetInfo = 2,
  PetProfessionalInfo = 3,
  Procedure = 4,
  Success = 5,
}

export interface Setup {
  email: string
  target: string
}

export const initialState = {
  owner: {
    pet: {},
    address: {},
    petProfessional: {
      practice: {},
      address: {},
    },
    procedure: {},
    ids: undefined,
  } as Owner,
  currentStep: CheckoutStep.OwnerInfo as number,
  checkingOut: false,
  checkedOut: false,
  error: null,
  calculatingTaxes: false,
  taxesCalculated: false,
  calculatedTax: 0,
  loadingPlans: false,
  plansLoaded: false,
  availablePlans: [],
  paymentResult: undefined,
  product: undefined,
  amount: 0,
  loadingCoupon: false,
  coupon: undefined,
  validatedSteps: [],
  skippingSteps: [],
  breeds: undefined,
  loadingBreeds: false,
  loadingCheckoutDetails: false,
  subscriptionCheckoutDetails: { stripePlan: undefined, sku: undefined },
  updatingPet: false,
  creatingPetProfessional: false,
  loadingSetup: false,
  successSetup: false,
  updatePetProfessional: false,
  updateProcedure: false,
  authToken: undefined,
  lastVisitedStep: CheckoutStep.OwnerInfo,
}

export interface Reducer {
  [key: string]: (state: typeof initialState, action: any) => typeof initialState
}
