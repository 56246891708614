import { createActionThunk } from 'redux-thunk-actions'

import { createPetProfessional } from '../../api/pet-professionals/create-pet-professional'
import { actions } from './actions'
import { Practice, Procedure, Reducer } from './initial-state'

interface CreatePetProfessional {
  orderId: number
  petId: number
  practice: Practice
  procedure?: Procedure
}

export const createPetProfessionalAction = createActionThunk(
  actions.createPetProfessional,
  ({ petId, orderId, practice, procedure }: CreatePetProfessional) =>
    createPetProfessional({
      petProfessional: { practice, address: practice.address },
      petId,
      orderId,
      procedure,
    }),
)

export const createPetProfessionalReducer = {
  [createPetProfessionalAction.STARTED]: (state) => ({
    ...state,
    creatingProcedure: true,
  }),
  [createPetProfessionalAction.SUCCEEDED]: (state, { payload }) => {
    const pets = state.pets.map((pet) => {
      if (pet.id === payload.petId) {
        return {
          ...pet,
          petProfessionalId: payload.petProfessionalId,
          practice: payload.practice,
        }
      }

      return pet
    })

    return { ...state, pets }
  },
  [createPetProfessionalAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [createPetProfessionalAction.ENDED]: (state) => ({
    ...state,
    creatingProcedure: false,
  }),
} as Reducer
