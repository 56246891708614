import { Button } from '@material-ui/core'
import { Formik } from 'formik'
import * as React from 'react'

import { Input } from '../../shared/forms/Input'
import { PhoneInput } from '../../shared/forms/PhoneInput'
import { ownerInfoSchema } from './schemas/owner'

interface OwnerFormProps {
  owner: {
    email: string
    phoneNumber: string
    firstName: string
    lastName: string
    address: {
      address: string
      secondAddress: string
      city: string
      state: string
      zip: string
    }
  }
  handleSubmit: (values: object) => void
  checkEmail?: (email: string) => void
  onCancel?: () => void
}

const OwnerForm: React.SFC<OwnerFormProps> = ({ owner, checkEmail, onCancel, handleSubmit }) => {
  const getInititalValue = () => ({
    email: owner.email,
    firstName: owner.firstName,
    lastName: owner.lastName,
    phoneNumber: owner.phoneNumber,
    streetAddress: owner.address.address,
    secondAddress: owner.address.secondAddress,
    city: owner.address.city,
    state: owner.address.state,
    zip: owner.address.zip,
  })

  const handleOwnerUpdateSubmit = (values) =>
    handleSubmit({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      address: {
        address: values.streetAddress,
        secondAddress: values.secondAddress,
        city: values.city,
        state: values.state,
        zip: values.zip,
      },
    })

  return (
    <Formik
      enableReinitialize
      initialValues={getInititalValue()}
      validationSchema={ownerInfoSchema}
      onSubmit={handleOwnerUpdateSubmit}
    >
      {(formProps) => (
        <form className="owner-form" onSubmit={formProps.handleSubmit} noValidate>
          <section className="form-fields">
            <Input
              form={formProps}
              onBlur={(e) => checkEmail && checkEmail(e.target.value)}
              name="email"
              type="email"
              label="Email"
              fullWidth
            />
            <div className="same-line">
              <Input className="right-margin" form={formProps} name="firstName" type="text" label="First Name" />
              <Input form={formProps} name="lastName" type="text" label="Last Name" />
            </div>
            <PhoneInput form={formProps} label="Phone Number" name="phoneNumber" />
            <Input form={formProps} name="streetAddress" type="text" label="Street Address" fullWidth />
            <Input
              form={formProps}
              name="secondAddress"
              placeholder="Apt #, Unit, or Suite"
              type="text"
              label="Address Line 2"
              isOptional
              fullWidth
            />
            <Input form={formProps} name="city" type="text" label="City" fullWidth />
            <div className="same-line">
              <Input className="right-margin" form={formProps} name="state" type="text" maxLength={2} label="State" />
              <Input form={formProps} name="zip" type="text" label="Zip Code" maxLength={9} fullWidth />
            </div>
            <div className="same-line actions">
              <Button type="button" className="cancel" onClick={() => onCancel && onCancel()}>
                Cancel
              </Button>
              <Button type="submit" className="save">
                <span className="icon save white" />
                Save
              </Button>
            </div>
          </section>
        </form>
      )}
    </Formik>
  )
}

export { OwnerForm }
