import axios from 'axios'
import { Checkout } from '../../redux/checkout/initial-state'

export const checkout = async (checkoutData: Checkout) => {
  const { data } = await axios({
    method: 'post',
    url: '/checkout',
    data: checkoutData,
  })

  return data
}
