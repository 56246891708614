import { createActionThunk } from 'redux-thunk-actions'

import { updateProcedure } from '../../api/procedures/update-procedure'
import { actions } from './actions'
import { AUTH_TOKEN_KEY } from './get-auth-token'
import { Procedure, Reducer } from './initial-state'

export const updateProcedureAction = createActionThunk(
  actions.updateProcedure,
  ({ procedureId, procedure }: { procedureId: number; procedure: Procedure }) =>
    updateProcedure({ procedureId, procedure }),
)

export const updateProcedureReducer = {
  [updateProcedureAction.STARTED]: (state) => ({
    ...state,
  }),
  [updateProcedureAction.SUCCEEDED]: (state) => {
    window.localStorage.setItem(AUTH_TOKEN_KEY, '')

    return { ...state }
  },
  [updateProcedureAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [updateProcedureAction.ENDED]: (state) => ({
    ...state,
  }),
} as Reducer
