import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, CircularProgress } from '@material-ui/core'
import lodash from 'lodash'
import moment from 'moment'
import * as React from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { trackEvent, SegmentEvents } from '../../api/segment'
import { getBreedsAction } from '../../redux/checkout/get-breeds'
import { Store } from '../../redux/configure-store'
import { createPetProfessionalAction } from '../../redux/user/create-pet-professional'
import { createProcedureAction } from '../../redux/user/create-procedure'
import { getProfileAction } from '../../redux/user/get-profile'
import { initialState, Pet as PetInterface } from '../../redux/user/initial-state'
import { updatePetAction, updatePetPictureAction } from '../../redux/user/update-pet'
import { updatePetProfessionalAction } from '../../redux/user/update-pet-professional'
import '../checkout/checkout.scss'
import '../checkout/success.scss'
import { PetForm } from './forms/PetForm'
import PicturePermissionForm from './forms/PicturePermissionForm'
import { PracticeForm } from './forms/PracticeForm'
import { ProcedureForm } from './forms/ProcedureForm'
import './timeline.scss'

type Props = {
  history: any
  pet: PetInterface
  user: typeof initialState
  match: { params: { petId: number } }
  authToken: string
} & Dispatchers

const mapStateToProps = (state: Store, props: Props) => ({
  user: state.user,
  pet: state.user.pets.find((pet) => pet.id === +props.match.params.petId),
})

const mapDispatchToProps = (dispatch) => ({
  getProfile: bindActionCreators(getProfileAction, dispatch),
  getBreeds: bindActionCreators(getBreedsAction, dispatch),
  updatePet: bindActionCreators(updatePetAction, dispatch),
  updatePetPicture: bindActionCreators(updatePetPictureAction, dispatch),
  createPetProfessional: bindActionCreators(createPetProfessionalAction, dispatch),
  updatePetProfessional: bindActionCreators(updatePetProfessionalAction, dispatch),
  createProcedure: bindActionCreators(createProcedureAction, dispatch),
})

type Dispatchers = ReturnType<typeof mapDispatchToProps>

// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
const FILE_TYPES = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  `image/x-icon`,
]

export class PetComponent extends React.Component<Props, any> {
  state = {
    petEditionMode: false,
    petPhotoEditionMode: false,
    petPhotoPermissionEditionMode: false,
    practiceEditionMode: false,
    procedureEditionMode: false,
    lightBoxOpen: false,
    newPetPhoto: null,
  }

  componentDidMount() {
    this.props.getProfile(this.props.authToken)
    this.props.getBreeds()
  }

  handlePetEditionMode = (): void => this.setState({ petEditionMode: !this.state.petEditionMode })

  handlePetPhotoPermissionEditionMode = (): void =>
    this.setState({ petPhotoPermissionEditionMode: !this.state.petPhotoPermissionEditionMode })

  handleProcedureEditionMode = (): void =>
    this.setState({ procedureEditionMode: !this.state.procedureEditionMode })

  handleShowSupportMessage = (): void => {
    alert(
      'To change your procedure information, please contact help@gallant.com or call 855-4-GALLANT.',
    )
  }

  handlePracticeEditionMode = (): void => {
    const { procedure } = this.props.pet

    if (procedure && procedure.procedureDate) {
      return this.goToProcedurePage()
    }

    this.setState({ practiceEditionMode: !this.state.practiceEditionMode })
  }

  handlePetUpdateSubmit = (values) => {
    this.props.updatePet(values)
    this.handlePetEditionMode()
  }

  handlePermissionUpdate = (pet: PetInterface) => {
    this.props.updatePet(pet)
    this.handlePetPhotoPermissionEditionMode()
  }

  handlePetProfessionalUpdate = async (practice) => {
    const { pet, createPetProfessional, updatePetProfessional } = this.props
    const { id, order, petProfessionalId } = pet

    if (!pet.petProfessionalId) {
      return await createPetProfessional({ petId: id, orderId: order.id, practice })
    }

    await updatePetProfessional({ petProfessionalId, practice })

    this.handlePracticeEditionMode()
  }

  handleProcedureUpdate = async (procedure) => {
    const { pet } = this.props
    const { id, practice } = pet

    if (pet.procedure) return

    await this.props.createProcedure({ ...procedure, petId: id, practiceId: practice.id })

    trackEvent(SegmentEvents.ProcedureScheduled, {
      procedure_date: new Date(procedure.procedureDate).toISOString(),
    })

    this.setState({ practiceEditionMode: false })

    this.props.getProfile(this.props.authToken)
  }

  goToProcedurePage = () => this.props.history.push('/procedure')

  // tslint:disable-next-line: cyclomatic-complexity
  renderPet = (pet, breeds) => {
    const { newPetPhoto, lightBoxOpen, petPhotoEditionMode } = this.state

    const handleImageChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      const file = evt.currentTarget.files.item(0)
      if (file && FILE_TYPES.includes(file.type)) {
        this.setState({ newPetPhoto: file, petPhotoEditionMode: true })
      }
      evt.currentTarget.value = null
    }

    const handleImageSubmit = () => {
      this.props.updatePetPicture(pet.id, newPetPhoto)
      this.setState({ petPhotoEditionMode: false })
    }

    const handleImageCanceled = () => {
      document.getElementById('pet_photo_uploads').setAttribute('value', '')
      this.setState({ petPhotoEditionMode: false, newPetPhoto: null })
    }

    if (!pet) return <p className="support-copy">Ooops! Unfortunally, no pet was found. :/</p>

    const pictureUrl =
      (newPetPhoto && URL.createObjectURL(newPetPhoto)) ||
      (pet.picture && `${pet.picture}?u=${new Date().getTime()}`)

    return (
      <section className="success-summary pet">
        <div className="title-container">
          <p className="title">
            Photo
            <a data-test-id="edit-pet-photo">
              <label className="pet-photo-uploader-label" htmlFor="pet_photo_uploads">
                <span className="icon edit" /> Edit
              </label>
            </a>
          </p>
        </div>
        <div className="pet-photo-container">
          {!pictureUrl && <img className="picture-icon" src="/assets/images/pet.svg" />}
          {pictureUrl && (
            <img
              className="picture"
              src={pictureUrl}
              onClick={() => this.setState({ lightBoxOpen: true })}
            />
          )}
          {lightBoxOpen && (
            <Lightbox
              mainSrc={pictureUrl}
              onCloseRequest={() => this.setState({ lightBoxOpen: false })}
            />
          )}
        </div>
        <form>
          <input
            className="pet-photo-uploader"
            id="pet_photo_uploads"
            type="file"
            name="pet-photo"
            accept="image/*"
            multiple={false}
            onChange={handleImageChange}
          />
          {petPhotoEditionMode && (
            <div className="same-line actions">
              <Button type="button" className="cancel" onClick={handleImageCanceled}>
                Cancel
              </Button>
              <Button type="button" className="save" onClick={handleImageSubmit}>
                <span className="icon save white" />
                Save
              </Button>
            </div>
          )}
        </form>
        <div className="title-container">
          <p className="title">
            Photo Permission
            <a
              href="javascript:void(0);"
              onClick={() => this.handlePetPhotoPermissionEditionMode()}
              data-test-id="edit-pet-photo-permission-button"
            >
              <span className="icon edit" />{' '}
              {this.state.petPhotoPermissionEditionMode ? 'Cancel' : 'Edit'}
            </a>
          </p>
        </div>
        {this.state.petPhotoPermissionEditionMode ? (
          <PicturePermissionForm
            pet={pet}
            handleSubmit={this.handlePermissionUpdate}
            cancelSubmit={() => this.handlePetPhotoPermissionEditionMode()}
          />
        ) : (
          <ul className="fluid">
            <li>
              <p>Can Gallant use your pup's picture for social media or website posts?</p>
              <p data-test-id="pet-photo-permission">
                {pet.picturePermission === true || pet.picturePermission === null ? 'Yes' : 'No'}
              </p>
            </li>
          </ul>
        )}
        <div className="title-container">
          <p className="title">
            Pet{' '}
            <a
              href="javascript:void(0);"
              onClick={() => this.handlePetEditionMode()}
              data-test-id="edit-pet-button"
            >
              <span className="icon edit" /> {this.state.petEditionMode ? 'Cancel' : 'Edit'}
            </a>
          </p>
        </div>
        {this.state.petEditionMode ? (
          <PetForm
            pet={pet}
            breeds={breeds}
            onCancel={() => this.handlePetEditionMode()}
            handleSubmit={this.handlePetUpdateSubmit}
          />
        ) : (
          <ul className="fluid">
            <li>
              <p>Name</p>
              <p data-test-id="pet-name">{pet.name || 'N/A'}</p>
            </li>
            <li>
              <p>Sex</p>
              <p>{pet.gender ? pet.gender.charAt(0).toUpperCase() + pet.gender.slice(1) : 'N/A'}</p>
            </li>
            <li>
              <p>Age</p>
              <p data-test-id="pet-years">
                {pet.years || 0} year(s) and {pet.months || 0} month(s)
              </p>
            </li>
            <li>
              <p>Main Breed</p>
              <p>{pet.primaryBreed || 'N/A'}</p>
            </li>
            <li>
              <p>Secondary Breeds</p>
              <p>{pet.secondaryBreeds ? pet.secondaryBreeds.join(', ') : 'N/A'}</p>
            </li>
            <li>
              <p>Plan</p>
              <p>{(pet.order && lodash.startCase(pet.order.plan)) || 'N/A'}</p>
            </li>
          </ul>
        )}
        {this.renderPracticeInfo(pet)}
        {this.renderProcedureInfo(pet)}
        {this.renderStatusesInfo(pet)}
      </section>
    )
  }

  renderPracticeInfo(pet) {
    const { practiceEditionMode } = this.state

    return (
      <>
        <div className="title-container">
          <p className="title">
            Vet Professional{' '}
            {pet.practice.name && (
              <a
                href="javascript::void(0)"
                onClick={() => {
                  this.handleShowSupportMessage()
                }}
              >
                <span className="icon edit" />
                Edit
              </a>
            )}
          </p>
        </div>
        {pet.practice.name && !practiceEditionMode ? (
          <ul className="fluid">
            <li>
              <p>Practice Name</p>
              <p>{pet.practice.name || 'N/A'}</p>
            </li>
            <li>
              <p>Address</p>
              <p>
                {pet.practice.address ? (
                  <>
                    {pet.practice.address.address}, {pet.practice.address.city},{' '}
                    {pet.practice.address.state}
                    <br />
                    {pet.practice.address.zip}
                  </>
                ) : (
                  'N/A'
                )}
              </p>
            </li>
            <li>
              <p>Phone Number</p>
              <p>
                {pet.practice.phoneNumber
                  ? pet.practice.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
                  : 'N/A'}
              </p>
            </li>
          </ul>
        ) : (
          <PracticeForm
            practice={pet.practice}
            onCancel={this.handlePracticeEditionMode}
            handleSubmit={this.handlePetProfessionalUpdate}
          />
        )}
      </>
    )
  }

  renderProcedureInfo(pet) {
    const { procedureEditionMode } = this.state

    return (
      pet.practice &&
      pet.practice.id && (
        <>
          <div className="title-container">
            <p className="title">
              Procedure Date{' '}
              {pet.procedure && (
                <a
                  href="javascript::void(0)"
                  onClick={() => {
                    this.handleShowSupportMessage()
                  }}
                >
                  <span className="icon edit" />
                  Edit
                </a>
              )}
            </p>
          </div>
          {pet.procedure && !procedureEditionMode ? (
            <ul className="fluid">
              <li>
                <p>Procedure Date</p>
                <p data-test-id="pet-procedure-date">
                  {new Date(pet.procedure.procedureDate).toLocaleDateString('en-US', {
                    timeZone: 'UTC',
                  })}
                </p>
              </li>
            </ul>
          ) : (
            <ProcedureForm procedure={pet.procedure} handleSubmit={this.handleProcedureUpdate} />
          )}
        </>
      )
    )
  }

  renderStatusesInfo(pet) {
    return (
      <>
        <div className="title-container">
          <p className="title">Status</p>
        </div>
        <ul className="timeline">
          {pet.timeline.map((item) => (
            <li key={item.action} className="timeline-item">
              <div className="timeline-header">
                <h3 className="timeline-action">{item.action}</h3>
                {item.time && <div className="timeline-time">{moment(item.time).fromNow()}</div>}
              </div>
              {item.metadata && <div className="timeline-highlight">{item.metadata}</div>}
            </li>
          ))}
        </ul>
      </>
    )
  }

  renderContent = () => {
    const { pet, user } = this.props
    const { breeds } = user

    return (
      <>
        <p className="copy">You can review everything about {pet && pet.name} below.</p>
        <div className="success-container">
          {this.renderPet(pet, breeds)}
          <p className="support-copy">
            If you have any questions, contact us at{' '}
            <a href="mailto:help@gallant.com">help@gallant.com</a> or call at{' '}
            <a href="tel:855-4-GALLANT">855-4-GALLANT</a>.
          </p>
        </div>
      </>
    )
  }

  render() {
    const { history, user } = this.props
    const { loadingProfile } = user

    return (
      <div className="container profile">
        <header className="header">
          <button className="no-style-btn" onClick={() => history.goBack()}>
            <FontAwesomeIcon className="back-arrow" icon={faArrowLeft} />
          </button>
          <div className="image-logo centralized" />
        </header>
        <div className="content">
          {loadingProfile ? (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            this.renderContent()
          )}
        </div>
      </div>
    )
  }
}

const Pet = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PetComponent)

export { Pet }
