export const routePaths = {
  root: '/',
  plans: '/plans/:product',
  setup: ['/setup/:authToken', '/setup'],
  user: {
    login: '/login',
    profile: ['/profile/:authToken', '/profile'],
    pet: ['/pet/:petId', '/pet'],
    procedure: '/procedure',
  },
}
