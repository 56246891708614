import { createActionThunk } from 'redux-thunk-actions'

import { updateOwner } from '../../api/owners/update-owner'
import { actions } from './actions'
import { Reducer } from './initial-state'

export const updateProfileAction = createActionThunk(actions.updateProfile, (id, data) => updateOwner(id, data))

export const updateProfileReducer = {
  [updateProfileAction.STARTED]: (state) => ({
    ...state,
    updatingProfile: true,
    error: undefined,
  }),
  [updateProfileAction.SUCCEEDED]: (state, { payload: profile }) => ({
    ...state,
    profile,
    error: undefined,
  }),
  [updateProfileAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [updateProfileAction.ENDED]: (state) => ({
    ...state,
    updatingProfile: false,
  }),
} as Reducer
