import { CircularProgress } from '@material-ui/core'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as Segment from '../../api/segment/index'
import { changeStepAction } from '../../redux/checkout/change-step'
import { getAuthTokenAction } from '../../redux/checkout/get-auth-token'
import { initialState as checkoutInitialState, SetupStep } from '../../redux/checkout/initial-state'
import { resetFormAction } from '../../redux/checkout/reset-form'
import { Store } from '../../redux/configure-store'
import { getProfileAction, AUTH_TOKEN_KEY } from '../../redux/user/get-profile'
import { initialState as userInitialState } from '../../redux/user/initial-state'
import { SetupSteps } from './SetupSteps'
import { Success } from './Success'
import './checkout.scss'
import { PetForm } from './forms/PetForm'
import { PetProfessionalForm } from './forms/PetProfessionalForm'
import { ProcedureForm } from './forms/ProcedureForm'
import { SetupForm } from './forms/SetupForm'
import { ShippingForm } from './forms/ShippingForm'

const mapStateToProps = (state: Store) => ({
  checkout: state.checkout,
  user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
  changeStep: bindActionCreators(changeStepAction, dispatch),
  getAuthToken: bindActionCreators(getAuthTokenAction, dispatch),
  resetForm: bindActionCreators(resetFormAction, dispatch),
  getProfile: bindActionCreators(getProfileAction, dispatch),
})

type Dispatchers = ReturnType<typeof mapDispatchToProps>
type Props = {
  history: any
  checkout: typeof checkoutInitialState
  match: { params: { authToken: string } }
  user: typeof userInitialState
} & Dispatchers

export class SetupComponent extends React.Component<Props, any> {
  componentDidMount() {
    Segment.page(Segment.SegmentEvents.Setup)

    let { authToken } = this.props.match.params

    if (!authToken) {
      authToken = window.localStorage.getItem('userAuthToken')
    }

    if (authToken) {
      this.props.getAuthToken(authToken)
    }

    const userToken = window.localStorage.getItem(AUTH_TOKEN_KEY)
    this.props.getProfile(userToken)
  }

  changeCurrentStep = (step: number) => {
    this.props.changeStep(step)
  }

  getWizardPageCopy = () => {
    const { checkout } = this.props

    const token = window.localStorage.getItem(AUTH_TOKEN_KEY)
    const profileLink = <Link to={`/profile/${token}`}>your profile</Link>

    const copies = {
      [SetupStep.Shipping]: 'Shipping',
      [SetupStep.Success]: (
        <>
          You can review the information below. To add/edit details, view status, or add additional
          pets, visit {profileLink}.
        </>
      ),
      [SetupStep.PetInfo]: `Tell us a little more about ${checkout.owner.pet.name} so that we can make sure everything is just right.`,
      [SetupStep.PetProfessionalInfo]: 'Please let us know about your vet.',
      [SetupStep.Procedure]: `When is ${checkout.owner.pet.name}’s spay procedure scheduled?`,
    }

    return copies[this.props.checkout.currentStep]
  }

  renderHeader = () => (
    <header className="header">
      <div className="image-logo" />
    </header>
  )

  renderPageCopy = () => {
    const pageCopy = this.getWizardPageCopy()
    const { checkout } = this.props

    if (checkout.currentStep === SetupStep.PetInfo) {
      return null
    }

    return <p className="copy">{pageCopy}</p>
  }

  renderSteps = () => {
    if (this.props.checkout.currentStep === SetupStep.Success) {
      return null
    }

    return (
      <SetupSteps
        currentStep={this.props.checkout.currentStep}
        onStepClick={(step: number) => this.changeCurrentStep(step)}
        validatedSteps={this.props.checkout.validatedSteps}
        skippingSteps={this.props.checkout.skippingSteps}
      />
    )
  }

  renderCurrentStep = () => {
    const pages = {
      [SetupStep.Shipping]: (
        <div className="shipping active">
          <ShippingForm nextStep={SetupStep.PetInfo} />
        </div>
      ),
      [SetupStep.PetInfo]: (
        <div className="pet active">
          <PetForm nextStep={SetupStep.PetProfessionalInfo} hideReceipt isSetup />
        </div>
      ),
      [SetupStep.PetProfessionalInfo]: (
        <div className="vet active">
          <PetProfessionalForm
            hideProcedureDate
            hideHasVet
            nextStep={SetupStep.Procedure}
            submitText="Next"
          />
        </div>
      ),
      [SetupStep.Procedure]: (
        <div className="procedure active">
          <ProcedureForm nextStep={SetupStep.Success} />
        </div>
      ),
      [SetupStep.Success]: (
        <div className="success active">
          <Success isSetup />
        </div>
      ),
    }

    return pages[this.props.checkout.currentStep]
  }

  renderContent = () => {
    const { checkout } = this.props

    if (checkout.authToken) {
      return (
        <>
          {this.renderSteps()}
          {this.renderPageCopy()}
          {this.renderCurrentStep()}
        </>
      )
    }

    if (checkout.loadingSetup) {
      return (
        <div className="setup-loading">
          <CircularProgress size={60} />
        </div>
      )
    }

    if (checkout.successSetup) {
      return <Success message="We've sent you an e-mail, please check your inbox to proceed." />
    }

    return (
      <>
        <h1>Welcome!</h1>
        <p className="copy">Please enter your email and we will send you a link to login.</p>
        <SetupForm />
      </>
    )
  }

  render() {
    return (
      <>
        <Helmet
          title="Setup | Gallant"
          link={[{ rel: 'canonical', href: '/setup' }]}
          meta={[
            {
              name: 'description',
              content:
                "Tell us how you've heard about us, check your order and fill in your pet procedure info.",
            },
          ]}
        />
        <section className="container">
          {this.renderHeader()}
          <div className="content">{this.renderContent()}</div>
        </section>
      </>
    )
  }
}

const Setup = connect(mapStateToProps, mapDispatchToProps)(SetupComponent)

export { Setup }
