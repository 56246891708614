import cx from 'classnames'
import * as React from 'react'
import { CardCVCElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements'

const { useState, useEffect } = React

const CreditCardFields = ({ elementClasses, elementStyles }) => {
  const [activeField, setActiveField] = useState(null)

  const checkIfInputRendered = () => {
    const el = document.querySelector('.credit-card-field') as HTMLUnknownElement

    return el.offsetHeight > 0
  }

  /*
    There is a specific bug with opera and stripe elements. We need to re-render them to be able to see the digits on safari/safari mobile
    https://www.pivotaltracker.com/story/show/176319502
  */
  const forceCardInputReflow = () => {
    const inputIds = ['#card-number-input', '#card-expiry-input', '#card-cvc-input']
    const hasElementRendered = document.querySelector(inputIds[0]) as HTMLUnknownElement

    if (hasElementRendered) {
      inputIds.forEach((inputId) => {
        const element = document.querySelector(inputId) as HTMLUnknownElement

        const initialDisplay = element.style.display

        element.style.display = 'none'
        const useless = element.offsetHeight
        element.style.display = initialDisplay

        /*
          we need to access element.offsetHeight to reflow the component
          but the tslint complains about unused variable
          returning just because of that
        */
        return useless
      })
    }
  }

  /*
    Checking each half second for the component to be rendered,
    then we reflow it
    https://github.com/stripe/react-stripe-js/issues/136

    I know, sorry!
  */
  let watcherInterval
  useEffect(() => {
    watcherInterval = setInterval(() => {
      if (checkIfInputRendered) {
        forceCardInputReflow()

        clearInterval(watcherInterval)
      }
    }, 500)

    return () => clearInterval(watcherInterval)
  })

  return (
    <>
      <div className="credit-card-field">
        <label className={cx({ focused: activeField === 'card-number' })}>Card Number</label>
        <CardNumberElement
          style={elementStyles}
          id="card-number-input"
          classes={elementClasses}
          onBlur={() => setActiveField(null)}
          onFocus={() => setActiveField('card-number')}
        />
      </div>
      <div className="same-line">
        <div className="credit-card-field right-margin">
          <label className={cx({ focused: activeField === 'expiring' })}>Expiring</label>
          <CardExpiryElement
            style={elementStyles}
            id="card-expiry-input"
            classes={elementClasses}
            onBlur={() => setActiveField(null)}
            onFocus={() => setActiveField('expiring')}
          />
        </div>
        <div className="credit-card-field">
          <label className={cx({ focused: activeField === 'cvc' })}>CVC</label>
          <CardCVCElement
            style={elementStyles}
            id="card-cvc-input"
            classes={elementClasses}
            onBlur={() => setActiveField(null)}
            onFocus={() => setActiveField('cvc')}
          />
        </div>
      </div>
    </>
  )
}

export { CreditCardFields }
