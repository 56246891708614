import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import * as localStorage from './local-storage'

import { checkout } from './checkout'
import { initialState as checkoutInitialState } from './checkout/initial-state'
import { user } from './user'
import { initialState as userInitialState } from './user/initial-state'

export interface Store {
  checkout: typeof checkoutInitialState
  user: typeof userInitialState
}

const ducks = combineReducers({
  checkout,
  user,
})

const { pathname } = window.location

let store = createStore(ducks, composeWithDevTools(applyMiddleware(thunk)))

if (pathname === '/' || pathname.includes('plans')) {
  const persistedState = localStorage.loadState()
  store = createStore(ducks, persistedState, composeWithDevTools(applyMiddleware(thunk)))
  store.subscribe(() => localStorage.saveState(store.getState()))
}

export { store }
