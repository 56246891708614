import { Button } from '@material-ui/core'
import * as React from 'react'
import { PaymentRequestButtonElement } from 'react-stripe-elements'
import { initialState as checkoutInitialState } from '../../../../../redux/checkout/initial-state'
import { PaymentProvider } from '../PaymentProvider'

interface eventFunc {
  (event: any): void
}

interface CheckoutButtonProps {
  selectedPaymentProvider: any
  isCheckoutDisabled: boolean
  paymentRequest: any
  checkout: typeof checkoutInitialState
  handleCheckout: eventFunc
}

interface StripeProps {
  paymentRequest: any
}

interface CheckoutProps {
  label: string
  isCheckoutDisabled: boolean
  checkout: typeof checkoutInitialState
  handleCheckout: eventFunc
}

const Checkout: React.FunctionComponent<CheckoutProps> = ({ label, checkout, handleCheckout, isCheckoutDisabled }) => (
  <Button disabled={isCheckoutDisabled} onClick={handleCheckout} type="submit">
    {label}
    <span className="arrow-right" />
  </Button>
)

const StripePaymentButton: React.FunctionComponent<StripeProps> = ({ paymentRequest }) =>
  paymentRequest && (
    <PaymentRequestButtonElement
      className="PaymentRequestButton"
      style={{
        paymentRequestButton: {
          type: 'buy',
          theme: 'dark',
          height: '50px',
        },
      }}
      paymentRequest={paymentRequest}
    />
  )

const CheckoutButton: React.FunctionComponent<CheckoutButtonProps> = ({
  paymentRequest,
  checkout,
  isCheckoutDisabled,
  selectedPaymentProvider,
  handleCheckout,
}) => {
  const buttonSwitch = {
    [PaymentProvider.Stripe]: (
      <Checkout
        checkout={checkout}
        label="PURCHASE"
        isCheckoutDisabled={isCheckoutDisabled}
        handleCheckout={handleCheckout}
      />
    ),
    [PaymentProvider.Affirm]: (
      <Checkout
        checkout={checkout}
        label="APPLY WITH AFFIRM"
        isCheckoutDisabled={isCheckoutDisabled}
        handleCheckout={handleCheckout}
      />
    ),
    [PaymentProvider.ApplePay]: <StripePaymentButton paymentRequest={paymentRequest} />,
    [PaymentProvider.GooglePay]: <StripePaymentButton paymentRequest={paymentRequest} />,
  }

  const ButtonElem = buttonSwitch[selectedPaymentProvider] || (
    <Checkout
      checkout={checkout}
      label="PURCHASE"
      isCheckoutDisabled={isCheckoutDisabled}
      handleCheckout={handleCheckout}
    />
  )

  return checkout.checkingOut ? '' : ButtonElem
}

export { CheckoutButton }
