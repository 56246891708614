import { Button } from '@material-ui/core'
import { Formik } from 'formik'
import * as React from 'react'

import { formatDate } from '../../checkout/forms/utils'
import { DatePicker } from '../../shared/forms/DatePicker'
import { procedureSchema } from './schemas/procedure'

interface ProcedureFormProps {
  procedure: {
    procedureDate: Date
  }
  handleSubmit: (values: object) => void
  onCancel?: () => void
}

const ProcedureForm: React.SFC<ProcedureFormProps> = ({ procedure, onCancel, handleSubmit }) => {
  const getInititalValue = () => ({
    procedureDate: procedure && procedure.procedureDate,
  })

  const handleProcedureSubmit = ({ procedureDate }) =>
    handleSubmit({
      procedureDate: procedureDate && formatDate(procedureDate),
    })

  return (
    <Formik
      enableReinitialize
      initialValues={getInititalValue()}
      validationSchema={procedureSchema}
      onSubmit={handleProcedureSubmit}
    >
      {(formProps) => {
        const { errors, touched, handleSubmit } = formProps

        return (
          <form className="pet-procedure-form" onSubmit={handleSubmit} noValidate>
            <section className="form-fields">
              <DatePicker
                name="procedureDate"
                form={formProps}
                onChange={(date) => formProps.setFieldValue('procedureDate', date)}
                label="Procecure Date"
                subLabel="If you haven’t scheduled yet, you can leave this blank."
              />
              {errors.procedureDate && touched.procedureDate && <p className="error">{errors.procedureDate}</p>}
              <div className="same-line actions">
                <Button type="button" className="cancel" onClick={() => onCancel && onCancel()}>
                  Cancel
                </Button>
                <Button type="submit" className="save">
                  <span className="icon save white" />
                  Save
                </Button>
              </div>
            </section>
          </form>
        )
      }}
    </Formik>
  )
}

export { ProcedureForm }
