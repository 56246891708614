import { createActionThunk } from 'redux-thunk-actions'

import { updatePet } from '../../api/pets/update-pet'
import { actions } from './actions'
import { Pet, Reducer } from './initial-state'

export const updatePetAction = createActionThunk(actions.updatePet, ({ petId, pet }: { petId: number; pet: Pet }) =>
  updatePet({ petId, pet }),
)

export const updatePetReducer = {
  [updatePetAction.STARTED]: (state) => ({
    ...state,
    updatingPet: true,
  }),
  [updatePetAction.SUCCEEDED]: (state) => ({
    ...state,
  }),
  [updatePetAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [updatePetAction.ENDED]: (state) => ({
    ...state,
    updatingPet: false,
  }),
} as Reducer
