import '../checkout.scss'

import { Formik } from 'formik'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Yup from 'yup'

import { Button } from '@material-ui/core'

import { createAuthTokenAction } from '../../../redux/checkout/create-auth-token'
import { initialState as checkoutInitialState } from '../../../redux/checkout/initial-state'
import { Store } from '../../../redux/configure-store'
import { Input } from '../../shared/forms/Input'

const mapStateToProps = (state: Store) => ({
  checkout: state.checkout,
})

const mapDispatchToProps = (dispatch) => ({
  submitAuthToken: bindActionCreators(createAuthTokenAction, dispatch),
})

type Dispatchers = ReturnType<typeof mapDispatchToProps>
type Props = { checkout: typeof checkoutInitialState } & Dispatchers

export interface SetupValues {
  email: string
}

const setupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be email format.')
    .required('Can’t be blank.'),
})

export class SetupFormComponent extends React.Component<Props, any> {
  state = {
    formActions: {
      setSubmitting: (val: boolean) => null,
    },
  }

  componentDidUpdate(prevProps: Props) {
    const prevCheckout = prevProps.checkout
    const { checkout } = this.props

    if (prevCheckout.loadingSetup && !checkout.loadingSetup && checkout.error) {
      this.state.formActions.setSubmitting(false)
    }
  }

  getInitialValues = (): SetupValues => ({
    email: '',
  })

  renderForm = ({ formProps }) => {
    const { error } = this.props.checkout

    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        <section className="form-fields">
          <Input form={formProps} name="email" type="email" label="Email" fullWidth />
        </section>
        {error && (
          <p className="error" style={{ paddingTop: '20px' }}>
            {error}
          </p>
        )}
        <Button disabled={formProps.isSubmitting} type="submit">
          NEXT
        </Button>
      </form>
    )
  }

  onFormSubmit = (values, formActions) => {
    this.handleSetupSubmit(values)
    this.setState({ formActions })
  }

  handleSetupSubmit = (values: SetupValues) => {
    const setup = {
      email: values.email,
      target: 'setup',
    }

    this.props.submitAuthToken(setup)
  }

  render() {
    const initialValues = this.getInitialValues()

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={setupSchema}
        onSubmit={this.onFormSubmit}
      >
        {(formProps) => this.renderForm({ formProps })}
      </Formik>
    )
  }
}

const SetupForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetupFormComponent)

export { SetupForm }
