import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { identify } from '../../api/segment'
import { initialState as checkoutInitialState } from '../../redux/checkout/initial-state'
import { resetFormAction } from '../../redux/checkout/reset-form'
import { Store } from '../../redux/configure-store'
import { LOCAL_STORE_KEY } from '../../redux/local-storage'
import { getProfileAction, AUTH_TOKEN_KEY } from '../../redux/user/get-profile'
import { initialState as userInitialState } from '../../redux/user/initial-state'
import './success.scss'

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

const mapStateToProps = (state: Store) => {
  return {
    checkout: state.checkout,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetForm: bindActionCreators(resetFormAction, dispatch),
    getProfile: bindActionCreators(getProfileAction, dispatch),
  }
}

type Dispatchers = ReturnType<typeof mapDispatchToProps>
type Props = {
  checkout: typeof checkoutInitialState
  message?: string
  user: typeof userInitialState
  isSetup?: boolean
} & Dispatchers

export class SuccessComponent extends React.Component<Props, any> {
  state = {
    hearAboutUsSource: null,
    hearAboutUsSourceOther: '',
    hearAboutUsSourceSent: false,
  }

  componentDidMount() {
    window.localStorage.setItem(LOCAL_STORE_KEY, '')
    window.scrollTo(0, 0)

    const userToken = window.localStorage.getItem(AUTH_TOKEN_KEY)
    this.props.getProfile(userToken)
  }

  capitalizeFirstLetter = (value: string) => value && value.charAt(0).toUpperCase() + value.slice(1)

  getFormattedDate = (date: Date) => (date ? moment(date).format('L') : null)

  getFormattedPhone = (phone) => {
    const number = phoneUtil.parseAndKeepRawInput(phone, 'US')

    return phoneUtil.formatInOriginalFormat(number, 'US')
  }

  renderSupportCopy = () => {
    return (
      <p className="support-copy">
        If you have any questions, contact us at{' '}
        <a href="mailto:help@gallant.com">help@gallant.com</a> or call at{' '}
        <a href="tel:855-4-GALLANT">855-4-GALLANT</a>.
      </p>
    )
  }

  renderPet = () => {
    const { checkout } = this.props
    const pet = checkout.owner.pet

    return [
      <div className="title-container pet">
        <p className="title">pet</p>
      </div>,
      <ul className="fluid">
        <li>
          <p>Name</p>
          <p data-test-id="pet-name">{pet.name}</p>
        </li>
        <li>
          <p>Age</p>
          <p data-test-id="pet-age">
            {pet.years || 0} year(s) and {pet.months || 1} month(s)
          </p>
        </li>
        <li>
          <p>Gender</p>
          <p data-test-id="pet-gender">{this.capitalizeFirstLetter(pet.gender)}</p>
        </li>
        <li>
          <p>Main Breed</p>
          <p data-test-id="pet-primary-breed">{pet.primaryBreed || 'N/A'}</p>
        </li>
        <li>
          <p>Secondary Breeds</p>
          <p data-test-id="pet-secondary-breeds">
            {pet.secondaryBreeds ? pet.secondaryBreeds.join(', ') : 'N/A'}
          </p>
        </li>
      </ul>,
    ]
  }

  renderOwner = () => {
    const { checkout } = this.props

    return (
      <>
        <div className="title-container you">
          <p className="title">you</p>
        </div>
        <ul className="fluid">
          <li>
            <p>First Name</p>
            <p data-test-id="owner-firstname">{checkout.owner.firstName}</p>
          </li>
          <li>
            <p>Last Name</p>
            <p data-test-id="owner-lastname">{checkout.owner.lastName}</p>
          </li>
          <li>
            <p>Email</p>
            <p data-test-id="owner-email">{checkout.owner.email}</p>
          </li>
          <li>
            <p>Address</p>
            <p>
              {checkout.owner.address.address ? (
                <>
                  {checkout.owner.address.address}, {checkout.owner.address.city},{' '}
                  {checkout.owner.address.state} {checkout.owner.address.zip}
                </>
              ) : (
                'N/A'
              )}
            </p>
          </li>
        </ul>
      </>
    )
  }

  renderPetProfessional = () => {
    const { checkout } = this.props
    const petProfessional = checkout.owner.petProfessional

    if (!petProfessional.practice.name) {
      return null
    }

    return (
      <>
        <div className="title-container pet-professional">
          <p className="title">pet professional</p>
        </div>
        <ul className="fluid">
          <li>
            <p>Practice Name</p>
            <p data-test-id="practice-name">{petProfessional.practice.name}</p>
          </li>
          <li>
            <p>Address</p>
            <p data-test-id="practice-address">
              {petProfessional.address.address}, {petProfessional.address.city},{' '}
              {petProfessional.address.state} {petProfessional.address.zip}
            </p>
          </li>
          {petProfessional.practice.phoneNumber && (
            <li>
              <p>Phone Number</p>
              <p data-test-id="practice-phone">
                {this.getFormattedPhone(petProfessional.practice.phoneNumber)}
              </p>
            </li>
          )}
          {checkout.owner.procedure.procedureDate && (
            <li>
              <p>Procedure Date</p>
              <p data-test-id="procedure-date">
                {this.getFormattedDate(checkout.owner.procedure.procedureDate)}
              </p>
            </li>
          )}
        </ul>
      </>
    )
  }

  renderSuccessMessage = () => {
    return (
      <div className="success-message">
        <div className="success-check-circle" />
        <p className="success-copy">{this.props.message}</p>
        {this.renderSupportCopy()}
      </div>
    )
  }

  renderSuccesNextPet = () => {
    const { pets } = this.props.user
    const hasProcedureToBeSchedule = pets && pets.filter((pet) => !pet.procedure)

    if (hasProcedureToBeSchedule.length === 0) {
      return null
    }

    const nextPet = hasProcedureToBeSchedule[hasProcedureToBeSchedule.length - 1]

    return (
      <Button className="succes-next-pet">
        <Link to={`/pet/${nextPet.id}`}>Next ({nextPet.name})</Link>
      </Button>
    )
  }

  renderHearAboutUs = () => {
    const { checkout } = this.props
    const { hearAboutUsSource, hearAboutUsSourceOther, hearAboutUsSourceSent } = this.state

    const options = [
      'My veterinarian',
      'My breeder',
      'Shark Tank',
      'Our blog',
      'My friend/family/coworker',
      'In the press',
      'Facebook',
      'Instagram',
      'Podcast',
      'Youtube',
      'Google search',
      'Other',
    ]

    const submitForm = (e) => {
      e.preventDefault()

      identify(checkout.owner.ids.userId, {
        hearAboutUsSource,
        hearAboutUsSourceOther,
      })

      this.setState({ hearAboutUsSourceSent: true })
    }

    return (
      <section className="hear-about-us">
        {!hearAboutUsSourceSent ? (
          <>
            <h2>One more thing! How did you hear about us?</h2>
            <form onSubmit={submitForm}>
              {options.map((option) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      key={option}
                      onChange={(e) => this.setState({ hearAboutUsSource: e.target.value })}
                      name="source"
                      value={option}
                      color="primary"
                      checked={option === hearAboutUsSource}
                    />
                  }
                  label={option}
                />
              ))}
              {hearAboutUsSource === 'Other' && (
                <TextField
                  name="other"
                  onChange={(e) => this.setState({ hearAboutUsSourceOther: e.target.value })}
                  value={hearAboutUsSourceOther}
                  fullWidth
                  variant="outlined"
                />
              )}
              {!!hearAboutUsSource && <Button type="submit">Submit</Button>}
            </form>
          </>
        ) : (
          <p>Thanks for your answer!</p>
        )}
      </section>
    )
  }

  renderSuccessSummary = () => {
    const token = window.localStorage.getItem(AUTH_TOKEN_KEY)

    return (
      <>
        <section className="success-summary">
          <hr />
          {this.renderHearAboutUs()}
          <hr />
          <h2>Order Confirmation</h2>
          {this.renderPet()}
          {this.renderOwner()}
          {this.renderPetProfessional()}
        </section>
        {this.props.isSetup && (
          <Link className="success-cta" to={`/profile/${token}`}>
            Go to profile
          </Link>
        )}
        {this.renderSupportCopy()}
      </>
    )
  }

  render() {
    return (
      <section className="success-container">
        {this.props.message ? this.renderSuccessMessage() : this.renderSuccessSummary()}
      </section>
    )
  }
}

const Success = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuccessComponent)

export { Success }
