import { handleActions } from 'redux-actions'

import { changeProductReducer } from './change-product'
import { changeStepReducer } from './change-step'
import { checkEmailReducer } from './check-email'
import { checkoutReducer } from './checkout'
import { createAuthTokenReducer } from './create-auth-token'
import { createOwnerReducer } from './create-owner'
import { createPetProfessionalReducer } from './create-pet-professional'
import { createProcedureReducer } from './create-procedure'
import { getAuthTokenReducer } from './get-auth-token'
import { getPlansReducer } from './get-available-plans'
import { getBreedsReducer } from './get-breeds'
import { getCouponReducer } from './get-coupon'
import { getSubscriptionCheckoutDetailsReducer } from './get-subscription-details'
import { initialState } from './initial-state'
import { resetFormReducer } from './reset-form'
import { skipStepReducer } from './skip-step'
import { calculateTaxReducer } from './tax-calculator'
import { updateOwnerReducer } from './update-owner'
import { updateProcedureReducer } from './update-procedure'
import { updateShippingReducer } from './update-shipping'

export const combinedReducers = {
  ...calculateTaxReducer,
  ...changeStepReducer,
  ...changeProductReducer,
  ...checkoutReducer,
  ...updateOwnerReducer,
  ...createOwnerReducer,
  ...getBreedsReducer,
  ...getCouponReducer,
  ...getPlansReducer,
  ...getSubscriptionCheckoutDetailsReducer,
  ...resetFormReducer,
  ...skipStepReducer,
  ...createAuthTokenReducer,
  ...getAuthTokenReducer,
  ...createPetProfessionalReducer,
  ...createProcedureReducer,
  ...updateProcedureReducer,
  ...checkEmailReducer,
  ...updateShippingReducer,
}

export const checkout = handleActions(combinedReducers, initialState)
