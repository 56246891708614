export const actions = {
  updateOwner: 'UPDATE_OWNER',
  createOwner: 'CREATE_OWNER',
  submitCheckout: 'SUBMIT_CHECKOUT',
  changeStep: 'CHANGE_STEP',
  skipStep: 'SKIP_STEP',
  includeStep: 'INCLUDE_STEP',
  calculateTaxes: 'CALCULATE_TAXES',
  getAuthToken: 'GET_AUTH_TOKEN',
  getPlans: 'GET_PLANS',
  getCoupon: 'GET_COUPON',
  getBreeds: 'GET_BREEDS',
  getSubscriptionDetails: 'GET_SUBSCRIPTION_DETAILS',
  resetForm: 'RESET_FORM',
  updatePet: 'UPDATE_PET',
  updatePetProfessional: 'UPDATE_PET_PROFESSIONAL',
  updateProcedure: 'UPDATE_PROCEDURE',
  createPetProfessional: 'CREATE_PET_PROFESSIONAL',
  createProcedure: 'CREATE_PROCEDURE',
  changeProduct: 'CHANGE_PRODUCT',
  submitSetup: 'SUBMIT_SETUP',
  checkEmail: 'CHECK_EMAIL',
  changeLastVisitedStep: 'CHANGE_LAST_VISITED_STEP',
  updateShipping: 'UPDATE_SHIPPING',
}
