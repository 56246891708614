import axios from 'axios'

export const createOwner = async (ownerData) => {
  const { data } = await axios({
    method: 'post',
    url: '/owners',
    data: { owner: ownerData },
  })

  return data
}
