import axios from 'axios'

export const updatePetProfessional = async ({ petProfessionalId, petProfessional }) => {
  const { data } = await axios({
    method: 'put',
    url: `/pet-professionals/${petProfessionalId}`,
    data: { petProfessional },
  })

  return data
}
