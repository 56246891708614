import { createActionThunk } from 'redux-thunk-actions'

import { createPetProfessional } from '../../api/pet-professionals/create-pet-professional'
import { actions } from './actions'
import { PetProfessional, Procedure, Reducer } from './initial-state'

export const createPetProfessionalAction = createActionThunk(
  actions.createPetProfessional,
  ({
    petProfessional,
    orderId,
    petId,
    procedure,
  }: {
    petProfessional: PetProfessional
    orderId: number
    petId?: number
    procedure?: Procedure
  }) => createPetProfessional({ petProfessional, orderId, petId, procedure }),
)

export const createPetProfessionalReducer = {
  [createPetProfessionalAction.STARTED]: (state) => ({
    ...state,
    creatingPetProfessional: true,
  }),
  [createPetProfessionalAction.SUCCEEDED]: (state, { payload }) => ({
    ...state,
    owner: {
      ...state.owner,
      ids: {
        ...state.owner.ids,
        petProfessionalId: payload.petProfessionalId,
        practiceId: payload.practiceId,
      },
    },
  }),
  [createPetProfessionalAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [createPetProfessionalAction.ENDED]: (state) => ({
    ...state,
    creatingPetProfessional: false,
  }),
} as Reducer
