export const elementStyles = {
  base: {
    color: 'black',
    fontFamily: 'Akkurat, sans-serif',
    fontSize: '16px',

    '::placeholder': {
      color: '#CFD7DF',
    },
    ':-webkit-autofill': {
      color: '#e39f48',
    },
  },
  invalid: {
    color: 'red',

    '::placeholder': {
      color: '#ff000065',
    },
  },
}

export const elementClasses = {
  focus: 'focused',
  empty: 'empty',
  invalid: 'invalid',
}
