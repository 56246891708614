import axios from 'axios'

export const getSubscriptionCheckoutDetails = async (product: string) => {
  const { data } = await axios({
    method: 'get',
    url: '/checkout/subscription-checkout-details',
    params: { product },
  })

  return data
}
