import axios from 'axios'

export const getBreeds = async () => {
  const { data } = await axios({
    method: 'get',
    url: '/checkout/breeds',
  })

  return data
}
