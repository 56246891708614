import * as Yup from 'yup'

const monthsRangeMessage = 'Insert a number between 0 and 11.'
const totalAgeMessage = 'Age in years or months must be bigger than 0.'

const validateTotalAge = function() {
  const { years, months } = this.parent
  const totalAge = months || 0 + years || 0

  return +totalAge !== 0
}

export const petInfoSchema = Yup.object().shape({
  gender: Yup.string().required('Can’t be blank.'),
  years: Yup.number()
    .min(0, 'Insert a positive number.')
    .test('totalAge', totalAgeMessage, validateTotalAge),
  months: Yup.number()
    .min(0, monthsRangeMessage)
    .max(11, monthsRangeMessage)
    .test('totalAge', totalAgeMessage, validateTotalAge),
  species: Yup.string().required(),
  primaryBreed: Yup.string().required(),
})
