import * as React from 'react'

import { ValidProducts } from '../../redux/checkout/initial-state'

type Props = {
  product: string
  className: string
}

class Terms extends React.Component<Props, any> {
  renderTermsByProduct = () => {
    const { product, className } = this.props

    const termsLink = (
      <a target="_blank" rel="noopener noreferrer" href="http://gallant.com/terms-of-service/">
        Terms of Use
      </a>
    )

    if (
      product === ValidProducts.lifetime ||
      product === ValidProducts.equineLifetime ||
      product === ValidProducts.adiposeLifetime
    ) {
      return (
        <p className={className}>
          By clicking ‘Purchase', you accept Gallant Pet, Inc.'s {termsLink}.
        </p>
      )
    }

    if (product === ValidProducts.monthly) {
      return (
        <p className={className}>
          By clicking ‘Purchase', you accept Gallant Pet, Inc.'s {termsLink} and agree that your
          subscription will automatically renew every year, and you will be charged $45 each month
          for the first 12 months and $10 each month thereafter until you cancel your subscription.
          You may cancel at any time by emailing us at help@gallant.com. If you cancel, previous
          charges will not be refunded, but you may continue to use the service until the end of the
          term that you paid for.
        </p>
      )
    }

    return (
      <p className={className}>
        By clicking ‘Purchase', you accept Gallant Pet, Inc.'s {termsLink} and agree that your
        subscription will automatically renew every year, and you will be charged $95 on each
        renewal until you cancel your subscription. You may cancel at any time by emailing us at
        help@gallant.com. If you cancel, previous charges will not be refunded, but you may continue
        to use the service until the end of the term that you paid for.
      </p>
    )
  }

  render() {
    return this.renderTermsByProduct()
  }
}

export { Terms }
