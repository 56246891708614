import { createActionThunk } from 'redux-thunk-actions'

import { updatePetProfessional } from '../../api/pet-professionals/update-pet-professional'
import { actions } from './actions'
import { PetProfessional, Reducer } from './initial-state'

export const updatePetProfessionalAction = createActionThunk(
  actions.updatePetProfessional,
  ({ petProfessionalId, petProfessional }: { petProfessionalId: number; petProfessional: PetProfessional }) =>
    updatePetProfessional({ petProfessionalId, petProfessional }),
)

export const updatePetReducer = {
  [updatePetProfessionalAction.STARTED]: (state) => ({
    ...state,
    updatingPet: true,
  }),
  [updatePetProfessionalAction.SUCCEEDED]: (state) => ({
    ...state,
  }),
  [updatePetProfessionalAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [updatePetProfessionalAction.ENDED]: (state) => ({
    ...state,
    updatingPet: false,
  }),
} as Reducer
