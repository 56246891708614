import * as React from 'react'
import { Helmet } from 'react-helmet'

import { Routes } from '../Routes'

const App = () => (
  <>
    <Helmet title="Checkout | Gallant" />
    <Routes />
  </>
)

export { App }
