import { createAction } from 'redux-actions'

import { actions } from './actions'
import { Reducer } from './initial-state'

export const updateOwnerAction = createAction(actions.updateOwner)

export const updateOwnerReducer = {
  [actions.updateOwner]: (state, { payload }) => ({
    ...state,
    owner: {
      ...state.owner,
      ...payload.info,
    },
  }),
} as Reducer
