import { withStyles } from '@material-ui/core'
import cx from 'classnames'
import * as React from 'react'
import ReactPhoneInput from 'react-phone-input-2'

import { styles } from './input-styles'

interface Props {
  form: any
  name: string
  label: string
  className?: string
  classes: any
}

interface State {
  phone: string
}

const getPhoneNumber = (phone: string) => {
  return phone.replace(/^\S+/, '').replace(/[^\d]/g, '')
}

const joinPhoneCodeNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return ''
  }

  return `+1${phoneNumber}`
}

class PhoneInputComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    const name = props.name

    const phone = props.form.values[name] ? joinPhoneCodeNumber(props.form.values[name]) : ''
    this.state = { phone }
  }

  render() {
    const { name, className, label, classes } = this.props
    const { errors, submitCount, setFieldValue } = this.props.form

    return (
      <div className={cx('input phone-input', className, classes.container)}>
        <label>{label}</label>
        <ReactPhoneInput
          onlyCountries={['us']}
          countryCodeEditable={false}
          defaultCountry={'us'}
          disableAreaCodes={true}
          inputExtraProps={{ name }}
          value={this.state.phone || joinPhoneCodeNumber(this.props.form.values[name])}
          onChange={(value) => {
            const phone = getPhoneNumber(value)

            setFieldValue(name, phone)

            this.setState({ phone: `+1${phone}` })
          }}
        />
        <p className="error">{!!submitCount && errors[name]}</p>
      </div>
    )
  }
}

const PhoneInput = withStyles(styles)(PhoneInputComponent)

export { PhoneInput }
