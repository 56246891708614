import './plan.scss'

import lodash from 'lodash'
import * as React from 'react'
import { formatMoney } from '../../utils/formatter'

interface PlanProps {
  petName: string
  plan: string
  total: number
}

const Plan: React.SFC<PlanProps> = ({ petName, plan, total }) => {
  const isMonthly = plan === 'monthly'

  return (
    <div className="plan">
      <h2 className="plan-heading">Your Plan</h2>
      <p className="plan-description">
        {petName}'<span style={{ textTransform: 'lowercase' }}>s</span> Gallant{' '}
        {lodash.startCase(plan)} Plan{' '}
        <span className="plan-price">
          <span>${formatMoney(total)} </span>{' '}
          {isMonthly && <span className="plan-price-caption">for 12 payments</span>}
        </span>
      </p>
      <ul className="plan-items">
        <li>Medical courier & shipping included</li>
        <li>Stem cell isolation and cryopreservation included</li>
        <li>Secure storage & daily monitoring included</li>
        {isMonthly && (
          <li>
            Plan lowers to <strong>$10/mo</strong> after 12 payments
          </li>
        )}
      </ul>
      <div className="badge">
        <img alt="Gallant Guarantee" src="/assets/images/badge.svg" />
      </div>
    </div>
  )
}

export { Plan }
