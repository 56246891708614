export const getSafe = (fn: any, defaultValue: any) => {
  try {
    return fn()
  } catch (e) {
    return defaultValue
  }
}

export const formatDate = (date: Date) => {
  const year = date.getFullYear()
  const month = `${date.getMonth() + 1}`.padStart(2, '0')
  const day = `${date.getDate()}`.padStart(2, '0')

  return `${year}-${month}-${day}`
}
