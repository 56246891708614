import { createActionThunk } from 'redux-thunk-actions'

import { getPlans } from '../../api/checkout/get-plans'
import { actions } from './actions'
import { Reducer } from './initial-state'

export const getPlansAction = createActionThunk(actions.getPlans, () => getPlans())

export const getPlansReducer = {
  [getPlansAction.STARTED]: (state) => ({
    ...state,
    loadingPlans: true,
  }),
  [getPlansAction.SUCCEEDED]: (state, { payload }) => ({
    ...state,
    availablePlans: payload.plans,
    plansLoaded: true,
  }),
  [getPlansAction.FAILED]: (state, { payload }) => ({
    ...state,
    loadingPlans: false,
    error: payload,
  }),
  [getPlansAction.ENDED]: (state) => ({
    ...state,
    plansLoaded: false,
    loadingPlans: false,
  }),
} as Reducer
