import deburr from 'lodash/deburr'

export const getSuggestions = ({ value, suggestions, showEmpty = false }) => {
  const inputValue = deburr(value.trim()).toLowerCase()
  const inputLength = inputValue.length

  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.filter((suggestion) => suggestion.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
}
