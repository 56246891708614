import * as React from 'react'
import { Switch } from 'react-router-dom'

import { ProtectedRoute } from './ProtectedRoute'
import { PublicRoute } from './PublicRoute'
import { Checkout } from './checkout/Checkout'
import { Setup } from './checkout/Setup'
import { Login as UserLogin } from './profile/Login'
import { Pet as UserPet } from './profile/Pet'
import { Procedure as UserPetProcedure } from './profile/Procedure'
import { Profile as UserProfile } from './profile/Profile'
import { routePaths } from './route-paths'

const Routes = () => {
  return (
    <Switch>
      <PublicRoute path={routePaths.user.login} component={UserLogin} />
      <ProtectedRoute path={routePaths.user.profile} component={UserProfile} />
      <ProtectedRoute path={routePaths.user.pet} component={UserPet} />
      <ProtectedRoute path={routePaths.user.procedure} component={UserPetProcedure} />
      <PublicRoute path={routePaths.setup} component={Setup} />
      <PublicRoute path={routePaths.plans} component={Checkout} />
      <PublicRoute path="*" component={Checkout} />
    </Switch>
  )
}

export { Routes }
