import { createActionThunk } from 'redux-thunk-actions'

import { calculateTax } from '../../api/checkout/tax-calculator'
import { actions } from './actions'
import { Reducer } from './initial-state'

export const calculateTaxAction = createActionThunk(actions.calculateTaxes, (zipCode: string) => calculateTax(zipCode))

export const calculateTaxReducer = {
  [calculateTaxAction.STARTED]: (state) => ({
    ...state,
    calculatingTaxes: true,
  }),
  [calculateTaxAction.SUCCEEDED]: (state, { payload }) => ({
    ...state,
    calculatedTax: payload.calculatedTax,
    taxesCalculated: true,
  }),
  [calculateTaxAction.FAILED]: (state, { payload }) => ({
    ...state,
    calculatingTaxes: false,
    error: payload,
  }),
  [calculateTaxAction.ENDED]: (state) => ({
    ...state,
    taxesCalculated: false,
    calculatingTaxes: false,
  }),
} as Reducer
