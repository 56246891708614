export const LOCAL_STORE_KEY = 'gallant_store'

export const loadState = (): object | undefined => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORE_KEY)

    if (serializedState === null) {
      return undefined
    }

    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state: object): void => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(LOCAL_STORE_KEY, serializedState)
  } catch (error) {
    throw error
  }
}
