import axios from 'axios'

export const updatePetPicture = async ({ petId, file }) => {
  const formData = new FormData()

  formData.append('file', file)

  const { data } = await axios({
    method: 'post',
    url: `/pets/${petId}/picture`,
    data: formData,
    headers: {
      'content-type': 'multipart/form-data',
    },
  })

  return data
}
