import * as React from 'react'

import { TextField } from '@material-ui/core'

export const AutocompleteInput = (inputProps) => {
  const { InputProps, ref, label, classes, isMultiple, ...other } = inputProps

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        ...InputProps,
        classes: classes && {
          root: classes.inputRoot,
          input: !isMultiple && classes.inputInput,
          multiline: classes.inputMultiline,
        },
      }}
      label={label}
      variant="outlined"
      {...other}
    />
  )
}
