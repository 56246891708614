import * as React from 'react'
import { initialState as checkoutInitialState } from '../../../../../redux/checkout/initial-state'
import { elementClasses, elementStyles } from '../../stripe-elements-style'
import { Coupon } from '../Coupon'
import { CreditCardFields } from '../CreditCardFields'
import { LifetimePaymentChooser } from '../LifetimePaymentChooser'
import { PaymentProvider } from '../PaymentProvider'

interface eventFunc {
  (event: any): void
}

interface paymentProviderChangeFunc {
  (selectedPaymentProvider: PaymentProvider): void
}

interface voidFunc {
  (): void
}

interface boolFunc {
  (): boolean
}

interface FormFieldProps {
  selectedPaymentProvider: PaymentProvider
  availableMobilePaymentProvider: PaymentProvider
  canMakePayment: boolean
  invalidCoupon: boolean
  isCouponEligible: boolFunc
  checkout: typeof checkoutInitialState
  couponValue: string
  couponDisabled: boolean
  handleCouponChange: eventFunc
  handlePaymentProviderChange: paymentProviderChangeFunc
  applyCoupon: voidFunc
}

const FormFields: React.FunctionComponent<FormFieldProps> = ({
  selectedPaymentProvider,
  availableMobilePaymentProvider,
  canMakePayment,
  invalidCoupon,
  isCouponEligible,
  checkout,
  couponValue,
  couponDisabled,
  handleCouponChange,
  handlePaymentProviderChange,
  applyCoupon,
}) => (
  <>
    <LifetimePaymentChooser
      selectedPaymentProvider={selectedPaymentProvider}
      availableMobilePaymentProvider={availableMobilePaymentProvider}
      canMakePayment={canMakePayment}
      onPaymentProviderChange={handlePaymentProviderChange}
      product={checkout.product}
    />
    <section className="form-fields">
      {selectedPaymentProvider === PaymentProvider.Stripe && (
        <>
          <CreditCardFields elementClasses={elementClasses} elementStyles={elementStyles} />
          <Coupon
            checkout={checkout}
            couponValue={couponValue}
            couponDisabled={couponDisabled}
            applyCoupon={applyCoupon}
            invalidCoupon={invalidCoupon}
            isCouponEligible={isCouponEligible}
            handleCouponChange={handleCouponChange}
          />
        </>
      )}
    </section>
  </>
)

export { FormFields }
