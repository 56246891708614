import { createActionThunk } from 'redux-thunk-actions'

import { createOwner } from '../../api/owners/create-owner'
import { actions } from './actions'
import { Reducer } from './initial-state'

export const createOwnerAction = createActionThunk(actions.createOwner, async ({ ownerData }) => {
  const payload = await createOwner(ownerData)

  return { payload, meta: { ownerData } }
})

export const createOwnerReducer = {
  [createOwnerAction.STARTED]: (state) => ({
    ...state,
    creatingOwner: true,
  }),
  [createOwnerAction.SUCCEEDED]: (state, { payload, meta }) => ({
    ...state,
    owner: {
      ...state.owner,
      ...meta.ownerData,
      ids: {
        userId: payload.userId,
        petId: payload.petId,
      },
    },
  }),
  [createOwnerAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [createOwnerAction.ENDED]: (state) => ({
    ...state,
    creatingOwner: false,
  }),
} as Reducer
