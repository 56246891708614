import * as Yup from 'yup'

const requiredMessage = 'Can’t be blank.'

export const billingSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredMessage),
  lastName: Yup.string().required(requiredMessage),
  streetAddress: Yup.string().required(requiredMessage),
  city: Yup.string().required(requiredMessage),
  state: Yup.string().required(requiredMessage),
  zipCode: Yup.string()
    .required(requiredMessage)
    .matches(/^\d{5}(?:\d{4})?$/, 'Invalid zip code.'),
})
