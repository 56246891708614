import { createActionThunk } from 'redux-thunk-actions'

import { getBreeds } from '../../api/checkout/get-breeds'
import { actions } from './actions'
import { Reducer } from './initial-state'

export const getBreedsAction = createActionThunk(actions.getBreeds, () => getBreeds())

export const getBreedsReducer = {
  [getBreedsAction.STARTED]: (state) => ({
    ...state,
    loadingBreeds: true,
  }),
  [getBreedsAction.SUCCEEDED]: (state, { payload }) => ({
    ...state,
    breeds: payload.breeds,
    error: undefined,
  }),
  [getBreedsAction.FAILED]: (state, { payload }) => ({
    ...state,
    loadingCoupon: false,
    error: payload.error,
    breeds: undefined,
  }),
  [getBreedsAction.ENDED]: (state) => ({
    ...state,
    loadingBreeds: false,
  }),
} as Reducer
