import '../../checkout/checkout.scss'

import { Formik } from 'formik'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Yup from 'yup'

import { Button } from '@material-ui/core'

import { Store } from '../../../redux/configure-store'
import { createAuthTokenAction } from '../../../redux/user/create-auth-token'
import { initialState } from '../../../redux/user/initial-state'
import { Input } from '../../shared/forms/Input'

const mapStateToProps = (state: Store) => ({
  user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
  submitAuthToken: bindActionCreators(createAuthTokenAction, dispatch),
})

type Dispatchers = ReturnType<typeof mapDispatchToProps>
type Props = { user: typeof initialState } & Dispatchers

export interface LoginValues {
  email: string
}

const setupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be email format.')
    .required('Can’t be blank.'),
})

export class LoginFormComponent extends React.Component<Props, any> {
  getInitialValues = (): LoginValues => ({
    email: '',
  })

  renderForm = ({ formProps }) => {
    const { error } = this.props.user

    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        <section className="form-fields">
          <Input form={formProps} name="email" type="email" label="Email" fullWidth />
        </section>
        {error && (
          <p className="error" style={{ paddingTop: '20px' }}>
            {error}
          </p>
        )}
        <Button disabled={formProps.isSubmitting} type="submit">
          NEXT
        </Button>
      </form>
    )
  }

  onFormSubmit = (values) => {
    this.handleSetupSubmit(values)
  }

  handleSetupSubmit = (values: LoginValues) => {
    const setup = {
      email: values.email,
      target: 'profile',
    }

    this.props.submitAuthToken(setup)
  }

  render() {
    const initialValues = this.getInitialValues()

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={setupSchema}
        onSubmit={this.onFormSubmit}
      >
        {(formProps) => this.renderForm({ formProps })}
      </Formik>
    )
  }
}

const LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginFormComponent)

export { LoginForm }
