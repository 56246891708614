import { createActionThunk } from 'redux-thunk-actions'

import { getAuthToken } from '../../api/auth-tokens/get-auth-token'
import { actions } from './actions'
import { Reducer } from './initial-state'

export const getProfileAction = createActionThunk(actions.getProfile, (token: string) => getAuthToken(token))

export const AUTH_TOKEN_KEY = 'gallant-pet-user-auth-token'

export const getProfileReducer = {
  [getProfileAction.STARTED]: (state) => ({
    ...state,
    loadingProfile: true,
    error: undefined,
  }),
  [getProfileAction.SUCCEEDED]: (state, { payload }) => {
    const token = payload.authToken && payload.authToken.token

    window.localStorage.setItem(AUTH_TOKEN_KEY, token)

    return {
      ...state,
      profile: {
        ...payload.user,
        address: payload.address,
      },
      pets: payload.pets,
      authToken: token,
      error: undefined,
    }
  },
  [getProfileAction.FAILED]: (state, { payload }) => {
    window.localStorage.setItem(AUTH_TOKEN_KEY, '')

    return {
      ...state,
      authToken: null,
      error: payload.error,
    }
  },
  [getProfileAction.ENDED]: (state) => ({
    ...state,
    loadingProfile: false,
  }),
} as Reducer
