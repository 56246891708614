import axios from 'axios'

export const page = async (analytics: any, name: string, options: object) => {
  await analytics.page(name, options)

  const anonymousId = analytics.user().anonymousId()

  await axios({
    method: 'post',
    url: '/segment/page',
    data: {
      anonymousId,
      name,
      properties: options,
    },
  })
}
