import { CircularProgress } from '@material-ui/core'
import cx from 'classnames'
import lodash from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import '../checkout/checkout.scss'
import '../checkout/success.scss'
import './profile.scss'

import { Store } from '../../redux/configure-store'
import { getProfileAction } from '../../redux/user/get-profile'
import { initialState } from '../../redux/user/initial-state'
import { updateProfileAction } from '../../redux/user/update-profile'
import { OwnerForm } from './forms/OwnerForm'

const mapStateToProps = (state: Store) => ({
  user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
  getProfile: bindActionCreators(getProfileAction, dispatch),
  updateProfile: bindActionCreators(updateProfileAction, dispatch),
})

type Dispatchers = ReturnType<typeof mapDispatchToProps>
type Props = {
  history: any
  user: typeof initialState
  match: { params: { authToken: string } }
  loadingProfile: boolean
  authToken: string
} & Dispatchers

export class ProfileComponent extends React.Component<Props, any> {
  state = {
    ownerEditionMode: false,
  }

  componentDidMount() {
    this.props.getProfile(this.props.authToken)
  }

  handleOwnerEditionMode = (opened: boolean): void =>
    this.setState({ ownerEditionMode: !this.state.ownerEditionMode })

  handleProfileUpdateSubmit = (values) => {
    const { id } = this.props.user.profile
    this.props.updateProfile(id, values)
    this.handleOwnerEditionMode(false)
  }

  renderOwner = (profile) => (
    <section className="owner">
      <div className="title-container">
        <p className="title">
          Owner{' '}
          <a
            href="javascript:void(0);"
            onClick={() => this.handleOwnerEditionMode(true)}
            data-test-id="edit-owner-button"
          >
            <span className="icon edit" /> {this.state.ownerEditionMode ? 'Cancel' : 'Edit'}
          </a>
        </p>
      </div>
      {this.state.ownerEditionMode ? (
        <OwnerForm
          owner={profile}
          handleSubmit={this.handleProfileUpdateSubmit}
          onCancel={() => this.handleOwnerEditionMode(false)}
        />
      ) : (
        <ul className="fluid">
          <li>
            <p>First Name</p>
            <p data-test-id="owner-firstname">{profile.firstName || 'N/A'}</p>
          </li>
          <li>
            <p>Last Name</p>
            <p data-test-id="owner-lastname">{profile.lastName || 'N/A'}</p>
          </li>
          <li>
            <p>Email</p>
            <p data-test-id="owner-email">{profile.email || 'N/A'}</p>
          </li>
          <li>
            <p>Phone Number</p>
            <p data-test-id="owner-phone">
              {profile.phoneNumber
                ? profile.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
                : 'N/A'}
            </p>
          </li>
          <li>
            <p>Address</p>
            <p data-test-id="owner-address">
              {profile.address.address}, {profile.address.city}, {profile.address.state}{' '}
              {profile.address.zip}
            </p>
          </li>
        </ul>
      )}
    </section>
  )

  renderPets = (pets) => {
    const { user, history } = this.props

    return (
      <section className="pets">
        <div className="title-container">
          <p className="title">
            Pets{' '}
            <a
              href="javascript:void(0);"
              onClick={() => history.push(`/plans/lifetime/?email=${user.profile.email}`)}
            >
              <span className="icon add" /> Add
            </a>
          </p>
        </div>
        <p className="copy">Click any of your pets below to view and/or edit their details.</p>
        {pets.map((pet) => (
          <div
            key={pet.id}
            className={cx('pet-box', { 'no-procedure-date-container': !pet.procedure })}
            onClick={() => history.push(`/pet/${pet.id}`)}
            data-test-id="go-to-pet"
          >
            <div className="picture-wrapper">
              {!pet.picture && <img className="picture-icon" src="/assets/images/pet.svg" />}
              {pet.picture && (
                <img className="picture" src={`${pet.picture}?u=${new Date().getTime()}`} />
              )}
            </div>
            <div>
              <h3 className="name">{pet.name}</h3>
              <p className="breed">
                {(pet.primaryBreed && pet.primaryBreed.toLowerCase()) || 'N/A'}
                {pet.secondaryBreeds && `\ ${pet.secondaryBreeds[0].toLowerCase()}`}
              </p>
              <div className="plan-contanier">
                <p>{pet.order.plan ? `${lodash.startCase(pet.order.plan)} plan` : 'N/A'}</p>
                {!pet.procedure && (
                  <p className="no-procedure-date">Please Provide Procedure Date</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </section>
    )
  }

  renderContent = () => {
    const { profile, pets } = this.props.user

    if (!profile) {
      return null
    }

    return (
      <>
        <p className="copy">
          Welcome, {profile.firstName}! Please, review your account information below.
        </p>
        <div className="success-container">
          <section className="success-summary">
            {this.renderOwner(profile)}
            {this.renderPets(pets)}
          </section>
          <p className="support-copy">
            If you have any questions, contact us at{' '}
            <a href="mailto:help@gallant.com">help@gallant.com</a> or call at{' '}
            <a href="tel:855-4-GALLANT">855-4-GALLANT</a>.
          </p>
        </div>
      </>
    )
  }

  render() {
    const { loadingProfile } = this.props.user

    return (
      <section className="container profile">
        <section className="header">
          <div className="image-logo" />
        </section>
        <div className="content">
          {loadingProfile ? (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            this.renderContent()
          )}
        </div>
      </section>
    )
  }
}

const Profile = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileComponent)

export { Profile }
