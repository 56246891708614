import axios from 'axios'

export const getAuthToken = async (token: string) => {
  const { data } = await axios({
    method: 'get',
    url: `/auth-tokens/${token}`,
  })

  return data
}
