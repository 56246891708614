import * as React from 'react'

import { MenuItem } from '@material-ui/core'

interface Props {
  suggestion: { name: string }
  index: number
  itemProps: any
  highlightedIndex: number
  selectedItem: string
}

export const Suggestion = ({ suggestion, index, itemProps, highlightedIndex, selectedItem }: Props) => {
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion.name) > -1

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.name}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.name}
    </MenuItem>
  )
}
