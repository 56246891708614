import './date-picker.scss'

import DateFnsUtils from '@date-io/date-fns'
import { withStyles } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as React from 'react'

import { styles } from './input-styles'
import { isDateDisabled } from './services/appointment-service'

interface Props {
  name: string
  label?: string
  classes: any
  form: any
  subLabel?: string
  onChange?: (value: Date) => void
  onBlur?: (value: Date) => void
}

export const disableWeekendDays = (currentDate) => isDateDisabled(currentDate, new Date())

const DateComponent: React.FunctionComponent<Props> = ({
  name,
  classes,
  form,
  onChange,
  onBlur,
  subLabel,
}) => {
  const { values, handleChange, handleBlur } = form

  return (
    <div className={`input ${classes.container}`}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          id="mui-pickers-date"
          className="date-picker-container"
          value={values[name] || null}
          clearable
          minDateMessage="Inform a valid future date."
          invalidDateMessage="Invalid date."
          maxDateMessage="Invalid date."
          disablePast
          format="MM/dd/yyyy"
          placeholder="MM/DD/YYYY"
          shouldDisableDate={disableWeekendDays}
          fullWidth
          inputVariant="outlined"
          onChange={(value) => {
            handleChange({ target: { name, value } })
            onChange && onChange(value)
          }}
          onBlur={(value) => {
            handleBlur({ target: { name, value } })
            onBlur && onBlur(value)
          }}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: classes.inputRoot,
              input: classes.inputInput,
            },
          }}
          InputAdornmentProps={{
            classes: {
              root: classes.inputDateAdornmentRoot,
            },
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
            classes: {
              root: classes.inputDateAdornmentButtonRoot,
            },
          }}
        />
      </MuiPickersUtilsProvider>
      {subLabel && <p className="sub-label">{subLabel}</p>}
    </div>
  )
}

const DatePicker = withStyles(styles)(DateComponent)

export { DatePicker }
